import moment from "moment-timezone";

export const updateAnswers = (value, answer, state, setState, index) => {
  if (!state) return;

  const copy = [...answer];
  copy[index].liked = value;

  const copyState = { ...state };
  copyState[moment(answer[index].date).format("YYYY-MM-DD")][answer[index].question] = copy;

  setState(copyState);
};
