/* 
  Used as keywords when mentioning. Order is least to greatest,
  least being most important when multiple @keyword appear
  Ex. @Plexxis would go out to the entire company and if @Contributors were active also it would be skipped 
  due to @Plexxis having a higher order number.
  If two or more keywords appear with the same order number they will both be included
*/
exports.keywords = {
  admin: [{ name: "Plexxis", id: "Plexxis", order: 0 }],
  primary: [{ name: "Contributors", id: "Contributors", order: 1 }],
  permission: [{ name: "Plexxis", id: "Plexxis", order: 0 }],
  reviewers: [{ name: "Reviewers", id: "Reviewers", order: 1 }],
};

exports.hotKeys = {
  "shift+b": "bold",
  "shift+i": "italic",
  "shift+u": "underline",
};
