/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect, useCallback } from "react";
import moment from "moment-timezone";
import API from "../utils/API";
import { groupByDate, vaildateDate } from "../utils/utils";
import { groupAnswers } from "../views/form/logic/groupAnswers";
import socketHandler from "../utils/socket";
import { parseJson } from "../components/CustomEditor/utils";

import { loadGapiClient } from "../components/GoogleAuth/googleCalendarApi";

// TODO: Would be nice to have more than one context...

const UserContext = React.createContext();

const initialState = {
  expanded: null,
  contributors: [],
};

const UserProvider = ({
  user,
  selectedDate,
  isSubmitted,
  setIsEditMode,
  dateChanged,
  children,
  declaration,
  lastReflection,
  setLastReflection,
  fetchUserList,
  userList,
  assignees,
  setAssignees,
  theme,
  notification,
  setNotification,
  currentView,
  setCurrentView,
}) => {
  const [loading, setLoading] = useState(false);
  const [isPushed, setIsPushed] = useState(false);
  const [answers, setAnswers] = useState({
    currentAns: {},
    prevAns: [],
    count: 0,
    lastRecord: null,
    currentAutoScore: null,
  });
  // Calendar ratings for the current month selected
  const [monthlyRatings, setMonthlyRatings] = useState([]);
  const [feedback, setFeedback] = useState({});
  const [incSocket, setIncSocket] = useState(null);
  const [expectations, setExpectations] = useState({});
  // Hidden Expectations
  const [hiddenExp, setHiddenExp] = useState([]);
  const [selected, setSelected] = useState(null);
  // Token used for Graph API
  const [token, setToken] = useState(null);
  const [messageData, setMessageData] = useState(null);
  const [announcements, setAnnouncements] = useState([]);
  const [drafts, setDrafts] = useState([]);
  // Hidden expectation dates
  const [hiddenDates, setHiddenDates] = useState([]);
  const [isEntryEmpty, setIsEntryEmpty] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const [workflows, setWorkflows] = useState([]); // Assigned Workflows (Used for expectations)
  // Flag used to open declaration expectations
  const [isClicked, setIsClicked] = useState(false);
  const [contributors, setContributors] = useState([]);
  // Flag used for requesting contributors expectations
  const [groupSelected, setGroupSelected] = useState(initialState);
  const [hasSubmission, setHasSubmission] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  /**
   * Gets current declaration answers
   */
  const getAns = useCallback(async () => {
    setLoading(true);
    let lastDeclaration;
    try {
      // Fetch Current Answers
      const currRes = await API.getDecData(user.id, selectedDate, user.timezone, dateChanged);

      if (currRes.data?.length > 0) {
        lastDeclaration = currRes.data[0].answer_set;
      }

      if ((answers.prevAns !== undefined && answers?.prevAns.length === 0) || declaration.type === "previous") {
        // Fetch Previous Answers
        const prevRes = await API.getPrevAns(user.id, 5, 0, user.timezone);

        const groupedRes = await groupByDate(prevRes.data.answers, 0, user.timezone);
        if (!lastDeclaration && prevRes.data.answers.length > 0) {
          lastDeclaration = prevRes.data.answers[0].answer_set;
        }
        setAnswers((previousState) => ({
          ...previousState,
          currentAns: groupAnswers(currRes.data),
          prevAns: groupedRes ?? [],
          // If currentDec exists, remove it from total count
          count: currRes.data.length > 0 && prevRes.data.answers.length > 0 ? prevRes.data.info.count - 1 : 1,
          lastRecord: prevRes?.data?.info?.lastRecord ?? moment(),
          currentAutoScore: currRes.data[0]?.auto_score,
        }));
      } else {
        setAnswers((previousState) => ({
          ...previousState,
          currentAns: groupAnswers(currRes.data),
          currentAutoScore: currRes.data[0]?.auto_score,
        }));
      }
      if (selectedDate === moment().format("YYYY-MM-DD")) {
        setHasSubmission(currRes.data.length > 0);
      }
      if (currRes.data.length === 0 && selectedDate >= moment().format("YYYY-MM-DD")) {
        setIsEditMode(true);
        setCurrentView({
          previous: currentView.current,
          current: "Edit/Enter",
        });
      }
      getReflection(lastDeclaration);
      setAssignees([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, selectedDate, isPushed]);

  /**
   * Gets (limit) number of answer sets from previous dates
   * @param {int} limit Number of sets returned
   * @param {int} offset Number of days skipped before pulling records
   */
  const getNewPrevAns = async (limit, offset) => {
    setLoading(true);

    try {
      const newAns = { ...answers };
      // Fetch Previous Answers
      const result = await API.getPrevAns(user.id, limit, offset, user.timezone);
      newAns.prevAns = await groupByDate(result.data.answers, offset, user.timezone);
      setAnswers(newAns);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  /**
   * Sets the current selected months calendar ratings
   * @param {date} date Date used to get monthly ratings. *YYYY-MM-DD*
   */
  const getMonthRating = async (date, timezone) => {
    setLoading(true);
    try {
      // Fetch Monthly Ratings
      const result = await API.getMonthRating(user.id, date, timezone);
      setMonthlyRatings(result.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  /**
   * Used to update answers state locally when it's not needed to make an API call
   * @param {array} arr Answers that are being updated
   * @param {string} type A String to indicate which array is being updated ( Current or Previous )
   */
  const updateAnswerState = (arr, type, rating, page) => {
    if (type === "Current") {
      setAnswers((previousState) => ({
        ...previousState,
        currentAns: arr,
        currentAutoScore: rating,
      }));
    } else if (type === "Previous") {
      if (user.autoScoring) {
        getNewPrevAns(5, page - 1);
      } else {
        setAnswers((previousState) => ({
          ...previousState,
          prevAns: arr,
        }));
      }
    }
  };
  /**
   * Toggles isPushed state when an answer is pushed (Only to submitted current declarations)
   */
  const togglePushed = () => {
    setIsPushed(!isPushed);
  };

  /**
   * Updates the monthly rating without needing to make another request
   * @param {date} date Date of submission
   * @param {string} autoScore Rating given "Ex. Bad, Fair, Good, Excellent"
   */
  const updateMonthlyRating = (date, newRating, type) => {
    const copyArr = [...monthlyRatings];
    copyArr.forEach((rating, index) => {
      if (rating.date === date && type === undefined) {
        copyArr[index].auto_score = newRating;
      }
      if (rating.date === date && type === "rating") {
        copyArr[index].self_rating = newRating;
      }
    });
    setMonthlyRatings(copyArr);
  };

  const fetchFeedback = async (id, offset) => {
    const result = await API.likeCount(id, offset);
    setFeedback(result.data.result);
  };

  const getExpectations = async () => {
    try {
      const results = await API.getExpectations(user.id);
      // TODO: Muted expectations
      if (results.status === 200) {
        sortExpectations(results.data);
        if (groupSelected.expanded != null) {
          setGroupSelected(initialState);
        }
      }
    } catch (error) {}
  };

  const sortExpectations = (arr) => {
    // const mutedExpectations = [...new Set(results.data.muted.map((mutedExp) => mutedExp.id))];
    // Pull hidden dates from local storage
    let hidden = localStorage.getItem("blankDays");

    if (hidden) {
      hidden = JSON.parse(hidden);
      setHiddenDates(hidden);
    } else {
      hidden = [];
    }
    let start = moment().tz(user.timezone).format("YYYY-MM-DD");
    const dates = {};
    dates["To-Do"] = [[]];
    for (let i = 0; i < 15; i++) {
      dates[start] = [[]];
      start = moment(start).add(1, "days").format("YYYY-MM-DD");
    }

    Object.values(arr.filter((exp) => !hidden.includes(exp.date))).forEach((item) =>
      dates[item.date] != null ? dates[item.date][0].push(item) : dates["To-Do"][0].push(item)
    );
    setExpectations(dates);
    setHiddenExp([]);
  };

  const updateExpectations = async (type, data, updated) => {
    let copy = { ...expectations };
    if (Object.values(expectations).length === 0) {
      await getExpectations(user.id);
    }
    const currentDate = moment().tz(user.timezone).set("hour", 8).set("minute", 0).set("second", 0);
    const subDate = updated?.date !== "" ? moment(`${updated?.date}T10:00:00`).tz(user.timezone).set("hour", 8).set("minute", 0).set("second", 0) : "";

    let prevDates = null;
    if (type === "Add") {
      if (data.date != null) {
        // Date is hidden
        if (hiddenDates.includes(data.date)) {
          prevDates = [...hiddenDates];
          prevDates = prevDates.filter((item) => item !== data.date);
        }
        copy[data.date][0] !== undefined ? copy[data.date][0].push(data) : (copy[data.date][0] = data);
      } else {
        copy["To-Do"][0] !== undefined ? copy["To-Do"][0].push(data) : (copy["To-Do"][0] = data);
      }
    }
    if (type === "Delete" || type === "Update") {
      let newCopy;
      if (data?.date != null && copy[data?.date] != null) {
        newCopy = copy[data.date][0].filter((item) => item.id !== data.id);
        copy[data.date][0] = newCopy;
      } else {
        newCopy = copy["To-Do"][0].filter((item) => item.id !== data.id);
        copy["To-Do"][0] = newCopy;
      }
    }
    if (type === "Update" && (Math.abs(currentDate.diff(subDate, "weeks", true)) <= 2 || updated?.date == null)) {
      if (updated.date != null && copy[updated.date] != null) {
        copy[updated.date][0].push(updated);
      } else {
        copy["To-Do"][0] !== undefined ? copy["To-Do"][0].push(updated) : (copy["To-Do"][0] = updated);
      }
    }
    if (prevDates !== null) {
      setHiddenDates(prevDates);
      localStorage.setItem("blankDays", JSON.stringify(prevDates));
    }
    setExpectations(copy);
  };

  /**
   * Clears stored blank dates for expectations
   */
  const clearDates = () => {
    localStorage.removeItem("blankDays");
    setHiddenDates([]);
  };

  /**
   * Handles the drag and drop event for expectations
   * @param {object} data Expectation being moved
   * @param {date} date New date / location (YYYY-MM-DD)
   */
  const updateTransfer = async (data, date) => {
    if (data.date !== date && data.isCreator) {
      if (vaildateDate(date) && date >= moment().format("YYYY-MM-DD")) {
        if (moment(data.created_at).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD") && data.date !== date) {
          data.altered_count += 1;
        }
        try {
          // Update expectation record
          const results = await API.dragExpectation({ userId: user.id, id: data.id, date, alteredCount: data.altered_count });
          if (results.status === 200) {
            updateExpectations("Update", data, {
              ...results.data,
              email: data.email,
              first_name: user.first_name,
              last_name: user.last_name,
              status: data.status,
              color: data.color,
            });
          }
        } catch (error) {
          setNotification({
            message: "Unable to reassign to past dates",
            open: true,
            severity: "error",
          });
        }
      } else {
        setNotification({
          message: "Unable to reassign to past dates",
          open: true,
          severity: "error",
        });
      }
    } else {
      setNotification({
        message: "Only The Creator Can Update Expectations",
        open: true,
        severity: "error",
      });
    }
  };

  /**
   * Pulls the latest previous expectations
   */
  const pushBack = async () => {
    try {
      // Fallback incase expectation date is missing
      const date = Object.keys(expectations)[1] ?? moment().startOf("week").subtract(2, "weeks").format("YYYY-MM-DD");

      let results;
      if (groupSelected.expanded != null) {
        results = await API.getPreviousBulkExpectations(user.id, groupSelected.contributors, date);
      } else {
        results = await API.pushBack(user.id, date);
      }
      if (results.status === 200 && results.data.expectations.length > 0) {
        const copy = { ...expectations };
        copy[results.data.expectations[0].date] = [];
        copy[results.data.expectations[0].date][0] = [...results.data.expectations];
        let sorted = Object.keys(copy)
          .sort()
          .reduce((obj, key) => {
            obj[key] = copy[key];
            return obj;
          }, {});
        const lastElem = sorted["To-Do"];
        delete sorted["To-Do"];
        sorted = { "To-Do": lastElem, ...sorted };
        setExpectations(sorted);
      } else {
        setNotification({
          message: `No More Expectations Found (Last date checked: ${date})`,
          open: true,
          severity: "warning",
        });
      }
      if (results.status === 400) {
        setNotification({
          message: results.data.message,
          open: true,
          severity: "error",
        });
      }
    } catch (error) {
      setNotification({
        message: "Failed to pull previous expectations",
        open: true,
        severity: "error",
      });
    }
  };

  const selectedExpectation = async (data, isLink) => {
    let copy = null;
    if (data == null) {
      setSelected(null);
      return;
    }
    if (isLink) {
      // Fetch expectation based on the passed in id
      const response = await API.getExpectation(user.id, data);
      if (response.data.success && response.data.expectation.length > 0) {
        copy = { ...response.data.expectation[0], email: user.email };
        if (copy?.description != null) {
          const temp = parseJson(copy.description);
          copy.description = temp?.children == null ? { children: temp } : temp;
        }
        setSelected(copy);
        setIsClicked(!isClicked);
      }
    } else {
      copy = { ...data };
      if (data != null && data?.description) {
        const temp = parseJson(data.description);
        copy.description = temp?.children == null ? { children: temp } : temp;
      }
      setSelected(copy);
    }
  };

  const handleMutedState = async (data, type, open) => {
    const copy = [...hiddenExp];
    let newCopy;
    try {
      if (type === "Delete") {
        // Remove muted expectations
        await API.clearMuted(user.id, { id: data.mutedId });
        // Remove from state
        newCopy = copy.filter((record) => parseInt(record.mutedId, 10) !== parseInt(data.mutedId, 10));
        if (Object.keys(expectations).includes(data.date)) {
          const expCopy = { ...expectations };
          delete data.mutedId;
          expCopy[data.date][0].push({ ...data, id: data.id.toString() });
          setExpectations(expCopy);
        }
      } else if (type === "Add") {
        // Add to existing muted state
        const results = await API.insertMuted(user.id, data);
        if (results.data.success) {
          newCopy = [...copy, { mutedId: results.data.newRecord.mutedid, ...data }];
        }
        if (Object.keys(expectations).includes(data.date)) {
          const expCopy = { ...expectations };
          expCopy[data.date][0] = expCopy[data.date][0].filter((t) => t.id !== data.id);
          setExpectations(expCopy);
        }
        // muted_expectations.id AS "mutedId", exp_id AS id, status, description, to_char(date, 'YYYY-MM-DD') as date
      }
      setHiddenExp(newCopy);
      if (open !== undefined) {
        open(false);
      }
    } catch (error) {
      setHiddenExp([]);
    }
  };

  /**
   * Sends a request to create a new announcement
   * If it's the current date, append to existing announcements
   * @param {object} data Announcement information {subject, description, date}
   * @returns Request status :: Boolean
   */
  const createAnnouncement = async (data) => {
    let results;
    try {
      if (!data.createdAt) {
        data = { ...data, createdAt: moment().format("YYYY-MM-DD HH:mm:ss") };
      }
      data = { ...data, sender: user.first_name + " " + user.last_name };
      data.timezone = user.timezone;
      if (!data.id) {
        results = await API.newAnnouncement(user.id, data);
      } else {
        results = await API.updateAnnouncement(user.id, data);
      }
      if (results.data.success) {
        if (results.data.type !== "Draft") {
          const copy = [...announcements];
          copy.push(results.data.announcement);
          setAnnouncements(copy);
        }
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  /**
   * Pulls announcements based on the provided type
   * Type: Draft => All future announcements
   * @param {string} type Announcement type Ex. Draft
   * @returns
   */
  const getAnnouncements = async (type) => {
    try {
      const results = await API.getAnnouncements(user.id, type, user.timezone);
      if (results.data.success) {
        if (type === "Draft") {
          setDrafts(results.data.announcements);
        } else {
          setAnnouncements(results.data.announcements);
        }
      }
    } catch (error) {
      return false;
    }
  };

  const deleteAnnouncement = async (id) => {
    try {
      const results = await API.deleteAnnouncement(user.id, id);
      if (results.data.success) {
        // Remove deleted announcement from state
        const copy = [...drafts];
        setDrafts(copy.filter((item) => item.id !== id));
      }
    } catch (error) {
      return false;
    }
  };

  const handleEntry = (value) => {
    setIsEntryEmpty(value);
  };

  const getExpectationContributors = async () => {
    try {
      const results = await API.getContributors(user.id);
      if (results.status === 200) {
        setContributors(results.data.contributors);
      }
    } catch (error) {
      setNotification({
        message: "Failed to get contributors",
        open: true,
        severity: "error",
      });
    }
  };

  const handleSelection = async (component, selected, isSingle) => {
    if (component === groupSelected.expanded && !isSingle) {
      setGroupSelected(initialState);
      getExpectations();
    } else {
      if (selected?.length === 0) {
        return setNotification({
          message: `No ${component} Contributors Assigned`,
          open: true,
          severity: "error",
        });
      }

      try {
        const expectations = await API.getBulkExpectations(user.id, selected);
        if (expectations.status === 200) {
          const group = component != null ? component : contributors.filter((c) => c.id === selected?.[0])?.[0].isPrimary ? "Primary" : "Secondary";
          sortExpectations(expectations.data);
          setGroupSelected({
            expanded: group,
            contributors: selected,
          });
        }
      } catch (error) {
        setNotification({
          message: `Failed to get contributors expectations`,
          open: true,
          severity: "error",
        });
      }
    }
  };

  // ************************************************* REFLECTIONS ******************************************* //
  const getReflection = async (declaration) => {
    if (declaration == null) return;
    setLoading(true);
    try {
      const response = await API.getReflection(user.id, declaration);
      if (response.status === 200) {
        setLastReflection({
          ...response.data,
          isLocked: moment().diff(moment(response.data.lastCounted), "minute") < 5,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  /*********************************************************************************************************** */

  const getDayRating = (date) => {
    const record = monthlyRatings.filter((x) => x.date === date);

    return record[0]?.auto_score ?? null;
  };

  useEffect(() => {
    const getAssignedWorkflows = async () => {
      const results = await API.getUserWorkflows(user.id);
      if (results.status === 200) {
        setWorkflows(results.data.workflows);
      }
    };
    getAssignedWorkflows();
  }, [user.id]);

  useEffect(() => {
    socketHandler.subscribeToExpectations((err, data) => {
      if (err) return;
      const callExp = async () => {
        await getExpectations();
      };
      if (data.type === "Add") {
        if (data.newData.isCreator) {
          delete data.newData.isCreator;
        }
        updateExpectations(data.type, data.newData, data.prevData);
      } else if (data.type.toLowerCase() === "mention") {
        // Send notification
        setNotification({
          message: `${data.sentBy} mentioned you`,
          open: true,
          severity: "success",
        });
        callExp();
      } else {
        callExp();
      }
    });
    return () => socketHandler.unsubscribeFromExpectations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expectations]);

  useEffect(() => {
    getExpectations();
    getExpectationContributors();
    fetchFeedback(user.id, 0);
    socketHandler.test((err, data) => {
      if (err) return;
      setIncSocket(data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // UseEffects
  useEffect(() => {
    getAns();
  }, [getAns]);

  useEffect(() => {
    if (incSocket !== null) {
      let index = -1;
      if (Object.keys(feedback).length > 0) {
        const copy = [...feedback[user.id]];
        for (let i = 0; i < copy.length; i++) {
          if (parseInt(copy[i].answer_id, 10) === parseInt(incSocket.data.ansId) && copy[i].reviewer === incSocket.data.name) {
            index = i;
          }
        }
        if (index >= 0) {
          copy.splice(index, 1);
        } else {
          copy.push({ answer_id: incSocket.data.ansId, reviewer: incSocket.data.name });
        }
        setFeedback({ [user.id]: copy });
      } else {
        const newFeedback = {
          [user.id]: [
            {
              answer_id: incSocket.data.ansId,
              reviewer: incSocket.data.name,
            },
          ],
        };
        setFeedback(newFeedback);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incSocket]);

  useEffect(() => {
    socketHandler.subscribeToAutoStep((err, data) => {
      if (err) return;
      getExpectations();
    });

    return () => socketHandler.unsubscribeFromAutoStep();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  useEffect(() => {
    socketHandler.subscribeToExpectationChange((err, data) => {
      if (err || data == null) return;
      let containsExpectation = false;
      const date = data?.expectation?.date ?? "To-Do";
      const copy = { ...expectations };

      const newCopy = copy[date]?.[0]?.map((x) => {
        if (x.id === data.expectation.id) {
          containsExpectation = true;
          return {
            ...data?.expectation,
            is_showing: true,
            isCreator: false,
          };
        }
        return x;
      });

      copy[date][0] = newCopy ?? [];

      if (!containsExpectation) {
        // Update if reviewer and accepted
        copy[date][0].push(data.expectation);
      }

      setExpectations(copy);
    });

    return () => socketHandler.unsubscribeFromExpectationChange();
  }, [expectations]);

  /**
   * Initializing GAPI
   */
  useEffect(() => {
    loadGapiClient(setIsAuthenticated);
  }, []);

  return (
    <UserContext.Provider
      value={{
        loading,
        answers,
        monthlyRatings,
        feedback,
        expectations,
        userList,
        selected,
        hiddenExp,
        messageData,
        announcements,
        drafts,
        hasPermission: user.isAdmin || user.isGlobal,
        timezone: user.timezone,
        token,
        user,
        hiddenDates,
        isEntryEmpty,
        selectedDate,
        assignees,
        milestones,
        notification,
        isClicked,
        theme,
        workflows,
        contributors,
        groupSelected,
        currentView,
        hasSubmission,
        isAuthenticated,
        getNewPrevAns,
        getMonthRating,
        updateAnswerState,
        togglePushed,
        updateMonthlyRating,
        updateExpectations,
        fetchUserList,
        updateTransfer,
        selectedExpectation,
        handleMutedState,
        setToken,
        setMessageData,
        createAnnouncement,
        getAnnouncements,
        deleteAnnouncement,
        setExpectations,
        setHiddenDates,
        clearDates,
        handleEntry,
        pushBack,
        setAssignees,
        setMilestones,
        setNotification,
        getExpectations,
        setIsClicked,
        sortExpectations,
        handleSelection,
        getDayRating,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(UserContext);
};

export { UserContext, UserProvider };
