import React, { useContext, useState, useEffect, useRef } from "react";
import socketHandler from "../utils/socket";
import { validate } from "uuid";
import API from "../utils/API";

const MessageContext = React.createContext();

const MessageProvider = ({ user, currentView, setCurrentView, notification, setNotification, redirect, children }) => {
  const [isLoading, setIsLoading] = useState(false); // Flag used when fetching messages
  const [messages, setMessages] = useState({}); // Stored declaration messages
  const [isShowing, setIsShowing] = useState(false); // Flag used to show/hide declaration messages
  const [conversationSelected, setConversationSelected] = useState({}); // Current selected conversation
  const mountRef = useRef(true);

  /**
   * Fetch all associated messages for the selected declaration
   * @param {uuid} userId
   * @param {uuid} id The selected declaration ID
   */
  const getMessages = async (userId, id) => {
    // IF the declaration id is missing or if it's already stored.. return
    if (!id || Object.keys(messages).filter((key) => key === id).length > 0) return;
    setIsLoading(true);
    try {
      // TODO: Add user & reviewer validation
      const results = await API.getMessages(userId, id, user.id);
      if (results.status === 200) {
        // Append the results to the localState
        setMessages((previousState) => ({
          ...previousState,
          [id]: results.data,
        }));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setNotification({
        message: `Failed to get messages - "${error?.request?.response}"`,
        open: true,
        severity: "error",
      });
    }
  };

  const newMessage = async (data, scrollEvent) => {
    const { answerSet, userId } = data;
    try {
      // Validate uuids
      if (validate(answerSet) && validate(userId)) {
        const results = await API.createMessage({ ...data, sender: `${user.first_name} ${user.last_name}` });
        if (results.status === 200) {
          const newMessage = {
            ...results.data.result,
            sender_name: `${user.first_name} ${user.last_name}`,
            sender_email: user.email,
          };
          socketHandler.newMessage(newMessage);
          setMessages((previousState) => ({
            ...previousState,
            [newMessage.declaration_id]:
              previousState[newMessage.declaration_id] != null ? [...previousState[newMessage.declaration_id], newMessage] : [newMessage],
          }));
          scrollEvent("smooth");
          return 200;
        }
      } else {
        setNotification({
          message: `Invalid id, failed to create message`,
          open: true,
          severity: "error",
        });
        return;
      }
    } catch (error) {
      setNotification({
        message: `"${error?.request?.response}"`,
        open: true,
        severity: "error",
      });
    }
  };

  const handleSelected = (value) => {
    if (value != null) {
      const conversation = messages[value.answerSet]?.filter((a) => a.mode === value.mode && a.answer_id === parseInt(value.answerId, 10));
      if (conversation == null) {
        getMessages(user.id, value.answerSet);
      }

      setConversationSelected({ messages: conversation, ...value });
    }
  };

  useEffect(() => {
    if (!mountRef.current) {
      socketHandler.subscribeToMessages((err, data) => {
        if (err) {
          // HANDLE ME
        }
        setMessages((previousState) => ({
          ...previousState,
          [data.declaration_id]: [...messages[data.declaration_id], data],
        }));
      });
    }

    return () => {
      mountRef.current = false;
      socketHandler.unsubscribeFromMessages();
    };
  }, [messages]);

  useEffect(() => {
    if (redirect != null && Object.keys(messages).length === 0) {
      getMessages(redirect.ids[1], redirect.ids[0]);
    }
    if (redirect != null && Object.keys(messages).length > 0) {
      const onRedirect = async () => {
        const { data, status } = await API.getMessage(user.id, redirect.ids[0], redirect.messageid);
        if (status === 200) {
          handleSelected(data);
          if (currentView.current !== "Reviewer") {
            setCurrentView({
              previous: currentView.current,
              current: "Messages",
            });
          }
        }
      };
      onRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect, messages]);

  return (
    <MessageContext.Provider
      value={{
        isLoading,
        messages,
        isShowing,
        conversationSelected,
        currentView,
        setIsShowing,
        getMessages,
        newMessage,
        handleSelected,
        setConversationSelected,
        setCurrentView,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};
export const useMessageContext = () => {
  return useContext(MessageContext);
};

export { MessageContext, MessageProvider };
