import axios from "axios";

const API = {
  // ANSWERS
  shareAnswers: (data) => {
    return axios.post("/api/answers", data);
  },
  sendDec: (data) => {
    return axios.post("/api/answers/submit", data);
  },
  getDecData: (userid, date, timezone, dateChanged) => {
    return axios.get(`/api/answers/byUser/${userid}?date=${date}&timezone=${timezone}&changed=${dateChanged}`);
  },
  getPrevAns: (userId, limit, offset, timezone) => {
    return axios.get(`/api/answers/prev/byUser/${userId}?limit=${limit}&offset=${offset}&timezone=${timezone}`);
  },
  getMonthRating: (userId, month, timezone) => {
    return axios.get(`/api/answers/month/byUser/${userId}?month=${month}&timezone=${timezone}`);
  },
  getContributorsRatings: (userId, month, id) => {
    return axios.get(`/api/answers/month/byReviewer/${userId}${month !== undefined ? `?month=${month}` : ""}${id !== undefined ? `&id=${id}` : ""}`);
  },
  getReviewerAns: (userId, limit, offset, id) => {
    return axios.get(
      `/api/answers/reviewer/byUser/${userId}${limit !== undefined ? `?limit=${limit}` : ""}${offset !== undefined ? `&offset=${offset}` : ""}${
        id !== undefined ? `&id=${id}` : ""
      }`
    );
  },
  // PUSH
  pushAnswer: (data) => {
    return axios.post(`api/answers/push`, data);
  },
  // CHECKMARKS
  checkedChange: (data) => {
    return axios.patch("api/answers/checked", data);
  },
  // QUESTIONS
  getQuestions: (id) => {
    return axios.get(`/api/questions/${id}`);
  },
  // USERS
  getAllUsers: (admin, keywords) => {
    const config = {
      headers: { admin, keywords },
    };
    return axios.get("/api/users/all", config);
  },
  checkUser: (id) => {
    return axios.get(`/api/users/${id}`);
  },
  getEmployees: (userId) => {
    return axios.get(`/api/users/byManager/'${userId}'`);
  },
  // ADMIN USERS
  getSingleUser: (id) => {
    return axios.get(`/api/admin/users/${id}`);
  },
  getUsers: () => {
    return axios.get(`/api/admin/users`);
  },
  createUser: (payload) => {
    return axios.post(`/api/admin/users`, payload);
  },
  updateUser: (id, payload) => {
    return axios.patch(`/api/admin/users/${id}`, payload);
  },
  deleteUser: (id) => {
    return axios.delete(`/api/admin/users/${id}`);
  },
  getAdminEmployees: (admin, isAdmin) => {
    const config = {
      headers: { admin, isAdmin },
    };
    return axios.get("/api/admin/Employees", config);
  },
  updateUserInfo: (data) => {
    return axios.patch("/api/admin/Employees", data);
  },
  removeUser: (data) => {
    return axios.delete("/api/admin/Employees/delete", { data });
  },
  // ADMIN QUESTIONS
  getQuestionRelatedData: () => {
    return axios.get(`/api/admin/questions`);
  },
  createQuestionSet: (data) => {
    return axios.post("/api/admin/questions/set", data);
  },
  editQuestionSet: (data) => {
    return axios.patch("/api/admin/questions/set", data);
  },
  deleteQuestionSet: (id) => {
    return axios.delete(`/api/admin/questions/set/${id}`);
  },
  getQuestionTypes: () => {
    return axios.get("/api/admin/questions/type/all");
  },
  createQuestionType: (data) => {
    return axios.post("/api/admin/questions/type", data);
  },
  updateQuestionType: (data) => {
    return axios.patch("/api/admin/questions/type", data);
  },
  deleteQuestionType: (id) => {
    return axios.delete(`/api/admin/questions/type/${id}`);
  },
  getQuestion: (id) => {
    return axios.get(`/api/admin/questions/${id}`);
  },
  createQuestion: (data) => {
    return axios.post("/api/admin/questions", data);
  },
  updateQuestion: (data) => {
    return axios.patch("/api/admin/questions", data);
  },
  deleteQuestion: (id) => {
    return axios.delete(`/api/admin/questions/${id}`);
  },
  // ADMIN schedules
  getSchedules: () => {
    return axios.get("/api/admin/schedules");
  },
  createSchedule: (data) => {
    return axios.post("/api/admin/schedules", data);
  },
  updateSchedule: (data) => {
    return axios.patch("/api/admin/schedules", data);
  },
  deleteSchedule: (id) => {
    return axios.delete(`/api/admin/schedules/${id}`);
  },
  // POKE
  pokeUser: (id) => {
    return axios.post(`/api/poke/${id}`);
  },
  pokeReflection: (id) => {
    return axios.post(`/api/poke/reflection/${id}`);
  },
  // SELF RATING
  updateRating: (data) => {
    return axios.patch(`/api/answers/selfRating`, data);
  },
  checkRating: (id, date, timezone) => {
    return axios.get(`/api/answers/rating/byUser/${id}?date=${date}&timezone=${timezone}`);
  },
  // MESSAGES/RESPONSES
  getMessage: (userId, id, messageId) => {
    return axios.get(`/api/messages/redirect/${userId}?id=${id}&messageid=${messageId}`);
  },
  getMessages: (userId, id, user) => {
    const config = {
      headers: { admin: user },
    };
    return axios.get(`/api/messages/${userId}?id=${id}`, config);
  },

  createMessage: (data) => {
    return axios.post("/api/messages", data);
  },
  // TEAMS
  getTeams: () => {
    return axios.get("/api/teams");
  },
  getAssignedTeams: (id) => {
    return axios.get(`/api/teams/byMember/${id}`);
  },
  getSelectedTeam: (team_name) => {
    return axios.get(`/api/teams/${team_name}`);
  },
  createTeam: (data) => {
    return axios.post("/api/teams", data);
  },
  updateTeam: (data) => {
    return axios.patch("/api/teams", data);
  },
  deleteTeam: (data) => {
    return axios.delete("/api/teams", { data });
  },
  // TEAM MEMBERS
  getSelectedTeamMembers: (id) => {
    return axios.get(`/api/team/members/${id}`);
  },
  addTeamMember: (data) => {
    return axios.post("/api/team/members", { data });
  },
  updateTeamMember: (data) => {
    return axios.patch("/api/team/members", { data });
  },
  deleteTeamMember: (data) => {
    return axios.delete("/api/team/members", { data });
  },
  // SCHEDULES
  getSchedule: (id) => {
    return axios.get(`api/schedule/${id}`);
  },
  // QUESTION SETS
  getSets: () => {
    return axios.get("/api/question-set");
  },
  add_multi_sets: (data) => {
    return axios.post("/api/question-set/multi", data);
  },
  addDuplicateSet: (data) => {
    return axios.post("/api/question-set/duplicate", data);
  },
  addSet: (data) => {
    return axios.post("/api/question-set", data);
  },
  updateSet: (data) => {
    return axios.put("/api/question-set", data);
  },
  deleteSet: (data) => {
    return axios.delete("/api/question-set", { data });
  },
  // TAGS
  getUserTags: (id, limit, offset) => {
    return axios.get(`/api/tags/byUser/${id}${limit !== undefined ? `?limit=${limit}` : ""}${offset !== undefined ? `&offset=${offset}` : ""}`);
  },
  getTagColor: (id) => {
    return axios.get(`/api/tags/${id}`);
  },
  getTags: () => {
    return axios.get("/api/tags");
  },
  addTag: (data) => {
    return axios.post("/api/tags", data);
  },
  updateTag: (data) => {
    return axios.patch("/api/tags", data);
  },
  deleteTag: (data) => {
    return axios.delete("/api/tags", { data });
  },
  // ASSIGNED TAGS
  getUserAssignedTags: (id) => {
    return axios.get(`/api/assignedTags/${id}`);
  },
  getAssignedTags: () => {
    return axios.get("/api/assignedTags");
  },
  assignTag: (data) => {
    return axios.post("/api/assignedTags", data);
  },
  removeTag: (data) => {
    return axios.delete("/api/assignedTags", { data });
  },
  // SESSIONS
  login: (data) => {
    return axios.post("/api/sessions/login", data);
  },
  logout: (data) => {
    return axios.delete("/api/sessions/logout", { data });
  },
  clearSessions: (data) => {
    return axios.delete("/api/sessions/logoutAll", { data });
  },
  changePassword: (data) => {
    return axios.patch("/api/sessions/update", data);
  },
  sendResetLink: (data) => {
    return axios.post("/api/sessions/forgetPass", data);
  },
  resetPassword: (data) => {
    return axios.patch("/api/sessions/reset", data);
  },
  clearCookies: (data) => {
    return axios.delete("/api/sessions/refresh", data);
  },
  updateSettings: (data) => {
    return axios.post("/api/users/toggle", data);
  },
  // FEEDBACK
  likeCount: (id, offset, isReviewer, user) => {
    const config = {
      headers: { admin: id },
    };
    return axios.get(`/api/feedback/${user !== undefined ? user : id}?offset=${offset}${isReviewer !== undefined ? `&isReviewer=${isReviewer}` : ""}`, config);
  },
  feedback: (data) => {
    return axios.post("/api/feedback/like", data);
  },
  // OUTLOOK
  getEvents: (route, accessToken) => {
    const bearer = `Bearer ${accessToken}`;
    const config = {
      // eslint-disable-next-line no-undef
      headers: { Authorization: bearer, Prefer: 'outlook.body-content-type="text"' },
    };
    return axios.get(`${route}`, config);
  },
  // LOGS
  getLogs: (admin, dev) => {
    const config = {
      headers: { admin, dev },
    };
    return axios.get("/api/admin/logs", config);
  },
  // Expectations
  getExpectations: (userId) => {
    return axios.get(`/api/expectations/${userId}`);
  },
  getUserWorkflows: (userId) => {
    return axios.get(`/api/expectations/assigned/${userId}`);
  },
  getExpectation: (userId, id) => {
    return axios.get(`/api/expectations/single/${userId}?id=${id}`);
  },
  newExpectation: (data) => {
    return axios.post(`/api/expectations`, data);
  },
  upload: (admin, data) => {
    const config = {
      headers: { admin },
    };
    return axios.post(`/api/expectations/file`, data, config);
  },
  download: (userId, expId, fileId) => {
    const config = {
      responseType: "blob",
    };
    return axios.get(`/api/expectations/file/download/${userId}?expId=${expId}&fileId=${fileId}`, config);
  },
  delete: (userId, expId, fileId) => {
    return axios.delete(`/api/expectations/file/${userId}?expId=${expId}&fileId=${fileId}`);
  },
  updateExpectation: (data) => {
    return axios.patch(`/api/expectations`, data);
  },
  dragExpectation: (data) => {
    return axios.patch("/api/expectations/dragged", data);
  },
  deleteExpectation: (data) => {
    return axios.delete(`/api/expectations`, { data });
  },
  pushBack: (userId, date) => {
    return axios.get(`/api/expectations/pushBack/${userId}?date=${date}`);
  },
  getMuted: (userId) => {
    return axios.get(`/api/expectations/muted/${userId}`);
  },
  insertMuted: (userId, data) => {
    return axios.post(`/api/expectations/muted/${userId}`, data);
  },
  clearMuted: (userId, data) => {
    return axios.delete(`/api/expectations/muted/${userId}`, { data });
  },
  getComments: (userId, id, timezone) => {
    return axios.get(`/api/expectations/comments/${userId}?id=${id}&timezone=${timezone}`);
  },
  createComment: (data) => {
    return axios.post("/api/expectations/comments", data);
  },
  getMilestones: (userId, date) => {
    return axios.get(`/api/expectations/milestones/${userId}?startDate=${date.startDate}&endDate=${date.endDate}`);
  },
  getContributors: (userId) => {
    return axios.get(`/api/expectations/contributors/${userId}`);
  },
  getBulkExpectations: (userId, contributors) => {
    return axios.get(`/api/expectations/contributors/expectations/${userId}?contributors=${contributors.join(",")}`);
  },
  getPreviousBulkExpectations: (userId, contributors, date) => {
    return axios.get(`/api/expectations/contributors/expectations/previous/${userId}?contributors=${contributors.join(",")}&date=${date}`);
  },
  getExpectationNotifications: (userId) => {
    return axios.get(`/api/expectations/contributors/notifications/${userId}`);
  },
  updateExpectationNotification: (data) => {
    return axios.patch(`/api/expectations/contributors/notifications`, data);
  },
  // Announcements
  getAnnouncements: (admin, type, tz) => {
    const config = {
      headers: { admin, timezone: tz },
    };
    return axios.get(`/api/announcements/${type}`, config);
  },
  newAnnouncement: (admin, data) => {
    const config = {
      headers: { admin },
    };
    return axios.post("/api/announcements", data, config);
  },
  updateAnnouncement: (admin, data) => {
    const config = {
      headers: { admin },
    };
    return axios.patch("/api/announcements", data, config);
  },
  deleteAnnouncement: (admin, id) => {
    const config = {
      headers: { admin },
    };
    return axios.delete(`/api/announcements/${id}`, config);
  },
  // Contributor Review
  weeklyAnswers: (userId, id, offset, timezone) => {
    const config = {
      headers: { userId },
    };
    return axios.get(`/api/answers/contributorReview/${userId}?contributor=${id}&offset=${offset}&timezone=${timezone}`, config);
  },
  reviewed: (data) => {
    return axios.post(`/api/answers/contributorReview/reviewed`, data);
  },
  //*************************** REFLECTIONS ****************************/
  getReflection: (userId, set) => {
    return axios.get(`/api/answers/reflection/${userId}?set=${set}`);
  },
  updateReflection: (userId, data) => {
    return axios.patch(`/api/answers/reflection/${userId}`, data);
  },
  //*************************** REVIEWER DISCUSSION ****************************/
  getDiscussion: (userId, id) => {
    return axios.get(`/api/reviewer/discussion/${userId}?id=${id}`);
  },
  newMessage: (data) => {
    return axios.post(`/api/reviewer/discussion`, data);
  },
  //**************************** ACTIVITY FEED ***********************************/
  getActivities: (userId, limit, offset, reviewer, isReviewer) => {
    const config = {
      headers: {
        id: userId,
      },
    };
    return axios.get(`/api/activityFeed/${reviewer ?? userId}?offset=${offset}&limit=${limit}&isReviewer=${isReviewer}`, config);
  },
  createActivity: (data) => {
    return axios.post(`/api/activityFeed`, data);
  },
  //******************************** KPI ****************************************/
  getKPITasks: (userId, id, range) => {
    return axios.get(`/api/reviewer/kpi/tasks/${userId}?id=${id}&range=${range}`);
  },
  getKPIReflections: (userId, id, range) => {
    return axios.get(`/api/reviewer/kpi/reflections/${userId}?id=${id}&range=${range}`);
  },
  getKPIRatings: (userId, id, range) => {
    return axios.get(`/api/reviewer/kpi/ratings/${userId}?id=${id}&range=${range}`);
  },
  getKPIAutoScore: (userId, id, range) => {
    return axios.get(`/api/reviewer/kpi/autoScore/${userId}?id=${id}&range=${range}`);
  },
  //*************************** ADMIN ****************************/
  getStoredWorkflows: (userId) => {
    return axios.get(`/api/admin/expectations/${userId}`);
  },
  getSelectedWorkflow: (userId, id) => {
    return axios.get(`/api/admin/expectations/workflow/${userId}?id=${id}`);
  },
  createWorkflow: (data) => {
    return axios.post("/api/admin/expectations/workflow", data);
  },
  updateOrder: (data) => {
    return axios.patch("/api/admin/expectations/workflow/order", data);
  },
  updateWorkflow: (data) => {
    return axios.patch("/api/admin/expectations/workflow", data);
  },

  createStatus: (data) => {
    return axios.post("/api/admin/expectations/status", data);
  },
  updateStatus: (data) => {
    return axios.patch("/api/admin/expectations/status", data);
  },
  removeStatus: (data) => {
    return axios.patch("/api/admin/expectations/status/remove", data);
  },
  //*************************** CONTRIBUTOR PROFILES ****************************/
  getWorkflows: (userId, contributor) => {
    return axios.get(`/api/reviewer/update/workflows/${userId}?contributor=${contributor}`);
  },
  assignWorkflow: (data) => {
    return axios.patch("/api/reviewer/update/workflows", data);
  },
  getAssignedWorkflows: (userId, contributor) => {
    return axios.get(`/api/reviewer/update/workflows/assigned/${userId}?contributor=${contributor}`);
  },
  getWorkflowStatus: (userId, contributor, id) => {
    return axios.get(`/api/reviewer/update/workflows/status/${userId}?contributor=${contributor}&id=${id}`);
  },
  removeWorkflow: (data) => {
    return axios.patch(`/api/reviewer/update/workflows/unassign`, data);
  },
  updateContributor: (data) => {
    return axios.patch("/api/reviewer/update/about", data);
  },
  updateContributorsSchedule: (data) => {
    return axios.patch("/api/reviewer/update/schedule", data);
  },
  getStoredSchedules: (userId, contributor) => {
    return axios.get(`/api/reviewer/update/schedule/${userId}?contributor=${contributor}`);
  },
  getQuestionSets: (userId, contributor) => {
    return axios.get(`/api/reviewer/update/questionset/${userId}?contributor=${contributor}`);
  },
  updateQuestionSet: (data) => {
    return axios.patch(`/api/reviewer/update/questionset`, data);
  },
  //*************************** Who's Away ****************************/
  whosAway: () => {
    return axios.get("/api/users/whosaway");
  },
};

export default API;
