import moment from "moment";
import API from "../../utils/API";
import socketHandler from "../../utils/socket";
import { updateAnswers } from "../../Pages/Reviewer/Components/Utils/updateAnswers";

export const updateFeedback = async (data, setIsLiked, isLiked, id, reviewer, state, setState, index, question, date) => {
  updateAnswers(!isLiked, reviewer, state, setState, index);
  try {
    const tempAnswers = [...reviewer];
    for (let i = 0; i < tempAnswers.length; i += 1) {
      if (tempAnswers[i][question] !== undefined && tempAnswers[i][question].length > 0) {
        const copy = [...tempAnswers[i][question]];
        for (let j = 0; j < copy.length; j += 1) {
          if (copy[j].answer_id === data.ansId) {
            copy[j] = {
              ...copy[j],
              type: data.type,
              feedbackat: copy[j].feedbackat === undefined || copy[j].feedback === null ? moment().format("YYYY-MM-DD HH:mm:ss") : null,
            };
            tempAnswers[i][question] = copy;
          }
        }
      }
    }
    socketHandler.sendLike({
      liked: !isLiked,
      data,
      userId: id,
    });
    setIsLiked(!isLiked);
    await API.feedback(data);
  } catch (error) {
    return;
  }
};
