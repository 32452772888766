import { gapi } from "gapi-script";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
const SCOPES = "https://www.googleapis.com/auth/calendar.events";

const updateSignInStatus = (isSignedIn, setIsAuthenticated) => {
  setIsAuthenticated(isSignedIn);
};

export const initClient = (setIsAuthenticated) => {
  gapi.client
    .init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
    })
    .then(() => {
      const authInstance = gapi.auth2.getAuthInstance();

      if (authInstance) {
        authInstance.isSignedIn.listen((s) => updateSignInStatus(s, setIsAuthenticated));

        updateSignInStatus(authInstance?.isSignedIn.get(), setIsAuthenticated);
      }
    })
    .catch((error) => {
      console.error("Error initializing gapi client:", error);
      setIsAuthenticated(false);
    });
};

export const loadGapiClient = (setIsAuthenticated) => {
  gapi.load("client:auth2", () => initClient(setIsAuthenticated));
};
