import React, { useState, useEffect, Suspense, lazy } from "react";
import moment from "moment-timezone";
import ReviewerWrapper from "../SideNav/Components/ReviewerWrapper";
import { useReviewerContext } from "../../contexts/ReviewerContext";
import { useMessageContext as useMessageContext2 } from "../../contexts/NewMessageContext";
import { ContainerOfThings } from "../../CSS/styled";
import { getIdFromUrl } from "../../utils/utils";
import API from "../../utils/API";
import styled from "styled-components";

const Header = lazy(() => import("./Components/Header"));
const Contributors = lazy(() => import("./Components/Contributors/Contributors"));
const Declarations = lazy(() => import("./Components/Declarations/Declarations"));
const Declaration = lazy(() => import("./Components/Declarations/Components/Declaration"));
const Footer = lazy(() => import("./Components/Footer"));
const KPI = lazy(() => import("./Components/KeyPerformanceIndicator/KeyPerformanceIndicator"));
const ReviewerDiscussion = lazy(() => import("./Components/ReviewerDiscussion/ReviewerDiscussion"));
const BreadCrumbs = lazy(() => import("./Components/BreadCrumbs"));
const Messages = lazy(() => import("../Messages/Index"));
const ContributorProfile = lazy(() => import("./Components/Contributors/Profile/ContributorProfile"));

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  gap: 10px;
`;

const Content = styled.div`
  display: flex;
  height: ${(props) => (props.isMobileProfile ? "100vh" : "100%")};
  overflow: hidden;
  gap: 10px;
`;

const DeclarationContainer = styled.div`
  flex: 4 1 50%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;

  @media (min-width: 1920px) {
    flex: 1 1 auto;
  }

  @media (max-width: 425px) {
    display: ${(props) => (props.isMobile ? "none" : "unset")};
  }
`;

const MessageWrapper = styled.div`
  flex: 0 1 30%;
  max-width: 30%;
`;

const Reviewer = (props) => {
  const [selectedContributor, setSelectedContributor] = useState(null);
  const [selected, setSelected] = useState({}); // Holds the selected declaration
  const [answers, setAnswers] = useState({}); // Stores the declarations for the selected week
  const [page, setPage] = useState(1); // Current Pagination page
  const [totalPages, setTotalPages] = useState(1); // Total pages based on the contriubutors oldest record
  const [range, setRange] = useState("day"); // Stores the selected date range for the KPI component
  const [showProfile, setShowProfile] = useState(false); // Flag to display contributor profiles

  const { reviewer, theme, id, setReviewer, timezone, fetchUserList, userList, setNotification, updateContributors } = useReviewerContext();
  const { getMessages, conversationSelected, setConversationSelected } = useMessageContext2();

  const updateReviewed = async (answerSet) => {
    if (selected && !selected.isReviewed) {
      const results = await API.reviewed({ userId: id, answerSet });
      if (results.data.success) {
        const copy = [...reviewer.contributors];
        copy.map((contributor) => {
          if (contributor.employee_id === selectedContributor.employee_id) {
            return (contributor.isReviewed = true);
          }
          return contributor;
        });
        setReviewer((previousState) => ({
          ...previousState,
          contributors: copy,
        }));
      }
    }
  };

  const handleSelected = (selected) => {
    if (!selected) return;

    setSelectedContributor(selected);
  };

  const toggleProfile = () => {
    setShowProfile(!showProfile);
  };

  useEffect(() => {
    if (props.redirect != null && props.employees != null) {
      const contributor = props.employees.filter((user) => user.employee_id === props.redirect.ids[1]);
      if (contributor.length > 0) {
        setSelectedContributor(contributor[0]);
      }
    } else if (reviewer.contributors && !selectedContributor) {
      setSelectedContributor(reviewer.contributors[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.employees]);

  useEffect(() => {
    if (!selectedContributor) return;
    const pageCount = Math.abs(moment(selectedContributor.lastRecord).startOf("week").diff(moment().tz(selectedContributor.timezone), "weeks")) + 1;
    setTotalPages(pageCount);
    setSelected({});
    setAnswers({});
    setConversationSelected({});
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContributor]);

  useEffect(() => {
    if (selected?.declaration != null && Object.keys(selected?.declaration).length > 0) {
      const answerSet = [].concat.apply([], Object.values(selected.declaration))[0].answer_set;
      getMessages(selectedContributor.employee_id, answerSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected?.declaration]);

  useEffect(() => {
    getIdFromUrl(window.location.href);
  }, []);

  return (
    <ContainerOfThings>
      <ReviewerWrapper
        setCurrentView={props.setCurrentView}
        currentView={props.currentView}
        isNavOpen={props.isNavOpen}
        setIsNavOpen={props.setIsNavOpen}
        width={props.width}
        theme={theme}
        user={props.user}
        type={"Reviewer"}
        updateReviewerMode={props.updateReviewerMode}
        employees={props.employees}
      />
      <ContentWrapper>
        <Suspense fallback={<></>}>
          {props.currentView.current !== "KPI" && (
            <BreadCrumbs
              theme={theme}
              currentView={props.currentView}
              contributor={selectedContributor}
              declaration={selected?.declaration}
              setSelected={setSelected}
              setAnswers={setAnswers}
              setSelectedContributor={setSelectedContributor}
              setShowProfile={setShowProfile}
            />
          )}
          <Header
            theme={theme}
            contributors={props.employees}
            currentView={props.currentView}
            setCurrentView={props.setCurrentView}
            selectedContributor={selectedContributor}
            handleSelected={handleSelected}
            range={range}
            setRange={setRange}
            width={props.width}
          />
        </Suspense>
        {props.currentView.current === "Reviewer" && (
          <Content isMobileProfile={props.width <= 768 && showProfile} isOpen={conversationSelected.answerSet != null}>
            <Suspense fallback={<></>}>
              <Contributors
                selectedContributor={selectedContributor}
                setSelectedContributor={setSelectedContributor}
                theme={theme}
                width={props.width}
                conversationSelected={conversationSelected}
                toggleProfile={toggleProfile}
                showProfile={showProfile}
              />
            </Suspense>
            {!showProfile && (
              <Suspense fallback={<></>}>
                <Declarations
                  selectedContributor={selectedContributor}
                  reviewer={id}
                  answers={answers}
                  setAnswers={setAnswers}
                  theme={theme}
                  selected={selected}
                  setSelected={setSelected}
                  updateReviewed={updateReviewed}
                  page={page}
                  width={props.width}
                  conversationSelected={conversationSelected}
                />
              </Suspense>
            )}
            {selectedContributor && showProfile && (
              <ContributorProfile
                theme={theme}
                id={props.user.id}
                contributor={selectedContributor}
                toggleProfile={toggleProfile}
                userList={userList}
                fetchUserList={fetchUserList}
                setNotification={setNotification}
                updateContributors={updateContributors}
              />
            )}
            {selectedContributor && !showProfile && (
              <DeclarationContainer isMobile={props.width <= 425 && conversationSelected.answerSet != null}>
                <Suspense fallback={<></>}>
                  <Declaration selected={selected} answers={answers} setAnswers={setAnswers} theme={theme} contributor={selectedContributor} />
                </Suspense>
                <Footer theme={theme} page={page} totalPages={totalPages} setPage={setPage} />
              </DeclarationContainer>
            )}
            {conversationSelected.answerSet != null && (
              <MessageWrapper>
                <Messages theme={theme} user={{ id, timezone }} setNotification={{}} />
              </MessageWrapper>
            )}
          </Content>
        )}
        {props.currentView.current === "KPI" && (
          <Suspense fallback={<></>}>
            <KPI
              width={props.width}
              selectedContributor={selectedContributor}
              setSelectedContributor={setSelectedContributor}
              contributors={props.employees}
              range={range}
            />
          </Suspense>
        )}
        {props.currentView.current === "Reviewer Discussion" && (
          <Suspense fallback={<></>}>
            <Content>
              <ReviewerDiscussion reviewer={props.user} width={props.width} contributor={selectedContributor} setSelectedContributor={setSelectedContributor} />
            </Content>
          </Suspense>
        )}
      </ContentWrapper>
    </ContainerOfThings>
  );
};

export default Reviewer;
