import React, { useEffect } from "react";
import Sidenav from "../Sidenav";
import Parent from "./Contributors/Parent";
import { useReviewerContext } from "../../../contexts/ReviewerContext";

import { FaUserTie, FaUserFriends, FaStar } from "react-icons/fa";

const ReviewerWrapper = (props) => {
  const { filters, theme } = useReviewerContext();
  let favourites = localStorage.getItem("favourites")?.split(",");

  const contributors = {
    primary: (
      <Parent
        group={"Primary"}
        contributors={props.employees
          .filter((contributor) => contributor.manager_id === props.user.id)
          ?.map((x) => {
            if (filters.includes(x.email)) {
              x.isFiltered = true;
            } else if (x.isFiltered) {
              x.isFiltered = false;
            }
            return x;
          })}
        icon={<FaUserTie />}
        theme={theme}
        isFiltered={filters.includes("Primary")}
      />
    ),
    secondary: (
      <Parent
        group={"Secondary"}
        contributors={props.employees
          .filter((contributor) => contributor.sReviewers.includes(props.user.id))
          ?.map((x) => {
            if (filters.includes(x.email)) {
              x.isFiltered = true;
            } else if (x.isFiltered) {
              x.isFiltered = false;
            }
            return x;
          })}
        icon={<FaUserFriends />}
        theme={theme}
        isFiltered={filters.includes("Secondary")}
      />
    ),
    stars: (
      <Parent
        group={"Stars"}
        contributors={props.employees
          .filter((contributor) => favourites?.includes(contributor.email))
          ?.map((x) => {
            if (filters.includes(x.email)) {
              x.isFiltered = true;
            } else if (x.isFiltered) {
              x.isFiltered = false;
            }
            return x;
          })}
        icon={<FaStar />}
        theme={theme}
        isFiltered={filters.includes("Stars")}
      />
    ),
  };

  useEffect(() => {
    props.updateReviewerMode();
    props.setCurrentView({ previous: props.currentView.current, current: "Reviewer" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return props.isNavOpen ? <Sidenav {...props} contributors={contributors} /> : <></>;
};

export default ReviewerWrapper;
