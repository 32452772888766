import React from "react";
import moment from "moment-timezone";
import styled from "styled-components";

const Time = styled.div`
  @media (min-width: 320px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const TimeView = ({ data, timezone, selectedDate }) => {
  let date;
  if (data) {
    date = data[0].average !== null ? data[0].average : data[0].date;
  } else {
    date = moment(selectedDate).tz(timezone);
  }
  return (
    <Time>
      {data ? (
        <>
          <span>{moment(date).format("MMMM Do YYYY ")}</span>
          {moment(date).format("YYYY-MM-DD") > moment(data[0]?.date_submitted).add(5, "hours").format("YYYY-MM-DD") ? null : (
            <span>{moment(date).format("LT")}</span>
          )}
        </>
      ) : (
        <span>{moment(selectedDate).format("DD-MMM")}</span>
      )}
    </Time>
  );
};

export default TimeView;
