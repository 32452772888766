/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, Suspense } from "react";
import styled from "styled-components";
import { FaShareAlt, FaPlus } from "react-icons/fa";
import { useGlobalContext } from "../contexts/context";
import { useMessageContext as useMessageContext2 } from "../contexts/NewMessageContext";
import { Card, CardHeader, CardBody } from "../CSS/styled";
import socketHandler from "../utils/socket";

// Components
import TimeView from "./form/components/TimeView";
import Question from "../components/CurrentDec/Question";
import moment from "moment-timezone";
import { themes } from "../CSS/themes";
import { getSubmissionColor, getAutoScoreColor } from "../utils/utils";
import NotificationIcon from "../Pages/Messages/Components/NotificationIcon";

// Lazy Components
// const SubmissionTimer = React.lazy(() => import("../components/CurrentDec/SubmissionTimer"));
const ShareModal = React.lazy(() => import("../components/Share/ShareModal"));

const NoData = styled.div`
  padding: 0 2.5em;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 1.2em;
`;

const NewTask = styled.div`
  display: none;
  background-color: ${(props) => themes[props.theme].primary};
  color: ${(props) => themes[props.theme].secondaryFontColor};
  width: fit-content;
  padding: 0.5em;
  border-radius: 50%;
  align-self: flex-end;
  margin-bottom: 0.5em;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  margin-right: 0.3em;
  @media (min-width: 1024px) {
    display: block;
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0 0.5em;
  color: ${(props) => themes[props.theme].secondary};
`;

export default function CurrentDec(props) {
  const { answers, theme, getDayRating } = useGlobalContext();
  const { getMessages } = useMessageContext2();
  const [answerSet, setAnswerSet] = useState(null);
  const [openShareModal, setOpenShareModal] = useState(false);
  const cardRef = useRef();

  const question = Object.keys(answers.currentAns)[0];

  useEffect(() => {
    if (props.selectedDate != null) {
      const answerSetID = [].concat.apply([], Object.values(answers.currentAns))?.[0]?.answer_set;
      setAnswerSet(answerSetID);
      getMessages(props.user.id, answerSetID);

      socketHandler.joinMessageRoom(answerSetID);
    }
  }, [answers.currentAns]);

  return (
    <Card theme={theme} style={{ overflow: "auto", height: "100%" }}>
      <CardHeader
        isOpen={true}
        submissionRating={
          props.user.autoScoring
            ? getAutoScoreColor(getDayRating(props.selectedDate), theme, false, answers.currentAns[question])
            : getSubmissionColor(answers.currentAns[question], theme, false)
        }
      >
        <TimeView data={answers.currentAns[question]} timezone={props.user.timezone} selectedDate={props.selectedDate} />
        {Object.keys(answers.currentAns).length > 0 && (
          <IconContainer theme={theme}>
            <NotificationIcon
              theme={theme}
              data={{
                answerId: answers?.currentAns[question]?.[0]?.answer_id,
                answerSet: answers?.currentAns[question]?.[0]?.answer_set,
                mode: "dec",
                title: "Declaration",
              }}
              isHeader
            />
            <FaShareAlt onClick={() => setOpenShareModal(true)} />
          </IconContainer>
        )}
      </CardHeader>
      <CardBody ref={cardRef} isAnswers={Object.keys(answers.currentAns).length > 0}>
        {openShareModal ? (
          <Suspense fallback={<></>}>
            <ShareModal
              recentAnswers={answers.currentAns}
              questions={props.questions}
              user={props.user}
              isOpen={openShareModal}
              setIsOpen={setOpenShareModal}
            />
          </Suspense>
        ) : null}
        {Object.keys(answers.currentAns).length > 0
          ? Object.keys(answers.currentAns).map((question, index) => {
              return (
                <React.Fragment key={question}>
                  <Question question={question} answerSet={answerSet} {...props} />
                </React.Fragment>
              );
            })
          : null}
        {Object.keys(answers.currentAns).length === 0 && <NoData>No Declaration Has Been Submitted For Today</NoData>}
      </CardBody>
      {props.selectedDate === moment().format("YYYY-MM-DD") && (
        <NewTask
          theme={theme}
          onClick={() => {
            props.setIsShowing(!props.isShowing);
            props.setIndex(0);
            props.setIsEditMode(true);
            props.setSelected({
              type: "current",
              date: moment().format("YYYY-MM-DD"),
            });
          }}
        >
          <FaPlus size={24} />
        </NewTask>
      )}
    </Card>
  );
}
