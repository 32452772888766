import React, { useState } from "react";
import { parseJson } from "../components/CustomEditor/utils";
import Answer from "../components/Misc/Answer";
import Like from "../components/Feedback/Like";
import CompletionMarker from "../components/Misc/CompletionMarker";
import NotificationIcon from "../Pages/Messages/Components/NotificationIcon";
import styled from "styled-components";
import { useGlobalContext } from "../contexts/context";
import { Tasks } from "../CSS/styled";
import { themes } from "../CSS/themes";

const QuestionContainer = styled.div`
  @media (min-width: 320px) {
    padding: 1em;
    font-size: 0.9em;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  @media (min-width: 1024px) {
    display: ${(props) => (props.isOpen ? "block" : "none")};
    width: unset;
  }
  @media (min-width: 1920px) {
    display: block;
  }

  :hover {
    background: ${(props) => (props.showIcons ? themes[props.theme].surfaceHover : "")};
  }
`;
const QuestionWrapper = styled.div`
  box-sizing: border-box;
  margin-bottom: 0.5em;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const PrevAnswerContainer = ({ innerArray, question, i, index, page, isChanged, cardOpened, setCardOpened, answerSet, ...props }) => {
  const [showIcons, setShowIcons] = useState(false);
  const { answers, feedback, updateAnswerState, updateMonthlyRating, selectedExpectation, theme, togglePushed, setNotification, hasSubmission } =
    useGlobalContext();

  return (
    <div>
      <QuestionContainer theme={theme} isOpen={cardOpened === index} showIcons={showIcons}>
        <QuestionWrapper onMouseEnter={() => setShowIcons(true)} onMouseLeave={() => setShowIcons(false)}>
          {question}
          {showIcons && <NotificationIcon theme={theme} data={{ answerId: innerArray?.[0]?.answer_id, answerSet: answerSet, mode: "ans", title: question }} />}
        </QuestionWrapper>

        <div style={{ display: "flex", flexDirection: "column", wordBreak: "break-word" }}>
          {innerArray?.map((ans) => {
            const ableToPush = (props.questions[i]?.question_type && props.questions[i]?.type?.push) || props.questions[i]?.question_type === null;
            let count = 0;
            const managers = [];
            if (feedback[props.user.id]) {
              feedback[props.user.id].forEach((item) => {
                if (item.answer_id === ans.answer_id) {
                  count++;
                  managers.push(item.reviewer);
                }
              });
            }
            const groupedAnswers = innerArray.filter((value) => value.parent === ans.parent);
            let isLastChild = false;
            let isOnlyParent = false;
            if (groupedAnswers && groupedAnswers[groupedAnswers.length - 1].answer_id === ans.answer_id) {
              isLastChild = true;
            }
            if (groupedAnswers.length === 1) {
              isOnlyParent = true;
            }
            return (
              <div
                key={"PrevDec: Answer" + ans.answer_id}
                draggable={ans.type !== null ? ans.type.toLowerCase() === "milestone" : false}
                onDragStart={(e) => {
                  if (ans.type !== null && ans.type.toLowerCase() === "milestone") {
                    const data = {
                      value: parseJson(ans.answer),
                      type: ans.type,
                    };
                    e.dataTransfer.setData("text/plain", JSON.stringify(data));
                  } else {
                    e.dataTransfer.effectAllowed = "none";
                  }
                }}
                style={{ marginLeft: ans.isChild ? ".5em" : "unset", position: "relative", padding: "0.2em 1em 0 1em" }}
              >
                <Container theme={theme}>
                  {ans.checkbox ? (
                    <Tasks
                      isChild={ans.isChild}
                      isParent={ans.parent}
                      isChecked={ans.checked}
                      isLastChild={isLastChild}
                      isOnlyParent={isOnlyParent}
                      theme={theme}
                    >
                      <CompletionMarker
                        answer={ans.answer_id}
                        set={{ ansSet: answers.prevAns, type: "Previous", index }}
                        row={0}
                        tooltip={ans.checkbox_tooltip}
                        selectedDate={props.selectedDate}
                        checked={ans.checked}
                        checkedUpdate={props.checkedUpdate}
                        timezone={props.user.timezone}
                        userId={props.user.id}
                        autoScoring={props.user.autoScoring}
                        date={ans.date}
                        isFirst={index === 0 && (page === 1 || (isChanged && page === 2))}
                        past={true}
                        updateAnswerState={updateAnswerState}
                        updateMonthlyRating={updateMonthlyRating}
                        isParent={ans.parent && !ans.isChild}
                        data={ans}
                        question={ans.question}
                        theme={theme}
                        text={ans.answer}
                        page={page}
                        setNotification={props.setNotification}
                      />
                    </Tasks>
                  ) : null}
                  <Answer
                    ans={ans}
                    view={"contributor"}
                    selectedExpectation={selectedExpectation}
                    theme={theme}
                    pushData={{
                      userId: props.user.id,
                      answerId: ans.answer_id,
                      answer: ans.answer,
                      question: ans.question,
                      questionId: ans.question_id,
                      isEditMode: props.isEditMode,
                      togglePushed,
                      setNotification,
                      hasSubmission,
                      ableToPush,
                    }}
                    isPrevious
                  />
                  {count > 0 ? (
                    <Like
                      theme={theme}
                      view={"Contributor"}
                      feedback={feedback}
                      ansId={ans.answer_id}
                      count={count}
                      managers={managers}
                      contributor={props.user.id}
                    />
                  ) : null}
                </Container>
              </div>
            );
          })}
        </div>
      </QuestionContainer>
    </div>
  );
};

export default PrevAnswerContainer;
