import {
  FaUsers,
  FaEdit,
  FaUserCog,
  FaCalendar,
  FaCloud,
  FaNewspaper,
  FaIdCard,
  FaUserTie,
  FaEraser,
  FaUnlockAlt,
  FaBullhorn,
  FaSignOutAlt,
  FaHome,
  FaQuestionCircle,
  FaCalendarAlt,
  FaListAlt,
  FaFileAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { themes } from "../../../CSS/themes";

export const getOptions = (value, theme, width) => {
  if (!value) return [];
  const option = {
    pages: [
      { name: "Home", icon: <FaHome style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "/", type: "Home" },
      { name: "Contributors", icon: <FaUsers style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "/review", type: "Contributor" },
      { name: "Previous", icon: <FaCloud style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "/", type: "Contributor" },
      { name: "Edit/Enter", icon: <FaEdit style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "/", type: "Contributor" },
      { name: "Expectations", icon: <FaIdCard style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "/", type: "Contributor" },
      {
        name: "Contributors",
        icon: <FaUsers style={{ marginRight: ".5em", color: themes[theme].secondary }} />,
        to: "/review",
        type: "Reviewer",
      },
      { name: "Calendar/Events", icon: <FaCalendar style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "/" },
      { name: "Announcements", icon: <FaBullhorn style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "/" },
      { name: "What's New?", icon: <FaNewspaper style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "/" },
      { name: "Settings", icon: <FaUserCog style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "/" },
      { name: "Users", icon: <FaUsers style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "/admin", type: "Admin" },
      { name: "Questions", icon: <FaQuestionCircle style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "/admin", type: "Admin" },
      { name: "Schedules", icon: <FaCalendarAlt style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "/admin", type: "Admin" },
      { name: "Workflows", icon: <FaListAlt style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "/admin", type: "Admin" },
      { name: "Logs", icon: <FaFileAlt style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "/admin", type: "Admin" },
    ],
    settings: [
      { name: "Admin Panel", icon: <FaUserTie style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "/admin" },
      { name: "Change Password", icon: <FaEraser style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "" },
      { name: "Clear Sessions", icon: <FaUnlockAlt style={{ marginRight: ".5em", color: themes[theme].secondary }} />, to: "" },
    ],
    toggleOptions: [
      { name: "Achievements", key: "achievements" },
      { name: "Self Rating", key: "selfRating" },
      { name: "Auto Scoring", key: "autoScoring" },
      { name: "Theme", key: "theme" },
    ],
    excludedOptions: width >= 1200 ? ["Previous", "Edit/Enter", "Expectations", "Calendar/Events"] : ["Previous", "Edit/Enter", "Expectations"],
    footer: (logout) => (
      <Link style={{ paddingLeft: "2.5em", textDecoration: "none", color: themes[theme].surfaceFontColor }} to="/" onClick={() => logout()}>
        <FaSignOutAlt style={{ marginRight: ".5em", color: themes[theme].primary }} /> Logout
      </Link>
    ),
  };
  return option[value];
};
