import { gapi } from "gapi-script";
import API from "../../../utils/API";

export const clearSessions = async (id, setMessage) => {
  const clearSessions = await API.clearSessions({ userId: id });
  if (clearSessions.data.valid) {
    setMessage(clearSessions.data.message);
    setTimeout(() => {
      setMessage("");
    }, 2500);
  }
};

export const handleSignInOptions = (isAuthenticated, isOpen, setIsOpen, option, setOption) => {
  if (isOpen) {
    setIsOpen(!isOpen);
  }
  if (option === null) {
    if (isAuthenticated) {
      gapi.auth2?.getAuthInstance().signOut();
      // setOption("signout");
    } else {
      gapi.auth2
        ?.getAuthInstance()
        ?.signIn()
        .then()
        .catch((error) => {
          if (error.error === "popup_closed_by_user") {
            console.log("user closed the authentication popup.");
          } else {
            console.error(error);
          }
        });

      // setOption("signin");
    }
  } else {
    setOption(null);
  }
};

export const handleClick = (page, currentView, setCurrentView, setIsNavOpen, setIsSettings, setIsEditMode, width) => {
  if (page === "Edit/Enter") {
    setIsEditMode(true);
  }
  if (page === "Return") {
    setIsSettings(false);
    return;
  }
  if (page === "Settings") {
    setIsSettings(true);
  }
  if (page === "Admin Panel") {
    setCurrentView({ previouse: currentView.current, current: "Users" });
    return;
  }
  if (page !== "Settings") {
    if (width && width < 1023 && page === "Home" && setIsEditMode != null) {
      setIsEditMode(false);
    }
    setCurrentView({ previous: currentView.current, current: page === "Contributors" ? "Reviewer" : page });
  }
};
