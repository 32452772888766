import io from "socket.io-client";

let socket;
const socketHandler = {
  /**
   * Initializes client socket
   */
  initializeSocket: () => {
    socket = io("/");
    console.log("client connected to socket ...");
    socket.on("clearSessions", () => {
      window.location.reload();
    });
  },

  /**
   * Ensures that client is "warmed-up" for sockets
   */
  subscribeToPing: () => {
    socket.on("ping", () => {
      console.log("ready");
    });
  },

  /**
   * Sends the build version to the server
   */
  checkVersion: () => {
    socket.on("request-version", () => {
      const version = require("../version");
      const localVersion = localStorage.getItem("version");
      if (localVersion === null) {
        localStorage.setItem("version", version);
      } else if (parseInt(localVersion, 10) !== version) {
        localStorage.setItem("version", version);
      }
      socket.emit("version", version);
    });
  },

  /**
   * Refreshes the client if versions do not match
   * @param {function} cb Callback Fuction
   */
  reloadClient: (cb) => {
    socket.on("reload", (data) => {
      console.log("server and client versions do not match. Updating ...", data);
      cb();
    });
  },

  /**
   * Sends pushed answer to ListItem
   * @param {Object} data answer data to add to form
   * @returns new socket event "new-entry"
   */
  pushToForm: (data) => {
    socket.emit("new-entry", data);
  },

  /**
   * Listener for "push-entry". Calls back function on success
   * @param {function} cb Callback Fuction
   * @returns cb(err, data)
   */
  subscribeToPushes: (cb) => {
    if (!socket) return cb(true);

    socket.on("push-entry", (data) => {
      return cb(null, data);
    });
  },

  /**
   * Cleanup "push-entry" socket
   * @returns removes listener "push-entry"
   */
  unsubscribeFromPushes: () => socket.off("push-entry"),

  /**
   * Listener for "notification". Calls back function on success
   * @param {function} cb Callback Fuction
   * @returns cb(err, data)
   */
  subscribeToSubmissions: (cb) => {
    if (!socket) return cb(true);

    socket.on("notification", (data) => {
      return cb(null, data);
    });
  },

  /**
   * Cleanup "notification" socket
   * @returns removes listener "notification"
   */
  unsubscribeFromSubmissions: () => socket.off("notification"),

  /**
   * Notifies server of new submission
   * @param {Object} data declaration data
   * @returns new socket event "submission"
   */
  sendSubmission: (data) => socket.emit("submission", data),

  /**
   * Notifies server of update to tooltip
   * @param {Object} data question data
   * @returns new socket event "update-tooltip"
   */
  updateTooltip: (data) => socket.emit("update-tooltip", data),

  /**
   * Listener for "tooltip-alert". Calls back function on success
   * @param {function} cb Callback Fuction
   * @returns cb(err, data)
   */
  subscribeToTooltips: (cb) => {
    if (!socket) return cb(true);

    socket.on("tooltip-alert", (data) => {
      return cb(null, data);
    });
  },

  /**
   * Cleanup "tooltip-alert" socket
   * @returns removes listener "tooltip-alert"
   */
  unsubscribeFromTooltips: () => socket.off("tooltip-alert"),

  /**
   * Reviewer liked a contributors answer
   * @param {Object} data Info containing the answer that was liked
   */
  sendLike: (data) => {
    socket.emit("liked", data);
  },

  /**
   * Sends the answers that has been liked to the contributor
   * @param {*} cb
   * @returns
   */
  test: (cb) => {
    if (!socket) return;

    socket.on("updateRecords", (data) => {
      return cb(null, data);
    });
  },

  checkEntry: (data) => {
    console.log("Connecting set");
    socket.emit("entry", data);

    socket.on("waiting", () => {
      socket.emit("backitgoes", data);
    });
  },

  unsubscribeFromEntry: () => socket.off("checkEntry"),

  subscribeToExpectations: (cb) => {
    if (!socket) return cb(true);

    socket.on("expChange", (data) => {
      return cb(null, data);
    });
  },
  unsubscribeFromExpectations: () => socket.off("expChange"),

  updateExpectation: (type, selected, newResults, assignees, from) => {
    if (!socket) return;
    const data = {
      type,
      prevData: selected,
      newData: newResults,
      assignees,
      sentBy: from,
    };

    socket.emit("expectation-change", data);
  },

  /**
   * Updates the reviewers dicussion that is linked with the contributor
   */
  newComment: (data) => {
    if (!socket) return;

    socket.emit("newComment", data);
  },

  joinDiscussion: (id) => {
    if (!socket) return;

    socket.emit("joinDiscussion", id);
  },

  subscribeToDiscussion: (cb) => {
    if (!socket) return cb(true);

    socket.on("updateDiscussion", (data) => {
      return cb(null, data);
    });
  },
  unsubscribeFromDiscussion: () => socket.off("updateDiscussion"),

  subscribeToActivityFeed: (cb) => {
    if (!socket) return cb(true);

    socket.on("updateMadeToActivityFeed", (data) => {
      return cb(null, data);
    });
  },
  unsubscribeFromActivityFeed: () => socket.off("updateMadeToActivityFeed"),

  /************************************ MESSAGES *************************************/
  joinMessageRoom: (id) => {
    if (!socket) return;

    socket.emit("joinMessageRoom", id);
  },

  newMessage: (data) => {
    if (!socket) return;
    socket.emit("newMessage", data);
  },

  subscribeToMessages: (cb) => {
    if (!socket) return cb(true);
    socket.on("updateMessages", (data) => {
      return cb(null, data);
    });
  },

  unsubscribeFromMessages: () => socket.off("updateMessages"),

  updateReviewed: (id, reviewer) => {
    if (!socket) return;
    socket.emit("reviewed", id, reviewer);
  },

  subscribeToReviewed: (cb) => {
    if (!socket) return cb(true);
    socket.on("updateReviewed", (id) => {
      return cb(null, id);
    });
  },

  autoStep: (userId, id, action) => {
    if (!socket) return;
    socket.emit("autoStep", userId, id, action);
  },

  subscribeToAutoStep: (cb) => {
    if (!socket) return;
    socket.on("updateFeed", (data) => {
      return cb(null, data);
    });
  },
  unsubscribeFromAutoStep: () => socket.off("updateFeed"),

  unsubscribeFromReviewed: () => socket.off("updateReviewed"),

  subscribeToReassignment: (cb) => {
    if (!socket) return;
    socket.on("reassignExpectation", (data) => {
      return cb(null, data);
    });
  },

  unsubscribeFromReassignment: () => socket.off("reassignExpectation"),

  subscribeToExpectationChange: (cb) => {
    if (!socket) return;

    socket.on("expectationChange", (data) => {
      return cb(null, data);
    });
  },

  unsubscribeFromExpectationChange: () => socket.off("expectationChange"),

  /**
   * Socket cleanup if initialization unmounts. Removes all current listeners and disconnects socket
   */
  unmountAll: () => {
    socket.offAny(); // removes any listener
    socket.disconnect();
    console.log("client disconnecting ...");
  },
};

export default socketHandler;
