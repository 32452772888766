export const themes = {
  light: {
    surface: "var(--light-mode-surface)",
    surfaceFontColor: "var(--light-mode-surface-font-color)",
    surfaceHover: "var(--light-mode-surface-hover)",
    surfacePrimaryHover: "var(--light-mode-surface-primary-hover)",
    surfaceSecondaryHover: "var(--light-mode-surface-secondary-hover)",
    surfaceFocused: "var(--light-mode-surface-focused)",
    surfaceFocusedFontColor: "var(--light-mode-surface-focused-font-color)",
    background: "var(--light-mode-background)",
    primary: "var(--light-mode-primary-color)",
    secondary: "var(--light-mode-secondary-color)",
    secondaryFocus: "var(--light-mode-secondary-focus)",
    backgroundFontColor: "var(--light-mode-background-font-color)",
    secondaryFontColor: "var(--light-mode-secondary-font-color)",
    excellentRating: "var(--light-mode-excellent-rating)",
    goodRating: "var(--light-mode-good-rating)",
    goodRatingBackground: "var(--light-mode-good-background)",
    fairRating: "var(--light-mode-fair-rating)",
    badRating: "var(--light-mode-bad-rating)",
    badRatingBackground: "var(--light-mode-bad-rating-background)",
    disabledContainer: "var(--light-mode-disabled-container)",
    disabledContainerHovered: "var(--light-mode-disabled-container-hover)",
    disabledText: "var(--light-mode-disabled-text)",
    error: "var(--light-mode-error)",
    errorFontColor: "var(--light-mode-error-font-color)",
    darkTheme: "var(--light-mode-dark-theme)",
    lightTheme: "var(--light-mode-light-theme)",
    expectationsNoDataFont: "var(--light-mode-expectations-date-no-date-font-color)",
    primaryMessageBG: "var(--light-mode-primary-message-bg)",
    mentionedBackground: "var(--light-mode-mentioned-background)",
    mentionedFontColor: "var(--light-mode-mentioned-font-color)",
    chartBorder: "var(--light-mode-chart-border)",
    swimLaneFontColor: "var(--light-mode-swimLane-font-color)",
    insetBoxShadow: "var(--light-mode-inset-box-shadow)",
    surfaceBorder: "var(--light-mode-surface-border)",
    tableHeaderBackground: "var(--light-mode-table-header-background)",
    tableHeaderFontColor: "var(--light-mode-table-header-font-color)",
    cardBackgroundColor: "var(--light-mode-card-background-color)",
  },
  dark: {
    surface: "var(--dark-mode-surface)",
    surfaceFontColor: "var(--dark-mode-surface-font-color)",
    surfaceHover: "var(--dark-mode-surface-hover)",
    surfacePrimaryHover: "var(--dark-mode-surface-primary-hover)",
    surfaceSecondaryHover: "var(--dark-mode-surface-secondary-hover)",
    surfaceFocusedFontColor: "var(--dark-mode-surface-focused-font-color)",
    surfaceFocused: "var(--dark-mode-surface-focused)",
    background: "var(--dark-mode-background)",
    primary: "var(--dark-mode-primary-color)",
    secondary: "var(--dark-mode-secondary-color)",
    secondaryFocus: "var(--dark-mode-secondary-focus)",
    secondaryVariant: "var(--dark-mode-secondary-variant-color)",
    backgroundFontColor: "var(--dark-mode-background-font-color)",
    secondaryFontColor: "var(--dark-mode-secondary-font-color)",
    excellentRating: "var(--dark-mode-excellent-rating)",
    goodRating: "var(--dark-mode-good-rating)",
    goodRatingBackground: "var(--dark-mode-good-background)",
    fairRating: "var(--dark-mode-fair-rating)",
    badRating: "var(--dark-mode-bad-rating)",
    badRatingBackground: "var(--dark-mode-bad-rating-background)",
    disabledContainer: "var(--dark-mode-disabled-container)",
    disabledContainerHovered: "var(--dark-mode-disabled-container-hover)",
    disabledText: "var(--dark-mode-disabled-text)",
    error: "var(--dark-mode-error)",
    errorFontColor: "var(--dark-mode-error-font-color)",
    darkTheme: "var(--dark-mode-dark-theme)",
    lightTheme: "var(--dark-mode-light-theme)",
    expectationsNoDataFont: "var(--dark-mode-expectations-date-no-date-font-color)",
    primaryMessageBG: "var(--dark-mode-primary-message-bg)",
    mentionedBackground: "var(--dark-mode-mentioned-background)",
    mentionedFontColor: "var(--dark-mode-mentioned-font-color)",
    chartBorder: "var(--dark-mode-chart-border)",
    swimLaneFontColor: "var(--dark-mode-swimLane-font-color)",
    insetBoxShadow: "var(--dark-mode-inset-box-shadow)",
    surfaceBorder: "var(--dark-mode-surface-border)",
    tableHeaderBackground: "var(--dark-mode-table-header-background)",
    tableHeaderFontColor: "var(--dark-mode-table-header-font-color)",
    cardBackgroundColor: "var(--dark-mode-card-background-color)",
  },
};
