/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Tooltip } from "@plexxis/react-ui-kit/lib/Misc";
import API from "../../utils/API";
import moment from "moment";
import Achievement from "./Achievement";
import { playSound, passingDay } from "../../utils/utils";
import { serialize, parseJson, formatText } from "../CustomEditor/utils";
import { Checkbox } from "../../CSS/styled";

// TODO: This component needs a complete refactor..

export default function CompletionMarker(props) {
  const [state, setState] = useState(false);
  const [open, setOpen] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  async function handleSubmit() {
    try {
      // to avoid saving initial values
      if (props.answer === undefined) return;
      else if (state === undefined) return;
      const dateFormat = passingDay(moment().format("YYYY-MM-DD"), props.timezone);

      const oldFormat = props.text?.[0]?.children?.[0]?.type === "expectation" ? props.text?.[0]?.children?.[0]?.id : null;
      const newFormat = props.text?.[0]?.type === "expectation" ? props.text?.[0]?.id : null;

      let newMarker = {
        state: !state,
        id: props.answer,
        date: moment(dateFormat).format("YYYY-MM-DD HH:mm:ss"),
        tz: props.timezone,
        user: props.userId,
        selectedDate: moment(props.date).format("YYYY-MM-DD"),
        autoScoring: props.autoScoring,
        isParent: props.isParent,
        parent: props.data.parent,
        type: props.data.type,
        answer: formatText(props.text),
        expId: oldFormat ?? newFormat,
      };
      let result = await API.checkedChange(newMarker);
      if (result.status === 200) {
        // Update the declaration
        const copy = props.set.type === "Current" ? [...props.set.ansSet[props.question]] : [...props.set.ansSet[props.set.index][props.question]];
        const ids = [...new Set(result.data.declaration.map((dec) => dec.id))];
        copy.forEach((ans, index) => {
          if (ids.includes(ans.answer_id)) {
            copy[index] = {
              ...ans,
              checked: result.data.declaration[ids.indexOf(ans.answer_id)].checked,
              checked_time: result.data.declaration[ids.indexOf(ans.answer_id)].checked_time,
            };
          }
        });
        if (props.set.type === "Current") {
          props.set.ansSet[props.question] = copy;
          setIsCompleted(result.data.rating === "Excellent");
          if (!state && props.special) {
            setOpen(true);
            playSound(result.data.rating === "Excellent");
            setTimeout(() => setOpen(false), 3050);
          }
        } else {
          props.set.ansSet[props.set.index][props.question] = copy;
        }

        props.updateMonthlyRating(moment(copy[0].date).format("YYYY-MM-DD"), result.data.rating);
      }
      if (!state) {
        props.setNotification({
          message: result.data.message,
          open: true,
          severity: result.status === 200 ? "success" : "error",
        });
      }
    } catch (error) {
      props.setNotification({
        message: "This answer can no longer be checked off",
        open: true,
        severity: "error",
      });
    }
  }

  useEffect(() => {
    //* Why?
    setState(props.checked);
  }, [props.checked]);

  return (
    <>
      <Tooltip
        title={state ? props.tooltip : "Not " + props.tooltip}
        anchorSide="center"
        children={
          <Checkbox
            theme={props.theme}
            style={{
              cursor: `${
                !props.reviewer
                  ? props.isFirst
                    ? "pointer"
                    : moment().tz(props?.timezone).diff(moment(props?.date), "days") > 1 || moment().tz(props?.timezone).diff(moment(props?.date), "days") < 0
                    ? "not-allowed"
                    : "pointer"
                  : "not-allowed"
              }`,
              backgroundColor: "#f26143",
            }}
            type="checkbox"
            onChange={() => handleSubmit()}
            disabled={
              !props.reviewer
                ? props.isFirst
                  ? false
                  : moment().tz(props?.timezone).diff(moment(props?.date), "days") > 1 || moment().tz(props?.timezone).diff(moment(props?.date), "days") < 0
                : true
            }
            //* Also Why?
            checked={state}
          />
        }
      />
      {props.special && props.text && state && open && (
        <Achievement open={open} completed={isCompleted}>
          <span dangerouslySetInnerHTML={{ __html: `<div> ${serialize({ children: parseJson(props.text) }, props.theme)}</div>` }} />
        </Achievement>
      )}
    </>
  );
}
