import React, { useState } from "react";
import styled from "styled-components";
import Child from "./Child";
import { FaUser, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { themes } from "../../../../CSS/themes";
import { useReviewerContext } from "../../../../contexts/ReviewerContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.2em;
`;

const Wrapper = styled.div`
  max-height: 10em;
  overflow: auto;

  @media (max-width: 768px) {
    max-height: 5em;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8em;
  cursor: pointer;
  outline: ${(props) => (props.isSelected ? `1px solid ${themes[props.theme].secondary}` : "unset")};
  box-sizing: border-box;
  padding: 0.2em;
  border-radius: 0.3em;
  background-color: ${(props) => (props.isSelected ? themes[props.theme].secondaryFocus : "unset")};
  margin: 0.25em 0;
  justify-content: space-between;

  span:first-child {
    color: ${(props) => (props.hasChildren ? themes[props.theme].primary : "")};
  }

  span:last-child {
    margin-left: 0.25em;
  }

  :hover {
    color: ${(props) => themes[props.theme].surfaceSecondaryHover} !important;
  }
`;

const Parent = ({ contributors, group, icon, theme, isFiltered }) => {
  const [isShowing, setIsShowing] = useState(false); // Flag used to hide/show the associated contributors
  const { handleFilterChange } = useReviewerContext();

  const handleSelected = () => {
    if (!isFiltered) {
      handleFilterChange("Add", group, "grouping");
    } else {
      handleFilterChange("Remove", group);
    }
  };

  const toggle = (e) => {
    e.stopPropagation();
    setIsShowing(!isShowing);
  };

  return (
    <Container>
      <Item isSelected={isFiltered} hasChildren={contributors.filter((user) => user.isFiltered).length > 0} theme={theme} onClick={() => handleSelected()}>
        <div>
          <span>{icon}</span>
          <span>{group}</span>
        </div>
        <div onClick={(e) => toggle(e)}>{isShowing ? <FaChevronUp /> : <FaChevronDown />}</div>
      </Item>

      {isShowing && (
        <Wrapper>
          {contributors.map((contributor) => {
            return (
              <React.Fragment key={contributor.employee_id}>
                <Child contributor={contributor} icon={<FaUser />} theme={theme} handleFilterChange={handleFilterChange} />
              </React.Fragment>
            );
          })}
        </Wrapper>
      )}
    </Container>
  );
};

export default Parent;
