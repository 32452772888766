import React, { useState } from "react";
import styled from "styled-components";
import { FaChevronCircleLeft, FaExclamation } from "react-icons/fa";
import { themes } from "../../CSS/themes";
import IconButton from "@plexxis/react-ui-kit/lib/Buttons/IconButton";
import { getOptions } from "./Utils/options";
import UserContainer from "./Components/UserContainer";
import NavigationItems from "./Components/NavigationItems";

const Nav = styled.div`
  font-family: "Roboto", sans-serif;
  width: ${(props) => (props.isOpen && !props.isSpecial ? "20vw" : props.isSpecial ? "unset" : 0)};
  height: ${(props) => (props.isSpecial && !props.isMobile ? "unset" : "100%")};
  z-index: 10;
  background-color: ${(props) => themes[props.theme].surface};
  padding: unset;
  color: ${(props) => themes[props.theme].surfaceFontColor};
  position: ${(props) => (props.isSpecial ? "relative" : "absolute")};
  top: 0;
  left: 0;
  opacity: 1; // ${(props) => (props.isOpen ? 1 : 0)};
  transition: all 0.25s linear;
  box-shadow: ${(props) => (props.isSpecial ? "rgba(0, 0, 0, 0.16) 0px 1px 4px" : `${themes[props.theme].secondary}1.95px 1.95px 2.6px`)};

  hr {
    background-color: white;
    width: 80%;
  }

  @media (max-width: 1023px) {
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => (props.isOpen ? "fit-content" : 0)};
  }
  @media (min-width: 1024px) {
    flex: 1 0 auto;
    width: ${(props) => (props.isSpecial ? "unset" : "")};
  }
`;

const Footer = styled.div`
  @media (min-width: 320px) {
    display: ${(props) => (props.isOpen && !props.isSpecial ? "block" : "none")};
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 1em;
  }
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => themes[props.theme].surfaceFontColor};
  background-color: ${(props) => themes[props.theme].disabledContainer};
  padding: 0.3em;
  margin: 0 1em;
  font-size: 0.9em;
  border-radius: 0.3em;
`;

const Sidenav = ({
  isNavOpen,
  setIsNavOpen,
  width,
  type,
  user,
  theme,
  setIsEditMode,
  handleReturn,
  handleToggle,
  toggleResponses,
  currentView,
  setCurrentView,
  contributors,
  setNotification,
  isAuthenticated,
}) => {
  const [option, setOption] = useState(null);
  const [message, setMessage] = useState("");

  const closeNavigation = () => {
    setIsNavOpen(false);
    setOption(null);
  };

  return (
    <Nav isOpen={isNavOpen} isSpecial={type === "Reviewer" || type === "Admin"} isMobile={width < 1024} theme={theme}>
      <div style={{ position: "absolute", right: -13, display: isNavOpen ? "block" : "none" }} onClick={() => closeNavigation()}>
        <IconButton
          icon={
            <>
              <FaChevronCircleLeft size={20} style={{ color: themes[theme].primary }} />
            </>
          }
        />
      </div>
      <UserContainer isNavOpen={isNavOpen} theme={theme} user={user} />
      <hr />
      <NavigationItems
        theme={theme}
        isNavOpen={isNavOpen}
        setIsNavOpen={setIsNavOpen}
        width={width}
        type={type}
        user={user}
        option={option}
        setOption={setOption}
        toggleResponses={toggleResponses}
        handleToggle={handleToggle}
        currentView={currentView}
        setCurrentView={setCurrentView}
        setMessage={setMessage}
        setIsEditMode={setIsEditMode}
        isReviewer={user.isReviewer}
        contributors={contributors}
        setNotification={setNotification}
        isAuthenticated={isAuthenticated}
      />
      <Footer isOpen={isNavOpen} isSpecial={type === "Reviewer" || type === "Admin"}>
        {message && (
          <Message theme={theme}>
            <FaExclamation color={themes[theme].badRating} /> {message}
          </Message>
        )}
        <hr />
        {getOptions("footer", theme, width)(handleReturn)}
      </Footer>
    </Nav>
  );
};

export default Sidenav;
