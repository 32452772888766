import moment from "moment-timezone";
import { achievementAudio, rare } from "../media";
import { groupAnswers } from "../views/form/logic/groupAnswers";
import sortArray from "sort-array";
import mp5 from "blueimp-md5";
import { themes } from "../CSS/themes";
moment.tz.setDefault("GMT");

/*
 * @ Compare two dates
 *
 */
export const isSame = (date1, date2) => {
  let firstDate = moment(date1).tz("GMT").format("YYYY-MM-DD");
  let secondDate = moment(date2).tz("GMT").format("YYYY-MM-DD");
  return firstDate === secondDate;
};

/*
 * @ Check if it's the weekend
 *
 */
export const isWeekend = (date) => {
  let dayOfWeek = moment(date).day();
  return dayOfWeek === 0 || dayOfWeek === 6;
};

/*
 *   @ Allow the user to set their self rating within (2) days of current date
 *
 */
export const isRating = (selectedDate, answers) => {
  let resultDay = 0;
  let endOfMonth = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");
  let secondLastDay = moment(endOfMonth).subtract(1, "days").format("DD");
  let startOfMonth = moment().startOf("month").format("DD");
  // Check if a declaration has been submitted for the day
  let hasSubmission = false;
  for (let i = 0; i < answers.length; i++) {
    if (moment(answers[i].date).format("YYYY-MM-DD") === moment(selectedDate).format("YYYY-MM-DD")) {
      hasSubmission = true;
    }
  }
  // It's the first day of the month
  if (moment().format("DD") === startOfMonth) {
    if (selectedDate === moment(endOfMonth).format("DD") && hasSubmission) {
      return true;
    }
    if (moment(selectedDate).format("DD") === secondLastDay && hasSubmission) {
      return true;
    }
  }
  // Return if selected date is greater than current date
  if (moment(selectedDate).format("DD") > moment().format("DD")) {
    return;
  } else {
    if (hasSubmission) {
      resultDay = moment().format("DD") - moment(selectedDate).format("DD");
    }
    // Date selected has no submission, return
    else {
      return;
    }
  }
  return resultDay < 3 && resultDay >= 0;
};

/*
 *   @ Get users self_rating
 *
 */
export const self_rating = (date, answers) => {
  let selectedDate = date;
  let allAnswers = answers;
  let rating = "";
  for (let i = 0; i < allAnswers.length; i++) {
    if (isSame(selectedDate, allAnswers[i].date) && allAnswers[i].warning_level !== undefined) {
      if (allAnswers[i].self_rating === "Excellent") rating = "Excellent";
      if (allAnswers[i].self_rating === "Good") rating = "Good";
      if (allAnswers[i].self_rating === "Fair") rating = "Fair";
      if (allAnswers[i].self_rating === "Bad") rating = "Bad";
    }
  }
  return rating;
};

/*
 * @ Validate empty feilds
 */
export const isEmpty = (input) => {
  return input.trim() === "" || input === undefined;
};

/*
 * @ Validate time input
 */
export const isValidTime = (time) => {
  let timeRegex = /^([0-1]?\d|2[0-3])(:([0-5][0-9]))(?::([0-5][0-9]))?$/;
  return timeRegex.test(time);
};

/*
 * @ Converts HH:mm:ss to milliseconds
 */
export const convertToMilliseconds = (time) => {
  if (time !== null) {
    // Remove the ':' from email time and use this array to grab HH MM SS
    let timeInMilliseconds = time.split(":");
    let result = 0;
    const hours = parseInt(timeInMilliseconds[0]);
    let mins = parseInt(timeInMilliseconds[1]) + hours * 60;
    let seconds = parseInt(timeInMilliseconds[2] !== undefined ? timeInMilliseconds[2] : "00") + mins * 60;
    result = seconds * 1000;
    return result;
  } else {
    return;
  }
};

export const escapeRegExp = (string) => {
  return string.replace(/[''|""]/g, "''");
};

export const undoEscapeRegExp = (string) => {
  return string.replace(/["''"]/g, "");
};

export const removeQuotes = (string) => {
  return string.replace(/[''|""]/g, " ");
};

export const escapeQuotes = (string) => {
  return string.replace(/[']/g, "''");
};

/*
 * @ Validates is user entered a valid email
 *
 * Parans
 * @value - The value you're checking
 * @return - A boolean
 */
export const isEmail = (value) => {
  try {
    let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let result = {
      status: false,
      error: "",
    };
    if (value.trim() === "") {
      result = {
        status: false,
        error: `Email Address cannot be empty`,
      };
      return result;
    } else {
      // Value type is an array
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          if (!emailRegex.test(value[i])) {
            result = {
              status: false,
              error: `${value[i]} is not a valid email address`,
            };
            return result;
          }
        }
        result.status = true;
        return result;
      }
      let validEmail = emailRegex.test(value);
      if (!validEmail) {
        result = {
          status: false,
          error: `${value} is not a valid email address`,
        };
        return result;
      } else {
        result.status = true;
        return result;
      }
    }
  } catch (error) {
    console.error(error);
  }
};
/*
 * @ Sets a previous day checkbox timer in local storage once the user submits
 * @ Params
 * @ name - Name of localStorage variable
 * @ value - Value for the variable
 */
export const setLocalStorage = (name, value, timezone) => {
  if (localStorage.getItem(name) === null) {
    localStorage.setItem(name, JSON.stringify(value));
  } else {
    let data = JSON.parse(localStorage.getItem(name));
    if (moment(data.date).format("YYYY-MM-DD") !== moment().tz(timezone).format("YYYY-MM-DD")) {
      let newData = {
        ...data,
        checked: true,
        date: moment(`${moment().format("YYYY-MM-DD")}T${convertTime(moment().format("HH:mm:ss"), "GMT", timezone)}`).format("YYYY-MM-DD HH:mm:ss"),
      };
      localStorage.setItem("timer", JSON.stringify(newData));
    }
  }
};
/*
 * @ Starts a timer that allows users to change their checkboxes
 * @ Params
 * @ user - Users id
 */
export const startTimer = (user, setSnackbar) => {
  let timer;
  // Add time the user gets to change their checkbox status
  let timeAllowed = JSON.parse(localStorage.getItem("timer"));
  const veryLateTime = convertToMilliseconds(convertTime(user.verylate_time, "GMT", user.timezone));
  const currentTime = convertToMilliseconds(convertTime(moment().format("HH:mm:ss"), "GMT", user.timezone));
  const timeTillClosed = veryLateTime - currentTime;
  // Open snackbar
  if (timeTillClosed > 0 && user.id === timeAllowed.user) {
    let updatedTimer = {
      ...timeAllowed,
      countdown: timeTillClosed,
    };
    localStorage.setItem("timer", JSON.stringify(updatedTimer));
    timer = setTimeout(() => {
      let newTimer = {
        ...timeAllowed,
        checked: false,
      };
      localStorage.setItem("timer", JSON.stringify(newTimer));
      clearTimeout(timer);
      setSnackbar(true);
    }, timeTillClosed); // Time until very late time
  } else {
    if (timer !== undefined) {
      clearTimeout(timer);
    }
    let newTimer = {
      ...timeAllowed,
      checked: false,
    };
    setSnackbar(true);
    localStorage.setItem("timer", JSON.stringify(newTimer));
  }
};

/*
 *  @ Increments the duplicated item
 * @ Params
 * @ setName - Data that is being duplicated
 * @ Return - Data plus incremented value
 */
export const returnInteger = (setName) => {
  let regex = /^.*?\([^\d]*(\d+)[^\d]*\).*$/;
  let toMatch = setName;
  let num = toMatch.match(regex);
  if (num !== null) {
    let incNum = parseInt(num[1], 10) + 1;
    toMatch = toMatch.replace(/\(.\)$/, "(" + incNum + ")");
  } else {
    toMatch = toMatch + "(1)";
  }
  return toMatch;
};

export const getIdFromUrl = (url) => {
  const regexForUser = /id=[a-z0-9-]+/gi;
  const uuidMatch = url.match(/([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})/gi);
  const messageid = url.match(/messageid=([0-9]+)/g);
  let userID = "";
  try {
    // Redirect Link
    if (uuidMatch.length > 0) {
      const id = messageid[0].substring(messageid[0].indexOf("=") + 1);
      localStorage.setItem(
        "redirect",
        JSON.stringify({
          ids: uuidMatch,
          messageid: id,
        })
      );
    }
    const indexOfUser = url.match(regexForUser);
    userID = indexOfUser.toString().substring(3);
  } catch (err) {
    userID = undefined;
  }
  return userID;
};

export const getTokenParam = (url, param) => {
  let token;
  if (param !== undefined) {
    const regexForUser = new RegExp(String.raw`[^${param}=][a-z0-9-]+$`, "gi");
    token = url.match(regexForUser);
  }
  return token[0];
};

export const isAUrl = (text) => {
  const urlRegex =
    // eslint-disable-next-line no-useless-escape
    /^((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)$/;
  return urlRegex.test(text);
};

export const playSound = (completed) => {
  let audio;
  if (completed) {
    audio = new Audio(rare);
  } else {
    audio = new Audio(achievementAudio);
  }

  audio.volume = 0.3;
  audio.addEventListener("canplaythrough", () => {
    audio?.play();
  });

  return () => audio.removeEventListener("canplaythrough");
};

export const convertTime = (time, timezone, secondTZ, format) => {
  const dateFormat = moment().format("YYYY-MM-DD");
  const timeFormat = format !== undefined ? format : "HH:mm:ss";
  return moment.tz(moment.tz(`${dateFormat}T${formatTime(time)}`, timezone), secondTZ).format(timeFormat);
};

/*
 * @ Correctly formats time, if needed
 */
export const formatTime = (time) => {
  // Check if time is formatted correctly
  const timeRegex = /^(?:[01]\d|2[0123]):(?:[012345]\d)(:[012345]\d)?$/;
  let formattedTime;
  if (!time.match(timeRegex) && time.length > 0) {
    formattedTime = time.split("");
    formattedTime.unshift("0");
    formattedTime = formattedTime.join("");

    return formattedTime;
  }
  return time;
};

const checkDay = (currentDay, collection, grouped) => {
  const day = moment(currentDay).day();
  const sorted = sortArray(grouped, {
    by: "order_by",
  });
  collection[day] = groupAnswers(sorted);
};

export const groupByDate = async (arr, offset, tz) => {
  // Array of Arrays, Answers group by date
  const collection = [];
  // Create an array for each day of the week
  for (let i = 0; i < 7; i += 1) {
    collection.push([]);
  }
  let grouped = [];
  let date = arr[0] ? moment(arr[0].date).tz("GMT").format("YYYY-MM-DD") : null;
  // If there are answers
  if (date !== null) {
    for (let i = 0; i < arr.length; i++) {
      // IF DATES ARE EQUAL
      if (moment(arr[i].date).tz("GMT").format("YYYY-MM-DD") === date) {
        grouped.push(arr[i]);
      }
      // DATES ARE NOT EQUAL, PUSH PREV ANSWERS
      if (moment(arr[i].date).tz("GMT").format("YYYY-MM-DD") !== date && grouped.length > 0) {
        checkDay(date, collection, grouped);
        grouped = [];
        grouped.push(arr[i]);
        date = moment(arr[i].date).tz("GMT").format("YYYY-MM-DD");
      }
      // END OF ARRAY, PUSH TO COLLECTION
      if (i === arr.length - 1) {
        checkDay(date, collection, grouped);
      }
    }
    let sorted = collection;
    if (offset === 0) {
      // Remove the current day from the previous answers
      sorted = sorted.splice(0, moment().tz(tz).day());
    }
    sorted.reverse();
    return sorted;
  }
};

/**
 *
 * Determines which component should be rendered
 * @param {Object} state Initial or current state
 * @param {Object} action Param passed in dispatch func.
 * @returns {Object} {value: new state, type: "current" or "previous"}
 */
export const determineComponent = (state, action) => {
  // if date is null that means it's multiple messages
  if (action.type === "clear") return { value: !state.value, type: null };
  else if (action.type === "sendlater" && !action.date) return { value: !state.value };
  else if (moment(action.date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) return { value: !state.value, type: "current" };
  else if (moment(action.date).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) return { value: !state.value, type: "previous" };
  else throw new Error("dispatch failed 😥");
};

/**
 * Checks if item is an integer. Returns a boolean value
 * @param {*} item
 */
export const isInt = (item) => {
  const regex = /^([1-9]\d*|0)$/;

  return regex.test(item);
};

export const lightOrDark = (color) => {
  if (color == null) return;
  // Variables for red, green, blue values
  var r, g, b, hsp;
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "light";
  } else {
    return "dark";
  }
};

export const hexToRgbA = (hex, opacity) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + `, ${opacity})`;
  }
  throw new Error("Bad Hex");
};

/**
 * Checks if UTC time is the next day
 * @param {string} selectedDate Date selected Ex. YYYY-MM-DD
 * @param {string} timezone Users local timezone
 * @returns converted date
 */
export const passingDay = (selectedDate, isCurrent) => {
  const currentDate = moment().format("YYYY-MM-DD");

  let date;
  if (currentDate > selectedDate && isCurrent) {
    date = moment(selectedDate + "T" + moment().format("HH:mm:ss")).add(1, "days");
  } else {
    date = moment(selectedDate + "T" + moment().format("HH:mm:ss"));
  }
  return date;
};

export const getSubmissionColor = (answer, theme, isReviewer) => {
  if (!answer || answer?.length === 0) return;
  let submissionRating;
  if (answer[0]?.warning_level?.toLowerCase()) {
    submissionRating = answer[0]?.warning_level.toLowerCase();
  } else {
    submissionRating = answer?.toLowerCase();
  }
  switch (submissionRating) {
    case "good":
      return isReviewer ? `linear-gradient(to bottom, ${themes[theme].goodRating} 0.8%, ${themes[theme].surface} 5%)` : themes[theme].goodRating;
    case "fair":
      return isReviewer ? `linear-gradient(to bottom, ${themes[theme].fairRating} 0.8%, ${themes[theme].surface} 5%)` : themes[theme].fairRating;
    case "bad":
      return isReviewer ? `linear-gradient(to bottom, ${themes[theme].badRating} 0.8%, ${themes[theme].surface} 5%)` : themes[theme].badRating;
    default:
      return "whitesmoke";
  }
};

export const getAutoScoreColor = (rating, theme, isReviewer, answer) => {
  const autoScore = rating != null ? rating.toLowerCase() : answer?.[0]?.auto_score?.toLowerCase();

  switch (autoScore) {
    case "excellent":
      return isReviewer ? `linear-gradient(to bottom, ${themes[theme].excellentRating} 0.8%, ${themes[theme].surface} 5%)` : themes[theme].excellentRating;
    case "good":
      return isReviewer ? `linear-gradient(to bottom, ${themes[theme].goodRating} 0.8%, ${themes[theme].surface} 5%)` : themes[theme].goodRating;
    case "fair":
      return isReviewer ? `linear-gradient(to bottom, ${themes[theme].fairRating} 0.8%, ${themes[theme].surface} 5%)` : themes[theme].fairRating;
    default:
      return isReviewer ? `linear-gradient(to bottom, ${themes[theme].badRating} 0.8%, ${themes[theme].surface} 5%)` : themes[theme].badRating;
  }
};

/**
 * Generates a hashed value from the users email address
 * @param {string} email
 * @returns Returns a mp5 hash from the email address
 */
export const emailHash = (email) => {
  if (email == null) return;
  const copy = email?.trim()?.toLocaleLowerCase(); // Ensures a consistent and accurate hash

  return mp5(copy);
};

export const utf8_to_b64 = (str) => {
  return window.btoa(unescape(encodeURIComponent(str)));
};

export const b64_to_utf8 = (str) => {
  return decodeURIComponent(escape(window.atob(str)));
};

export const formatDate = (date, type) => {
  if (date == null) return;

  switch (type) {
    case "display":
      return moment(date).format("MMMM D YYYY");
    default:
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }
};

export const vaildateDate = (dateString, withTz) => {
  // Use the Date.parse() method to parse the date string
  let date = Date.parse(dateString);

  // Check if the returned value is a valid date
  if (!isNaN(date)) {
    if (withTz) {
      // Extract the day, month, and year from the date
      let day = new Date(date).getDate();
      let month = new Date(date).getMonth();
      let year = new Date(date).getFullYear();

      // Create a new Date object using the extracted values
      let newDate = new Date(year, month, day);

      // Check if the new Date object has the same timestamp as the original Date object
      if (date === newDate.getTime()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const getTimeDifference = (timestamp) => {
  if (timestamp == null) return;

  return moment().to(timestamp);
};
