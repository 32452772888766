import rg4js from "raygun4js";
import version from "../../version";

const raygun = {
  inititalize: () => {
    rg4js("apiKey", process.env.REACT_APP_RAYGUN_API_KEY);
    rg4js("enableCrashReporting", process.env.NODE_ENV !== "development");
    rg4js("setVersion", `${version}.0.0`);
  },
  connect: (data) => {
    rg4js("setUser", {
      identifier: `${data.name} - ${data.id}`,
      isAnonymouse: false,
    });
  },
  sendError: (error) => {
    rg4js("send", {
      error: new Error(error),
      customData: { ...error },
    });
  },
};

export default raygun;
