import React from "react";
import styled from "styled-components";
import { ImageWrapper } from "../../../CSS/styled";
import { emailHash } from "../../../utils/utils";

const Container = styled.div`
  @media (min-width: 320px) {
    display: ${(props) => (props.isOpen ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    line-height: 1;
    margin-top: 0.5em;
    font-size: 1.2em;
    margin-left: 1.5em;
    margin-right: 1.5em;

    span {
      font-size: 0.5em;
      margin-left: 0.8em;
    }

    div:first-child > div:first-child > img {
      width: 40px !important;
      height: 40px !important;
      margin-right: 0.5em;
    }
  }

  @media (min-width: 1440px) {
    font-size: 1.2em;

    span {
      font-size: 0.55em;
    }

    div:first-child > div:first-child > img {
      width: 50px !important;
      height: 50px !important;
      margin-right: 0.5em;
    }
  }
`;

const UserContainer = ({ isNavOpen, theme, user }) => {
  return (
    <Container isOpen={isNavOpen}>
      <div>
        <div
          style={{
            display: isNavOpen ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ImageWrapper
            theme={theme}
            src={`https://gravatar.com/avatar/${emailHash(user.email)}?d=robohash`}
            alt={`${user.first_name.substring(0, 1)} ${user.last_name.substring(0, 1)}`}
          />
        </div>
      </div>
      <div>
        <div>
          {user.first_name} {user.last_name}
        </div>
        <span>{user.email}</span>
      </div>
    </Container>
  );
};

export default UserContainer;
