import API from "../../../../utils/API";
import socketHandler from "../../../../utils/socket";

export const pushAnswer = async (e, data) => {
  const { userId, answerId, answer, question, questionId, isEditMode, togglePushed, setNotification, hasSubmission } = data;
  e.stopPropagation();

  if (isEditMode) {
    socketHandler.pushToForm({ answer, questionId, question });
  } else if (hasSubmission) {
    try {
      // CHECK if declaration exists
      const results = await API.pushAnswer({ userId, id: answerId });
      if (results.status === 200) {
        togglePushed();
      }
    } catch (error) {
      setNotification({
        message: `Failed to push answer`,
        open: true,
        severity: "error",
      });
    }
  } else {
    setNotification({
      message: "No Declaration found",
      open: true,
      severity: "warning",
    });
  }
};
