import React, { useState, useEffect, useRef } from "react";
import { FaRegThumbsUp, FaThumbsUp } from "react-icons/fa";
import { updateFeedback } from "./likeLogic";
import { Tooltip } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import "./feedback.css";
import { IconButtonWrapper } from "../../CSS/styled";

const Like = (props) => {
  const [isLiked, setIsLiked] = useState(false);
  const [tooltip, setTooltip] = useState("");
  const [flag, setFlag] = useState(true);
  const _isMounted = useRef(true);
  const timeOutArr = [];

  const data = {
    reviewer: props.reviewer,
    userId: props.contributor,
    ansId: props.ansId,
    type: props.type,
    question: props.question,
    offset: props.page - 1,
    name: props.name,
    timezone: props.timezone,
  };

  useEffect(() => {
    if (_isMounted.current) {
      setFlag(true);
      if (props.view === "Reviewer") {
        if (props.answer?.liked) {
          setIsLiked(true);
        }
      } else {
        const liked = props.feedback[props.contributor]?.filter((fb) => parseInt(fb.answer_id, 10) === parseInt(props.ansId, 10));
        setIsLiked(liked.length > 0);
      }
      if (props.managers !== null && props.managers !== undefined) {
        if (props.managers.length > 1) {
          const text = `${props.managers[0]} and ${props.managers[1]} liked your answer`;
          setTooltip(text);
        } else if (props.managers.length === 1) {
          const text = `${props.managers[0]} liked your answer`;
          setTooltip(text);
        }
      }
      if (props.view !== "Reviewer") {
        const stopPulse = setTimeout(() => {
          setFlag(false);
        }, 3500);
        timeOutArr.push({
          timeoutID: stopPulse,
        });
        if (timeOutArr.length > 1) {
          clearInterval(timeOutArr.shift().timeoutID);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.feedback]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
      if (timeOutArr.length > 0) {
        clearInterval(timeOutArr.shift().timeoutID);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tooltip TransitionComponent={Zoom} title={props.view === "Reviewer" ? (!isLiked ? "Like" : "Unlike") : tooltip} arrow>
      <span style={{ position: "relative", display: "flex", flexDirection: "column" }}>
        <IconButtonWrapper
          theme={props.theme}
          clearProps={true}
          onClick={() =>
            props.view === "Reviewer" &&
            updateFeedback(
              data,
              setIsLiked,
              isLiked,
              props.contributor,
              props.answers,
              props.state,
              props.setState,
              props.index,
              props.question,
              props.date,
              props.count
            )
          }
          icon={
            props.view === "Reviewer" ? (
              isLiked ? (
                <>
                  <FaThumbsUp />
                </>
              ) : (
                <>
                  <FaRegThumbsUp />
                </>
              )
            ) : isLiked ? (
              <>
                <FaThumbsUp size={12} className={flag ? "pulse" : ""} />
              </>
            ) : null
          }
        ></IconButtonWrapper>
        {props.count > 1 && props.view !== "Reviewer" ? (
          <span
            style={{
              position: "absolute",
              bottom: "-.2em",
              right: "-0.6em",
              fontSize: ".6em",
              color: "white",
              fontWeight: "bold",
              backgroundColor: "rgba(128, 128, 128, 0.75)",
              borderRadius: "50%",
              width: "15px",
              height: "15px",
              textAlign: "center",
            }}
          >
            {props.count}
          </span>
        ) : null}
      </span>
    </Tooltip>
  );
};

export default Like;
