import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
// import { PublicClientApplication } from "@azure/msal-browser";
// import { MsalProvider } from "@azure/msal-react";
// import { msalConfig } from "./components/AzureAuth/Config/authConfig";
import raygun from "./utils/Raygun/Raygun";

// const msalInstance = new PublicClientApplication(msalConfig);
raygun.inititalize();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider> */}
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
