import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { FaComment, FaArrowUp } from "react-icons/fa";
import { useMessageContext as useMessageContext2 } from "../../../contexts/NewMessageContext";
import { themes } from "../../../CSS/themes";
import { pushAnswer } from "../../Declarations/Previous/Logic/pushLogic";

const IconContainer = styled.div`
  position: ${(props) => (props.isHeader || props.isReviewer ? "unset" : "absolute")};
  top: -1em;
  right: 0;
  border: ${(props) => (!props.isHeader && !props.isReviewer ? `1px solid ${themes[props.theme].chartBorder}` : "unset")};
  padding: ${(props) => (props.isHeader || props.isReviewer ? "0 0.25em" : "0.2em 0.75em")};
  border-radius: 0.3em;
  background: ${(props) => (props.isHeader || props.isReviewer ? "unset" : themes[props.theme].surface)};
  cursor: pointer;
  display: flex;

  @media (min-width: 1920px) {
    top: -1.7em;
  }
`;

const IconWrapper = styled.div`
  position: relative;
  div:first-child {
    color: ${(props) => (props.isHeader && props.isReviewer ? themes[props.theme].disabledContainer : themes[props.theme].secondary)};
    margin-right: ${(props) => (props.isPrevious ? "0.25em" : "")};
  }
`;

const Count = styled.div`
  position: absolute;
  top: 0;
  right: -0.5em;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  line-height: 10px;
  background-color: ${(props) => themes[props.theme].primary};
  color: ${(props) => themes[props.theme].secondaryFontColor};
  text-align: center;
  font-size: 0.45em;
`;

const NotificationIcon = ({ theme, data, isHeader, isReviewer, isAnswer, pushData, isPrevious }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { handleSelected, messages, currentView, setCurrentView } = useMessageContext2();

  const numberOfMessages = useMemo(
    () => messages?.[data?.answerSet]?.filter((a) => a?.mode === data?.mode && a?.answer_id === parseInt(data?.answerId, 10)).length ?? "",
    [data, messages]
  );

  const handleClick = (e) => {
    e.stopPropagation();
    handleSelected(data);
    setIsOpen(!isOpen);
    if (currentView.current !== "Reviewer") {
      setCurrentView({
        previous: currentView.current,
        current: "Messages",
      });
    }
  };

  return (
    <IconContainer theme={theme} isHeader={isHeader} isReviewer={isReviewer}>
      {isAnswer && isPrevious && pushData.ableToPush && (
        <IconWrapper isPrevious={isAnswer && isPrevious} theme={theme} onClick={(e) => pushAnswer(e, pushData)}>
          <div>
            <FaArrowUp />
          </div>
        </IconWrapper>
      )}
      <IconWrapper theme={theme} isHeader={isHeader} isReviewer={isReviewer} isPrevious={isAnswer && isPrevious} onClick={(e) => handleClick(e)}>
        <div>
          <FaComment />
        </div>
        {numberOfMessages > 0 && <Count theme={theme}>{numberOfMessages}</Count>}
      </IconWrapper>
    </IconContainer>
  );
};

export default NotificationIcon;
