import React, { useState } from "react";
import styled from "styled-components";
import CompletionMarker from "../Misc/CompletionMarker";
import Answer from "../Misc/Answer";
import Like from "../Feedback/Like";
import { FiArrowUpCircle, FiArrowRightCircle } from "react-icons/fi";
import IconButton from "@plexxis/react-ui-kit/lib/Buttons/IconButton";
import { useGlobalContext } from "../../contexts/context";
import { useMessageContext as useMessageContext2 } from "../../contexts/NewMessageContext";
import { Tasks } from "../../CSS/styled";
import { themes } from "../../CSS/themes";
import NotificationIcon from "../../Pages/Messages/Components/NotificationIcon";
import moment from "moment-timezone";

const QuestionContainer = styled.div`
  background-color: ${(props) => (props.isHovered && !props.isSelected ? themes[props.theme].surfaceHover : "")};
  @media (min-width: 320px) {
    position: relative;
    padding: 1em;
    margin: 1em;
    font-size: 0.9em;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    border-radius: 0.3em;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 2px 6px 2px;
    display: flex;
    flex-direction: ${(props) => (props.isOpen ? "column" : "row")};
    align-items: ${(props) => (props.isOpen ? "flex-start" : "center")};
  }
  @media (min-width: 1024px) {
    border-left: ${(props) => (props.isSelected ? `4px solid ${themes[props.theme].secondary}` : "unset")};
    box-shadow: ${(props) => (props.isSelected ? "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" : "unset")};
    border-top-right-radius: ${(props) => (props.isSelected ? "0.3em" : "unset")};
    border-bottom-right-radius: ${(props) => (props.isSelected ? "0.3em" : "unset")};
    margin: 0;
    margin-top: 1em;
    margin-right: 0.5em;
    padding: 0.5em 1em;
    height: auto;
  }
`;
const AnswerContainer = styled.div`
  position: relative;
  border-left: ${(props) => (props.isSelected ? `4px solid ${themes[props.theme].secondary}` : "unset")};
  box-shadow: ${(props) => (props.isSelected ? "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" : "unset")};
  border-top-right-radius: ${(props) => (props.isSelected ? "0.3em" : "unset")};
  border-bottom-right-radius: ${(props) => (props.isSelected ? "0.3em" : "unset")};
  background-color: ${(props) => (props.isSelected && props.theme === "dark" ? themes[props.theme].surfaceHover : "")};
  :hover {
    background-color: ${(props) => (!props.isSelected ? themes[props.theme].surfaceHover : "")};
  }

  @media (min-width: 320px) {
    padding: 0.2em 1em;
    margin-left: ${(props) => (props.isChild ? ".5em" : "unset")};
  }
`;
const IconContainer = styled.div`
  border: none;
  @media (min-width: 1024px) {
    display: none;
  }
`;

const QuestionWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

// Todo: Fade away transition on button click && expanding

const Question = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const { answers, feedback, updateAnswerState, updateMonthlyRating, selectedExpectation, theme, notification, setNotification } = useGlobalContext();
  const { conversationSelected } = useMessageContext2();
  return (
    <QuestionContainer
      isOpen={isOpen || window.innerWidth >= 1024}
      isHovered={showIcons}
      isSelected={props.answerSet === conversationSelected.answerSet && conversationSelected.mode === "ans" && props.question === conversationSelected.title}
      theme={theme}
    >
      <QuestionWrapper onMouseEnter={() => setShowIcons(true)} onMouseLeave={() => setShowIcons(false)}>
        {props.question}
        {showIcons && (
          <NotificationIcon
            theme={theme}
            data={{ answerId: answers.currentAns?.[props.question]?.[0].answer_id, answerSet: props.answerSet, mode: "ans", title: props.question }}
          />
        )}
      </QuestionWrapper>

      {!isOpen && (
        <IconContainer onClick={() => setIsOpen(true)}>
          <IconButton icon={<FiArrowRightCircle size={20} fill={themes[theme].secondary} />} />
        </IconContainer>
      )}
      {(isOpen || window.innerWidth >= 1024) && (
        <div style={{ width: "100%" }}>
          {answers.currentAns[props.question].map((ans) => {
            let count = 0;
            let managers = [];
            if (feedback[props.user.id]) {
              feedback[props.user.id].forEach((item) => {
                if (item.answer_id === ans.answer_id) {
                  count++;
                  managers.push(item.reviewer);
                }
              });
            }
            const groupedAnswers = answers.currentAns[props.question].filter((value) => value.parent === ans.parent);
            let isLastChild = false;
            let isOnlyParent = false;
            if (groupedAnswers && groupedAnswers[groupedAnswers.length - 1].answer_id === ans.answer_id) {
              isLastChild = true;
            }
            if (groupedAnswers.length === 1) {
              isOnlyParent = true;
            }
            return (
              <AnswerContainer
                theme={theme}
                isChild={ans.isChild}
                key={ans.answer_id}
                isSelected={parseInt(ans.answer_id, 10) === parseInt(conversationSelected.answerId, 10) && conversationSelected.mode === "row"}
              >
                <div
                  style={{ display: "flex", wordBreak: "break-word" }}
                  draggable={ans.type !== null ? ans.type.toLowerCase() === "milestone" : false}
                  onDragStart={(e) => {
                    if (ans.type !== null && ans.type.toLowerCase() === "milestone") {
                      const data = {
                        value: ans.answer,
                        type: ans.type,
                      };
                      e.dataTransfer.setData("text/plain", JSON.stringify(data));
                    } else {
                      e.dataTransfer.effectAllowed = "none";
                    }
                  }}
                >
                  {ans.checkbox ? (
                    <Tasks
                      isChild={ans.isChild}
                      isParent={ans.parent}
                      isChecked={ans.checked}
                      isLastChild={isLastChild}
                      isOnlyParent={isOnlyParent}
                      theme={theme}
                    >
                      <CompletionMarker
                        answer={ans.answer_id}
                        set={{ ansSet: answers.currentAns, type: "Current" }}
                        tooltip={ans.checkbox_tooltip}
                        question={props.question}
                        selectedDate={props.selectedDate}
                        checked={ans.checked}
                        checkedUpdate={props.checkedUpdate}
                        text={ans.answer}
                        special={props.user.achievements}
                        autoScoring={props.user.autoScoring}
                        timezone={props.user.timezone}
                        userId={props.user.id}
                        date={ans.date}
                        isFirst={props.selectedDate === moment().format("YYYY-MM-DD")}
                        isParent={ans.parent && !ans.isChild}
                        data={ans}
                        updateAnswerState={updateAnswerState}
                        updateMonthlyRating={updateMonthlyRating}
                        theme={theme}
                        notification={notification}
                        setNotification={setNotification}
                      />
                    </Tasks>
                  ) : null}
                  <Answer ans={ans} view="contributor" selectedExpectation={selectedExpectation} theme={theme} />
                  {count > 0 ? (
                    <Like
                      theme={theme}
                      view={"Contributor"}
                      ansId={ans.answer_id}
                      count={count}
                      feedback={feedback}
                      managers={managers}
                      contributor={props.user.id}
                    />
                  ) : null}
                </div>
              </AnswerContainer>
            );
          })}
          <IconContainer style={{ position: "absolute", right: 0 }} onClick={() => setIsOpen(false)}>
            <IconButton icon={<FiArrowUpCircle size={20} fill={themes[theme].secondary} />} />
          </IconContainer>
        </div>
      )}
    </QuestionContainer>
  );
};

export default Question;
