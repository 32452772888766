import React from "react";
import styled from "styled-components";
import { themes } from "../../../../CSS/themes";

const Container = styled.div`
  display: flex;
  border-left: 1px solid white;
  padding: 0 0.5em;
  margin-left: 0.5em;
  font-size: 0.7em;
  align-items: center;
  cursor: pointer;
  color: ${(props) => (props.isSelected ? themes[props.theme].primary : "unset")};

  span {
    margin-left: 0.35em;
  }

  :hover {
    color: ${(props) => (!props.isSelected ? `${themes[props.theme].surfaceSecondaryHover}` : "")};
  }
`;

const Child = ({ contributor, icon, theme, handleFilterChange }) => {
  const handleSelected = () => {
    if (!contributor.isFiltered) {
      handleFilterChange("Add", contributor.email);
    } else {
      handleFilterChange("Remove", contributor.email);
    }
  };

  return (
    <Container theme={theme} isSelected={contributor.isFiltered} onClick={() => handleSelected()}>
      {icon} <span>{`${contributor.first_name} ${contributor.last_name}`}</span>
    </Container>
  );
};

export default Child;
