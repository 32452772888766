import { createPortal } from "react-dom";
import { Row, Col } from "react-bootstrap";
import "../../CSS/achievement.css";

export default function Achievement({ children, open, completed }) {
  return createPortal(
    <>
      {open ? (
        <div id="achievement" className="outer">
          {completed ? (
            <>
              <div style={{ position: "absolute", top: -148, left: -85, minHeight: "200px", minWidth: "300px" }} className="super"></div>
              <div style={{ position: "absolute", top: -50, left: 79, minWidth: "300px" }} className="stars"></div>
            </>
          ) : null}
          <Row>
            <Col className="col-inner col-2">
              <div className="inner"></div>
            </Col>
            <Col className="col-text">
              <div className="text">
                <h6>Achievement Unlocked</h6>
                <p>{children}</p>
              </div>
            </Col>
          </Row>
        </div>
      ) : null}
    </>,
    document.getElementById("root")
  );
}
