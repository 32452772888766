import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "@plexxis/react-ui-kit/lib/Misc";
import { FaLevelDownAlt, FaPlus } from "react-icons/fa";
import moment from "moment-timezone";
import { PaginationWrapper, CardHeader } from "../CSS/styled";
import TimeView from "./form/components/TimeView";
import { useGlobalContext } from "../contexts/context";
import { useMessageContext as useMessageContext2 } from "../contexts/NewMessageContext";
import NotificationIcon from "../Pages/Messages/Components/NotificationIcon";
import styled from "styled-components";
import { getSubmissionColor, getAutoScoreColor } from "../utils/utils";
import PrevAnswerContainer from "./PrevAnswerContainer";
import { themes } from "../CSS/themes";

const Card = styled.div`
  @media (min-width: 320px) {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    margin-bottom: 1em;
    position: relative;
  }
  @media (min-width: 1000px) {
    width: 60vw;
  }
  @media (min-width: 1024px) {
    width: unset;
    box-shadow: unset;
  }
`;

const NoSubmission = styled.div`
  @media (min-width: 320px) {
    display: flex;
    justify-content: center;
    height: 67vh;
    align-items: center;
    font-size: 1.5em;
    padding: 1.6em;
    text-align: center;
  }
  @media (min-width: 1024px) {
    height: unset;
    font-family: "Roboto", sans-serif;
    position: relative;
    justify-content: center;
    align-items: center;
    min-height: 5em;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    background-color: ${(props) => props.submissionRating};
    font-weight: 900;
    font-size: 1em;
  }
`;
const PreviousContainer = styled.div`
  background-color: ${(props) => themes[props.theme].surface};
  display: flex;
  flex-direction: column;
  @media (max-width: 1023px) {
    width: 100vw;
    height: 100%;
    overflow: auto;
  }
  @media (min-width: 1024px) {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    height: unset;
    max-height: 100%;
    overflow: hidden;
    grid-area: 13 / 14 / 21 / 20;
  }
  @media (min-width: 1200px) {
    height: 100%;
    justify-content: space-between;
    flex-shrink: 10;
    min-height: 8em;
  }
`;

const NewTask = styled.div`
  display: ${(props) => (props.isFirst && props.isOpen ? "block" : "none")};
  background-color: ${(props) => themes[props.theme].primary};
  color: ${(props) => themes[props.theme].secondaryFontColor};
  width: 25px;
  height: 25px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  margin-right: 0.5em;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0 0.5em;
  color: ${(props) => themes[props.theme].secondary};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  overflow: auto;
`;

const Footer = styled.div`
  display: flex;
  flex: 0 1 auto;
  justify-content: flex-end;
`;

export default function PrevDec(props) {
  const [page, setPage] = useState(1);
  const [cardOpened, setCardOpened] = useState(-1);
  const [isChanged, setIsChanged] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [set, setAnswerSet] = useState(null);
  const { loading, answers, getNewPrevAns, theme, getDayRating } = useGlobalContext();
  const { getMessages } = useMessageContext2();
  const flatten = [].concat.apply([], answers.prevAns);
  const cardRef = useRef(null);
  const mountRef = useRef(null);

  const handleChange = (event, value) => {
    // If the first pagination is selected, offset is one (Remove currentDate), else offset by limit x pagination index
    if (page !== value) {
      setCardOpened(-1);
      setPage(value);
      // setCurrentPage(value);
      // Fetch new results
      getNewPrevAns(5, value - 1);
    }
  };
  const handleSelected = (index) => {
    if (window.innerWidth >= 1024 && window.innerWidth < 1920) {
      if (cardOpened === index) {
        setCardOpened(-1);
      } else {
        setCardOpened(index);
      }
    }
  };

  const noSubmission = (index) => {
    if (flatten == null) return;
    let date;

    if (flatten.length > 0 && page > 1) {
      const firstRecord = [].concat.apply([], Object.values(flatten?.[0]));
      date = moment(firstRecord[0].date).endOf("week").subtract(index, "days").format("YYYY-MM-DD");
    } else {
      date = moment()
        .subtract(index + 1, "days")
        .format("YYYY-MM-DD");
    }

    const isWeekend = moment(date).day() === 0 || moment(date).day() === 6;

    if (isWeekend) return;

    return (
      <NoSubmission theme={theme}>
        <span>
          {`No Submission Found ${date}`}
          <span style={{ display: "block", fontSize: ".5em", textAlign: "center", color: "gray" }}>
            Scoll to see the previous declarations for this week {<FaLevelDownAlt />}
          </span>
        </span>
      </NoSubmission>
    );
  };

  useEffect(() => {
    if (answers.lastRecord) {
      const start = moment(answers.lastRecord).startOf("week");
      const end = moment().startOf("week");
      setPageCount(Math.abs(start.diff(end, "weeks")) + 1);
    }
  }, [answers.lastRecord]);

  useEffect(() => {
    if (mountRef.current) {
      if (flatten.length === 0 && pageCount > 1) {
        setPage(2);
        // setCurrentPage(2);
        setIsChanged(true);
        getNewPrevAns(5, 1);
      }
    }
    return () => (mountRef.current = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount]);

  useEffect(() => {
    if (cardOpened >= 0) {
      setAnswerSet([].concat.apply([], Object.values(answers.prevAns[cardOpened]))[0].answer_set);
      getMessages(props.user.id, [].concat.apply([], Object.values(answers.prevAns[cardOpened]))[0].answer_set);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardOpened]);

  return (
    <PreviousContainer isEditMode={props.isEditMode} theme={theme}>
      <Content ref={cardRef}>
        {loading ? (
          <div>
            <Spinner top={0} left={0} opacity={0} />
          </div>
        ) : answers.prevAns !== undefined && answers.prevAns.length >= 1 ? (
          answers.prevAns.map((answerSet, index) => {
            const values = Object.values(answerSet);
            return (
              <Card key={`${values[0]?.answer_set}_${index}`}>
                {values.length >= 1 ? (
                  <>
                    <CardHeader
                      isOpen={index === cardOpened}
                      submissionRating={
                        props.user?.autoScoring
                          ? getAutoScoreColor(getDayRating(moment(values[0][0].date).format("YYYY-MM-DD")), theme, false, values[0])
                          : getSubmissionColor(values[0], theme)
                      }
                      onClick={() => handleSelected(index)}
                    >
                      <TimeView data={values[0]} timezone={props.user.timezone} />
                      <IconContainer theme={theme}>
                        <NotificationIcon
                          theme={theme}
                          data={{
                            answerId: values?.[0]?.[0]?.answer_id,
                            answerSet: values?.[0]?.[0]?.answer_set,
                            mode: "dec",
                            title: "Declaration",
                          }}
                          isHeader
                        />
                      </IconContainer>
                    </CardHeader>
                  </>
                ) : (
                  <>{noSubmission(index)}</>
                )}

                <div style={{ position: "relative" }}>
                  {!props.isEditMode && (
                    <NewTask
                      theme={theme}
                      isOpen={index === cardOpened}
                      isFirst={index === 0 && (page === 1 || (isChanged && page === 2))}
                      onClick={() => {
                        props.setSelected({
                          type: "previous",
                          date: moment(values[0][0].average).format("YYYY-MM-DD"),
                        });
                        props.setIsEditMode(true);
                      }}
                    >
                      <FaPlus />
                    </NewTask>
                  )}
                  {Object.keys(answerSet).map((question, i) => {
                    return (
                      <div key={"PrevDec: Question" + i}>
                        <PrevAnswerContainer
                          innerArray={values[i]}
                          question={question}
                          i={i}
                          index={index}
                          page={page}
                          isChanged={isChanged}
                          cardOpened={cardOpened}
                          setCardOpened={setCardOpened}
                          answerSet={set}
                          {...props}
                        />
                      </div>
                    );
                  })}
                </div>
              </Card>
            );
          })
        ) : (
          <Card>
            <NoSubmission style={{ height: "35vh" }}>
              <span>No Data Found For This Week</span>
            </NoSubmission>
          </Card>
        )}
      </Content>
      <Footer>
        {!loading && (
          <PaginationWrapper
            theme={theme}
            count={pageCount !== 0 && !isNaN(pageCount) ? pageCount : 1}
            size="small"
            page={page}
            disabled={answers.count === 0}
            onChange={handleChange}
          ></PaginationWrapper>
        )}
      </Footer>
    </PreviousContainer>
  );
}
