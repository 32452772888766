import React, { useState, useEffect, useContext, useMemo } from "react";
import API from "../utils/API";

const AdminContext = React.createContext();

const AdminProvider = ({ user, width, theme, isNavOpen, currentView, setCurrentView, setIsNavOpen, setNotification, children }) => {
  const [users, setUsers] = useState(null);
  const [schedules, setSchedules] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [originalCopy, setOriginalCopy] = useState(null);
  const [crumbTrail, setCrumbTrail] = useState([]);

  // ********************************** USERS ********************************** //

  const getUsers = async () => {
    if (users != null) return;

    try {
      const results = await API.getUsers();
      if (results.status === 200) {
        setUsers(results.data);
      }
    } catch (error) {
      setNotification({
        message: "Failed to get users",
        open: true,
        severity: "error",
      });
    }
  };

  // ********************************** SCHEDULES ********************************** //

  const getSchedules = async () => {
    if (schedules != null) return;

    try {
      const results = await API.getSchedules();
      if (results.status === 200) {
        setSchedules(results.data);
      }
    } catch (error) {
      setNotification({
        message: "Failed to get schedules",
        open: true,
        severity: "error",
      });
    }
  };

  const scheduleOptions = useMemo(() => {
    return schedules?.map((schedule) => {
      return {
        label: schedule.name,
        value: schedule.id,
      };
    });
  }, [schedules]);

  // ********************************** QUESTIONS ********************************** //

  /**
   * Fetches Question Sets, Questions, and Question Types basic display information
   */
  const getQuestionData = async () => {
    if (questions != null) return;

    try {
      const results = await API.getQuestionRelatedData();
      if (results.status === 200) {
        setQuestions(results.data);
      }
    } catch (error) {
      setNotification({
        message: "Failed to get Questions",
        open: true,
        severity: "error",
      });
    }
  };

  const questionTypeOptions = useMemo(() => {
    return questions?.types.map((type) => {
      return {
        label: type?.name,
        value: type?.id,
      };
    });
  }, [questions?.types]);

  const questionSetOptions = useMemo(() => {
    return questions?.sets.map((type) => {
      return {
        label: type?.name,
        value: type?.id,
      };
    });
  }, [questions?.sets]);

  // ********************************** MISC ********************************** //

  const handleCrumbTrail = (crumb, position) => {
    if (crumb == null) return setCrumbTrail([crumbTrail?.[0]]);
    // Handle crumbs..
    if (position != null) {
      setCrumbTrail(crumbTrail?.slice(0, position !== 0 ? position : 1));
    } else {
      setCrumbTrail((previousState) => [...previousState, crumb]);
    }
  };

  useEffect(() => {
    getSchedules();

    setCurrentView({ previous: currentView?.current, current: "Users" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentView?.current == null) return;
    setCrumbTrail([currentView.current]);
    // setCrumbTrail(currentView.current);
  }, [currentView]);

  return (
    <AdminContext.Provider
      value={{
        crumbTrail,
        currentView,
        getQuestionData,
        getUsers,
        handleCrumbTrail,
        isNavOpen,
        theme,
        originalCopy,
        questions,
        questionTypeOptions,
        questionSetOptions,
        schedules,
        scheduleOptions,
        setCurrentView,
        setIsNavOpen,
        setNotification,
        setSchedules,
        setOriginalCopy,
        setQuestions,
        setUsers,
        user,
        users,
        width,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdminContext = () => {
  return useContext(AdminContext);
};

export { AdminContext, AdminProvider };
