import React, { useState } from "react";
import moment from "moment-timezone";
import { Tooltip } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import { serialize, parseJson } from "../CustomEditor/utils";
import styled from "styled-components";
import { themes } from "../../CSS/themes";
import Like from "../Feedback/Like";
import NotificationIcon from "../../Pages/Messages/Components/NotificationIcon";
import { useMessageContext } from "../../contexts/NewMessageContext";

const AnswerContainer = styled.div`
  position: relative;
  width: 100%;

  -webkit-user-select: ${(props) => (props.isPrevious ? "none" : "unset")}; /* Safari */
  -ms-user-select: ${(props) => (props.isPrevious ? "none" : "unset")}; /* IE 10 and IE 11 */
  user-select: ${(props) => (props.isPrevious ? "none" : "unset")}; /* Standard syntax */

  @media (min-width: 320px) {
    font-size: 0.8em;
    cursor: pointer;
    margin: 0 0 0.2em 0.5em;
    color: ${(props) => (props.isExpectation ? themes[props.theme].secondary : "unset")};
    p {
      margin: 0 !important;
    }
  }

  @media (min-width: 1920px) {
    width: ${(props) => (props.isPrevious ? "unset" : "")};
    min-width: ${(props) => (props.isPrevious ? "7.5em" : "")};
  }

  :hover {
    background: ${(props) => (props.isPrevious ? themes[props.theme].surfaceHover : "")};
  }
`;

const IconWrapper = styled.span`
  position: absolute;
  top: -1em;
  right: 0;
  display: inline-flex;
  align-items: center;
  padding: 0.2em 0.3em;
  border-radius: 0.3em;
  border: ${(props) => (!props.isHeader && !props.isReviewer ? `1px solid ${themes[props.theme].chartBorder}` : "unset")};
  background: ${(props) => (props.isHeader || props.isReviewer ? "unset" : themes[props.theme].surface)};
  cursor: pointer;
`;

const Answer = (props) => {
  const [showIcons, setShowIcons] = useState(false);
  // Array of question sets that allow for expectation links when dragged
  const linkTypes = ["MILESTONE", "PRIORITY"];
  const { messages } = useMessageContext();

  const hasMessages = messages?.[props?.ans?.answer_set]?.find(
    (message) => message?.mode === "row" && message?.answer_id === parseInt(props?.ans?.answer_id, 10)
  );

  const handleClick = () => {
    if (props.view === "contributor") {
      if (linkTypes.includes(props.ans.type) && props.ans.answer[0] != null && props.ans.answer[0]?.type === "expectation") {
        props.selectedExpectation(props.ans.answer[0]?.id, true);
      }
    }
  };
  return (
    <>
      <Tooltip
        TransitionComponent={Zoom}
        title={moment(
          moment(props.ans.date).format("YYYY-MM-DD") <= moment(props.ans.date_submitted).format("YYYY-MM-DD") ? props.ans.date : props.ans.date_submitted
        ).format("YYYY-MM-DD HH:mm:ss")}
        arrow
        enterNextDelay={1000}
      >
        <AnswerContainer
          theme={props.theme}
          onClick={() => handleClick()}
          isExpectation={
            linkTypes.includes(props.ans.type) && props.ans.answer[1] !== undefined && props.ans.answer[1].children
              ? props.ans.answer[1].children[0].type === "expectation"
              : false
          }
          isReviewer={props.view === "reviewer"}
          onMouseEnter={() => setShowIcons(true)}
          onMouseLeave={() => setShowIcons(false)}
          isPrevious={props.isPrevious}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: `<div class=${props.view.toLowerCase() === "reviewer" ? "reviewerAnswers" : ""}> ${serialize(
                {
                  children: parseJson(props.ans.answer),
                },
                props.theme
              )}</div>`,
            }}
          />
          {props.view === "reviewer" && (showIcons || hasMessages != null) ? (
            <IconWrapper theme={props.theme}>
              <NotificationIcon
                theme={props.theme}
                data={{ answerId: props.ans.answer_id, answerSet: props.ans.answer_set, mode: "row", title: props.ans.answer }}
                isReviewer
              />
              {props.view === "reviewer" &&
                (props.contributor?.canMessage === undefined || props.contributor?.canMessage || props.contributor.isAdmin || props.contributor.isPrimary) && (
                  <Like
                    view={"Reviewer"}
                    type={"answer"}
                    ansId={props.ans.answer_id}
                    question={props.ans.question}
                    contributor={props.contributor?.employee_id}
                    feedback={props.reviewer.feedback}
                    reviewer={props.reviewer.id}
                    name={props.reviewer.name}
                    answers={props.answers}
                    answer={props.ans}
                    state={props.state}
                    setState={props.setState}
                    date={props.ans.date}
                    timezone={props.contributor.timezone}
                    theme={props.theme}
                    index={props.index}
                  />
                )}
            </IconWrapper>
          ) : (
            <>
              {showIcons && (
                <NotificationIcon
                  theme={props.theme}
                  data={{ answerId: props.ans.answer_id, answerSet: props.ans.answer_set, mode: "row", title: props.ans.answer }}
                  isAnswer
                  pushData={props.pushData}
                  isPrevious={props.isPrevious}
                />
              )}
            </>
          )}
        </AnswerContainer>
      </Tooltip>
    </>
  );
};

export default Answer;
