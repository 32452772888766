import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getOptions } from "../Utils/options";
// import { SignInButton } from "../../../components/AzureAuth/SignInButton";
// import { SignOutButton } from "../../../components/AzureAuth/SignOutButton";
import { FaSignInAlt, FaSignOutAlt, FaArrowAltCircleLeft } from "react-icons/fa";
import { themes } from "../../../CSS/themes";
import { CustomSwitch, ThemeSwitch } from "../../../CSS/styled";

// Utils
import { clearSessions, handleSignInOptions, handleClick } from "../Utils/navigationUtils";
import { gapi } from "gapi-script";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1.5em;
`;
const ViewCard = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: ${(props) => (props.hasChildren ? "column" : "row")};
  cursor: pointer;

  :not(:first-child) {
    margin-top: 0.5em;
  }

  @media (min-width: 320px) {
    font-size: 1em;
  }
  @media (min-width: 1024px) {
    font-size: 0.8em;
  }
  @media (min-width: 1440px) {
    font-size: 1.2em;
  }
`;

const NavigationItems = ({
  theme,
  isNavOpen,
  setIsNavOpen,
  width,
  type,
  user,
  option,
  setOption,
  toggleResponses,
  handleToggle,
  currentView,
  setCurrentView,
  setMessage,
  setIsEditMode,
  isReviewer,
  contributors,
  setNotification,
  isAuthenticated,
}) => {
  const [isSettings, setIsSettings] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      {!isSettings && (
        <>
          {getOptions("pages", theme, width)
            .filter((pge) => (type === "Admin" ? pge.type === type || pge.type === "Home" : pge.type === type || pge.type === "Home" || pge.type == null))
            .map((page, i) => {
              if ((width >= 1024 && !getOptions("excludedOptions", theme, width).includes(page.name)) || width < 1024) {
                if (page.type == null || type === page.type || page.type === "Home") {
                  if (page.name === "Contributors" && !isReviewer) {
                    return <React.Fragment key={`${page.name}-${i}`}></React.Fragment>;
                  }
                  return (
                    <React.Fragment key={`${page.name}-${i}`}>
                      <ViewCard
                        isOpen={isNavOpen}
                        hasChildren={!!page?.children?.length > 0}
                        onClick={() => handleClick(page.name, currentView, setCurrentView, setIsNavOpen, setIsSettings, setIsEditMode, width)}
                      >
                        <Link to={page.to} style={{ textDecoration: "none", color: themes[theme].surfaceFontColor, width: "100%" }}>
                          {page.icon} {page.name}
                        </Link>
                      </ViewCard>
                      {page.name === "Contributors" && contributors
                        ? Object.entries(contributors).map(([key, values], index) => {
                            return <React.Fragment key={`Navigation Group: ${key}`}>{values}</React.Fragment>;
                          })
                        : null}
                    </React.Fragment>
                  );
                } else {
                  return <React.Fragment key={page.name}></React.Fragment>;
                }
              }
              return null;
            })}
        </>
      )}

      {isSettings && (
        <>
          {getOptions("settings", theme, width).map((option) => {
            if (option.to === "/admin" && !user.isAdmin) {
              return <React.Fragment key={option.name}></React.Fragment>;
            }
            return (
              <ViewCard key={option.name} isOpen={isNavOpen}>
                <Link
                  to={option.to}
                  style={{ textDecoration: "none", color: themes[theme].surfaceFontColor, width: "100%" }}
                  onClick={() => {
                    option.name === "Clear Sessions"
                      ? clearSessions(user?.id, setMessage)
                      : handleClick(option.name, currentView, setCurrentView, setIsNavOpen, setIsSettings, setIsEditMode);
                  }}
                >
                  {option.icon} {option.name}
                </Link>
              </ViewCard>
            );
          })}
        </>
      )}
      {isSettings && (
        <ViewCard
          isOpen={isNavOpen}
          style={{ alignItems: "center" }}
          onClick={() => handleSignInOptions(isAuthenticated, isOpen, setIsOpen, option, setOption)}
        >
          {isAuthenticated ? (
            <FaSignOutAlt style={{ marginRight: ".5em", color: themes[theme].secondary }} />
          ) : (
            <FaSignInAlt style={{ marginRight: ".5em", color: themes[theme].secondary }} />
          )}
          {isAuthenticated ? "Sign Out Of Gmail" : "Sign In To Gmail"}
        </ViewCard>
      )}
      {/* {option === "signin" && <SignInButton option={option} setOption={setOption} setNotification={setNotification} />}
      {option === "signout" && <SignOutButton option={option} setOption={setOption} setNotification={setNotification} />} */}
      {isSettings && (
        <>
          {getOptions("toggleOptions", theme, width).map((option) => {
            return (
              <ViewCard key={option.name} isOpen={isNavOpen} style={{ justifyContent: "space-between", alignItems: "center" }}>
                <div>{option.name}</div>
                <div>
                  {option.name !== "Theme" ? (
                    <CustomSwitch
                      checked={user[option.key] || toggleResponses}
                      disabled={option.key === "selfRating"}
                      onClick={() => handleToggle(option.key)}
                      inputProps={{ theme }}
                    />
                  ) : (
                    <ThemeSwitch checked={theme === "dark"} onClick={() => handleToggle(option.key)} inputProps={{ theme }} />
                  )}
                </div>
              </ViewCard>
            );
          })}
        </>
      )}
      {isSettings && (
        <ViewCard
          isOpen={isNavOpen}
          style={{ alignItems: "center" }}
          onClick={() => handleClick("Return", currentView, setCurrentView, setIsNavOpen, setIsSettings, setIsEditMode)}
        >
          <FaArrowAltCircleLeft style={{ color: themes[theme].secondary, marginRight: ".5em" }} /> Return
        </ViewCard>
      )}
    </Container>
  );
};

export default NavigationItems;
