import styled from "styled-components";
import Modal from "@plexxis/react-ui-kit/lib/Misc/Modal";
import IconButton from "@plexxis/react-ui-kit/lib/Buttons/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import Switch from "@mui/material/Switch";
import ColorPicker from "@plexxis/react-ui-kit/lib/Inputs/ColorPicker";
import { themes } from "./themes";

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 320px) {
    font-size: 0.8em;
  }
`;

export const FakeInput = styled.div`
  background-color: ${(props) => themes[props.theme].surface};
  color: ${(props) => themes[props.theme].surfaceFontColor};
  box-shadow: ${(props) => themes[props.theme]?.surfaceFontColor} 0px 1px 4px !important;
  opacity: 0.85;
  max-width: 90%;
  margin: 3%;
  padding: 5%;
  word-break: break-word;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  margin-top: 3%;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 0 5%;
  padding-top: 2%;
  border-radius: 3px;
`;

export const Popover = styled.div`
  text-align: left;
  max-width: 40vw;
  padding: 0.2em 1em;
  overflow-x: auto;
  overflow-y: auto;
`;

export const PaginationWrapper = styled(Pagination)`
  .MuiPaginationItem-root {
    color: ${(props) => themes[props.theme].secondary};
  }
  .MuiPaginationItem-page.Mui-selected {
    background-color: ${(props) => themes[props.theme].surfaceSecondaryHover} !important;
  }
  display: flex;
  justify-content: flex-end;

  @media (min-width: 320px) {
    border-radius: 0.3em;

    li > button {
      font-size: 0.7em;
      height: 16px;
      min-height: 16px;
      min-width: 16px;
    }
  }
  @media (min-width: 1024px) {
    li > button {
      font-size: unset;
      height: 32px;
      min-height: 32px;
      min-width: 32px;
    }
  }
  @media (min-width: 1440px) {
    margin: 0 1em 0.5em 0;
  }
  @media (min-width: 1920px) {
    position: relative;
    bottom: unset;
    right: unset;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  font-family: "Roboto", sans-serif;
  position: relative;
  cursor: pointer;
  @media (min-width: 320px) {
    justify-content: center;
    align-items: center;
    min-height: 5em;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    border-top: ${(props) => (props.submissionRating ? `1em solid ${props.submissionRating}` : "unset")};
    font-weight: 900;
    border-bottom: 1px solid gray;
    border-bottom-left-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
  }
  @media (min-width: 1024px) {
    border-bottom: ${(props) => (props.isOpen ? "1px solid gray" : "unset")};
  }
  @media (min-width: 1920px) {
    border-bottom: 1px solid gray;
  }
`;
export const Card = styled.div`
  background-color: ${(props) => themes[props.theme].surface};
  color: ${(props) => themes[props.theme].surfaceFontColor};

  @media (min-width: 320px) {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    overflow-y: auto;
  }
  @media (min-width: 768px) {
    height: 80vh;
  }
  @media (min-width: 1000px) {
    width: 60vw;
  }
  @media (min-width: 1024px) {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    position: relative;
    height: unset;
    width: unset;
    grid-area: 1 / 1 / 21 / 14;
    overflow-y: unset;
  }
  @media (min-width: 1200px) {
    overflow: auto;
    margin-bottom: -1.5em;
    grid-area: unset;
  }
`;
export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 320px) {
    min-height: 60vh;
    min-width: 90vw;
    max-width: 90vw;
    justify-content: ${(props) => (props.isAnswers ? "flex-start" : "center")};
  }
  @media (min-width: 1000px) {
    position: relative;
    min-width: unset;
  }
  @media (min-width: 1024px) {
    overflow-y: auto;
    overflow-x: hidden;
  }
  @media (min-width: 1200px) {
    min-height: unset;
    min-width: unset;
    max-width: unset;
    height: 100%;
  }
`;
export const NoData = styled.div`
  @media (min-width: 320px) {
  }
`;
export const MyModal = styled(Modal)`
  background-color: ${(props) => (props.theme ? themes[props.theme]?.surface : "")} !important;
  color: ${(props) => themes[props.theme]?.surfaceFontColor};
  border: unset !important;
  box-shadow: ${(props) => themes[props.theme]?.secondary} 0px 3px 8px !important;
  box-sizing: border-box;
  min-width: 20vw;
  height: ${(props) => (props.height ? props.height : "")};
  @media (min-width: 320px) {
    max-height: ${(props) => (props.isCustom ? "90vh" : "90vh")};
    width: ${(props) => (props.isCustom ? "90vw" : "80vw")};
    overflow: ${(props) => (props.allowOverflow ? "visable" : "auto")} !important;
    h1 {
      font-size: 0.9em;
      font-weight: 600;
    }
  }
  @media (min-width: 500px) {
    width: 50vw;
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 1440px) {
    width: 30vw;
  }
`;
export const TableContainer = styled.div`
  @media (min-width: 320px) {
    width: 100%;
    h1 {
      font-size: 1em;
    }
  }
`;
export const TableWrapper = styled.div`
  .-container {
    background-color: ${(props) => themes[props.theme].surface};
    color: ${(props) => themes[props.theme].surfaceFontColor};
  }
  .rt-head {
    background-color: ${(props) => themes[props.theme].surface};
    color: ${(props) => themes[props.theme].surfaceFontColor};
    opacity: 0.7;
  }
  .rt-th {
    :hover {
      background-color: ${(props) => themes[props.theme].surfaceHover};
    }
  }
  .rt-tr {
    border-bottom: 0.5px solid ${(props) => themes[props.theme].disabledText};
    :hover {
      background-color: ${(props) => themes[props.theme].surfaceHover};
    }
  }
  .rt-td {
    border-left: 0.5px solid ${(props) => themes[props.theme].disabledText};
    border-right: 0.5px solid ${(props) => themes[props.theme].disabledText};

    :hover {
      background-color: ${(props) => themes[props.theme].surfaceHover};
    }
  }
  .-highlighted {
    background: ${(props) => themes[props.theme].surfaceFocused} !important;
    color: ${(props) => themes[props.theme].primary};
  }

  @media (min-width: 320px) {
    height: 80vh;
  }
`;
/**
 * Displays tree like structure for parent/child declaration items
 */
export const Tasks = styled.div`
  margin-left: ${(props) => (props.isChild ? "0.5em" : "unset")};
  &:before {
    position: absolute;
    left: ${(props) => (props.isChild ? ".85em" : "1.35em")};
    top: ${(props) => (!props.isChild && props.isParent ? "1.2em" : "-0.109em")};
    background: ${(props) => (props.isChecked ? themes[props.theme].secondary : themes[props.theme].surfaceFontColor)};
    content: "";
    width: 0.2em;
    height: ${(props) => (props.isLastChild || (props.isParent && !props.isChild) ? ".989em" : "100%")};
    display: ${(props) => (props.isChild || (props.isParent && !props.isOnlyParent) ? "block" : "none")};
  }
  :after {
    position: absolute;
    left: 0.93em;
    top: 0.68em;
    height: 0.2em;
    background: ${(props) => (props.isChecked ? themes[props.theme].secondary : themes[props.theme].surfaceFontColor)};
    content: "";
    width: 0.6em;
    display: ${(props) => (props.isChild ? "block" : "none")};
  }
`;

export const IconButtonWrapper = styled(IconButton)`
  color: ${(props) =>
    props.isDefault
      ? themes[props.theme].surfaceFontColor
      : props.isPrimary || props.isReviewerMode
      ? themes[props.theme].primary
      : themes[props.theme].secondary};
  padding: ${(props) => (props.clearProps ? 0 : "")};
  height: ${(props) => (props.clearProps ? "unset" : "")};
  margin-left: ${(props) => (props.clearProps ? ".5em" : "")};
  box-sizing: border-box;
`;

export const DefaultButton = styled.button`
  background-color: ${(props) =>
    props.disabled ? themes[props.theme].disabledContainer : props.isPrimary ? themes[props.theme].primary : themes[props.theme].secondary};
  color: ${(props) => (props.disabled ? themes[props.theme].disabledText : themes[props.theme].secondaryFontColor)};
  font-family: "Roboto", sans-serif;
  margin-left: 0.3em;
  position: relative;
  overflow: hidden;
  transition: background 1s;
  outline: 0;
  border: 0;
  border-radius: 0.25rem;

  :focus {
    border: 1px solid ${(props) => themes[props.theme].surfaceFontColor};
  }
`;
export const CancelButton = styled(DefaultButton)`
  background-color: ${(props) => (props.isDisabled ? themes[props.theme].disabledContainer : "transparent")};
  color: ${(props) => (props.isDisabled ? themes[props.theme].disabledText : themes[props.theme].secondary)};

  :focus {
    border: 2px solid ${(props) => themes[props.theme].secondary};
  }
`;

export const ButtonOverlay = styled.span`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  :hover {
    background-color: ${(props) =>
      props.disabled
        ? themes[props.theme].disabledContainerHovered
        : props.isPrimary
        ? themes[props.theme].surfacePrimaryHover
        : themes[props.theme].surfaceSecondaryHover};
    transition: opacity 0.5s linear;
    opacity: 0.3;
  }
`;

export const Checkbox = styled.input`
  :checked {
    accent-color: ${(props) => themes[props.theme].secondary};
  }
  input[type="checkbox"] {
    color: blue;
  }
`;

export const ImageWrapper = styled.img`
  box-shadow: ${(props) => themes[props.theme].secondary} 0px 1px 5px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
`;

export const CustomTextInput = styled.input`
  display: flex;
  color-scheme: ${(props) => props.theme};
  background-color: ${(props) => (props.disabled ? themes[props.theme].disabledContainer : "unset")};
  color: ${(props) => (props.disabled ? themes[props.theme].disabledText : themes[props.theme].surfaceFontColor)};
  border: none !important;
  border-bottom: ${(props) => (props.isText ? `2px solid ${themes[props.theme].surfaceFontColor} !important` : "unset")};
  width: 100%;

  :hover {
    border-bottom: ${(props) => (props.isText ? `2px solid ${themes[props.theme].disabledText} !important` : "unset")};
  }
  :focus {
    outline: none !important;
    border-bottom: ${(props) => (props.isText ? `2px solid ${themes[props.theme].primary} !important` : "unset")};
  }
`;

export const CustomTextArea = styled.textarea`
  background: ${(props) => themes[props.theme].surface};
  color: ${(props) => themes[props.theme].surfaceFontColor};
  min-height: 5em;
  max-height: 25vh;
  font-size: 0.8em;
`;

export const CustomSwitch = styled(Switch)((props) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: `${themes[props.inputProps.theme].secondary}`,
    zIndex: 5,
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: `${themes[props.inputProps.theme].secondary}`,
  },
  "& .MuiSwitch-switchBase": {
    color: `${themes[props.inputProps.theme].surfaceFontColor}`,
  },
  "& .MuiSwitch-switchBase.Mui-disabled": {
    color: `${themes[props.inputProps.theme].disabledText}`,
    zIndex: 5,
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track": {
    backgroundColor: `${themes[props.inputProps.theme].disabledContainer}`,
  },
}));

export const ThemeSwitch = styled(Switch)((props) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: props.inputProps.theme === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: props.inputProps.theme === "dark" ? themes[props.inputProps.theme].darkTheme : themes[props.inputProps.theme].lightTheme,
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="yellow" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: props.inputProps.theme === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

export const ColorPickerWrapper = styled(ColorPicker)`
  border: 1px solid ${(props) => themes[props.theme].surfaceFocused};
  box-shadow: ${(props) => themes[props.theme].surfaceFontColor} 0px 1px 3px;
  z-index: 1500 !important;

  :hover {
    border: 2px solid ${(props) => themes[props.theme].surfaceHover};
  }
  .frMxSt {
    background-color: ${(props) => themes[props.theme].surface};

    * {
      color: ${(props) => themes[props.theme].surfaceFontColor};
    }
  }
`;

export const Container = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(19, minmax(0, 1fr));
  grid-template-rows: repeat(20, minmax(0, 1fr));
  grid-gap: 0.5em;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  padding: 0.3em;
  font-family: "Roboto", sans-serif;
`;

export const ContainerOfThings = styled.div`
  display: flex;
  max-height: 100%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0.3em;
  gap: 10px;
`;
