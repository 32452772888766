import React from "react";
import styled from "styled-components";
import { FaUserFriends, FaUsers } from "react-icons/fa";
import { ImageWrapper, IconButtonWrapper } from "../../../../../CSS/styled";
import { themes } from "../../../../../CSS/themes";
import { emailHash } from "../../../../../utils/utils";
import Tooltip from "@plexxis/react-ui-kit/lib/Misc/Tooltip";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ImageWrapperContainer = styled(ImageWrapper)`
  width: 30px;
  height: 30px;
  margin: ${(props) => (props.isOpen ? "0 0.15em 0 0.15em" : "0 -0.35em")};
  border: 1px solid ${(props) => (props.isSelected ? themes[props.theme].primary : themes[props.theme].secondary)};
`;

const GroupWrapper = styled(IconButtonWrapper)`
  width: 25px;
  height: 25px;
  border: 1px solid ${(props) => (props.isOpen ? themes[props.theme].primary : themes[props.theme].secondary)};
`;

const ContributorGroup = ({ theme, contributors, selected, handleSelection, type }) => {
  return (
    <Container isOpen={selected.expanded === type}>
      {type === "Primary" ? (
        <GroupWrapper
          theme={theme}
          icon={
            <>
              <FaUserFriends />
            </>
          }
          title="Primary"
          isOpen={selected.expanded === type}
          onClick={() =>
            handleSelection(
              type,
              contributors?.map((contributor) => contributor.id)
            )
          }
        />
      ) : (
        <GroupWrapper
          theme={theme}
          icon={
            <>
              <FaUsers />
            </>
          }
          title="Secondary"
          isOpen={selected.expanded === type}
          onClick={() =>
            handleSelection(
              type,
              contributors?.map((contributor) => contributor.id)
            )
          }
        />
      )}
      {selected.expanded === type &&
        contributors?.map((contributor) => {
          return (
            <Tooltip
              title={contributor.name}
              anchorSide="left"
              modalProps={{ style: { padding: "4px", fontSize: ".8em", background: themes[theme].surfaceHover } }}
            >
              <ImageWrapperContainer
                key={`User - ${contributor.id}`}
                isOpen={selected.expanded === type}
                isSelected={selected.contributors.length === 1 && selected.contributors.includes(contributor.id)}
                theme={theme}
                src={`https://gravatar.com/avatar/${emailHash(contributor.email)}?d=robohash`}
                alt={"Gravitar Icon"}
                onClick={() => {
                  if ((selected.contributors.length === 1 && !selected.contributors.includes(contributor.id)) || selected.contributors.length > 1) {
                    handleSelection(type, [contributor.id], true);
                  }
                }}
              />
            </Tooltip>
          );
        })}
    </Container>
  );
};

export default ContributorGroup;
