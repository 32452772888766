import React, { useState, useContext, useEffect } from "react";
import API from "../utils/API";
import socketHandler from "../utils/socket";
import sortArray from "sort-array";

const ReviewerContext = React.createContext();

const ReviewerProvider = ({
  user,
  fetchUserList,
  userList,
  assignees,
  setAssignees,
  theme,
  employees,
  setEmployees,
  notification,
  setNotification,
  children,
}) => {
  const [loading, setLoading] = useState(false);
  const [reviewer, setReviewer] = useState({
    contributors: [],
    records: {},
  });
  const [monthlyRatings, setMonthlyRatings] = useState({});
  const [filters, setFilters] = useState([]);
  const [openCard, setOpenCard] = useState(false);
  const [socketData, setSocketData] = useState(null);
  const [favourites, setFavourites] = useState([]);
  const [redirectInfo, setRedirectInfo] = useState({
    isRedirected: false,
    id: null,
  });
  const [hasStatus, setHasStatus] = useState(false);
  /**
   * Loop through all the contributors and return the ones that match the current fitlers
   * @returns Fitlered contributors
   */
  const getContributors = () => {
    const copy = [...employees];

    const filtered = copy.filter((item) =>
      filters.includes("Primary")
        ? item.manager_id === user.id
        : filters.includes("Secondary")
        ? item.sReviewers.includes(user.id)
        : filters.includes("Stars")
        ? favourites.includes(item.email)
        : filters.includes(item.email)
    );

    const orderBy = filters.includes("completed") ? "completed" : filters.includes("incompleted") ? "incompleted" : null;

    const contributors = filtered.length > 0 ? filtered : copy;

    let orderedContributors = [...contributors];
    switch (orderBy) {
      case "completed":
        return sortArray(orderedContributors, {
          by: ["hasSubmission", "name"],
          computed: {
            name: (row) => `${row.first_name.toLowerCase()} ${row.last_name.toLowerCase()}`,
          },
        });
      case "incompleted":
        return sortArray(orderedContributors, {
          by: ["hasSubmission", "name"],
          computed: {
            name: (row) => `${row.first_name.toLowerCase()} ${row.last_name.toLowerCase()}`,
          },
          nullRank: -1,
        });
      default:
        return contributors;
    }
  };

  const updateContributors = (newValue) => {
    const copy = [...employees];

    const newArray = copy.map((contributor) => {
      if (contributor.employee_id === newValue.employee_id) {
        return { ...contributor, ...newValue };
      }
      return contributor;
    });

    setEmployees(newArray);
  };

  /**
   * Appends/Removes selected filter from the filters state
   * @param {string} action Action to perform (Add/Remove)
   * @param {strig} filter Option that is being added or removed
   * @param {string} type Which filter type (Grouping/label, Contributor)
   */
  const handleFilterChange = (action, filter, type) => {
    // Create a copy of the current filters
    let newFilters = [...filters];
    if (action === "Add") {
      if (type === "grouping") {
        newFilters = newFilters.filter((item) => !["Primary", "Secondary", "Stars"].includes(item));
      }
      if (type === "order") {
        newFilters = newFilters.filter((item) => !["completed", "incompleted"].includes(item));
      }
      // Append new filter
      newFilters = [...newFilters, filter];
    } else if (action === "Remove") {
      // Filtering out the selected filtered
      newFilters = newFilters.filter((item) => item !== filter);
    }

    setFilters(newFilters);
  };

  /**
   * Sets the current selected months calendar ratings
   * @param {date} date Date used to get monthly ratings. *YYYY-MM-DD*
   */
  const getContributorsRatings = async (reviewer, date, id) => {
    let result;
    setLoading(true);
    try {
      // If no monthly ratings found, do a bulk request
      if (Object.keys(monthlyRatings).length > 0 && id !== undefined) {
        result = await API.getContributorsRatings(reviewer, date, id);
        setMonthlyRatings((previousState) => ({
          ...previousState,
          [id]: result.data,
        }));
      } else {
        result = await API.getContributorsRatings(reviewer, date);
        setMonthlyRatings(result.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  /**
   * Updates the reviewers contributors state without having to make an API call
   * @param {Object} data Reviewer State
   */
  const updateAnswers = (data) => {
    setReviewer(data);
  };

  useEffect(() => {
    const checkForRedirect = async () => {
      const regexContainsReviewed = /reviewed/gi;

      if (regexContainsReviewed.test(window.location.href)) {
        const results = window.location.href.match(/([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})/gi);
        if (results.length > 0) {
          const response = await API.reviewed({ userId: user.id, answerSet: results[1] });
          if (response.status === 200) {
            socketHandler.updateReviewed(results[0], user.id);
            setTimeout(() => {
              window.close();
            }, 250);
          }
        }
      }
    };
    checkForRedirect();
    socketHandler.subscribeToSubmissions((err, data) => {
      if (err) return;
      setSocketData(data);
      setOpenCard(true);
    });
    socketHandler.subscribeToReviewed((err, id) => {
      if (err) return;
      setRedirectInfo({
        isRedirected: true,
        id,
      });
    });

    return () => {
      socketHandler.unsubscribeFromSubmissions();
      socketHandler.unsubscribeFromReviewed();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (redirectInfo.isRedirected) {
      const copy = [...employees];
      copy.map((contributor) => {
        if (contributor.employee_id === redirectInfo.id) {
          return (contributor.isReviewed = true);
        }
        return contributor;
      });
      setReviewer((previousState) => ({
        ...previousState,
        contributors: copy,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectInfo.isRedirected]);

  useEffect(() => {
    if (socketData == null) return;
    if (socketData?.currentSubmission) {
      updateContributors({ employee_id: socketData?.id, hasSubmission: socketData?.currentSubmission });
      setSocketData(null);
    }
    setNotification({
      message: `${socketData?.first_name} ${socketData?.last_name} made a submission`,
      open: true,
      severity: "info",
      duration: 5000,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketData]);

  useEffect(() => {
    const whosAway = async () => {
      try {
        const response = await API.whosAway();
        if (response?.status >= 200 && response.status < 300) {
          const usersAwayEmails = response.data?.map((x) => x.email);
          const updatedEmployees = employees?.map((employee) => ({
            ...employee,
            isAway: usersAwayEmails.includes(employee.email),
          }));
          setHasStatus(true);
          setEmployees(updatedEmployees);
        }
      } catch (error) {
        // Goodbye error :wave:
        setHasStatus(true);
      }
    };

    if (!hasStatus && employees.length > 0) whosAway();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees]);
  return (
    <ReviewerContext.Provider
      value={{
        loading,
        reviewer,
        filters,
        monthlyRatings,
        id: user.id,
        name: user.first_name + " " + user.last_name,
        openCard,
        socketData,
        isAdmin: user.isAdmin,
        isPrimary: user.isPrimary,
        notification,
        timezone: user.timezone,
        userList,
        assignees,
        theme,
        favourites,
        handleFilterChange,
        getContributorsRatings,
        updateAnswers,
        getContributors,
        setOpenCard,
        setNotification,
        fetchUserList,
        setAssignees,
        setFavourites,
        setReviewer,
        updateContributors,
      }}
    >
      {children}
    </ReviewerContext.Provider>
  );
};

export const useReviewerContext = () => {
  return useContext(ReviewerContext);
};

export { ReviewerContext, ReviewerProvider };
