/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, Suspense, lazy, useCallback } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

//***************************** UTILS **********************************************//
import { debounce } from "lodash";
import { getIdFromUrl } from "./utils/utils";
import API from "./utils/API";
import ToggleContext from "./contexts/ToggleContext";
import moment from "moment-timezone";
import { keywords } from "./utils/keywords";

//*************************** Providers ********************************************//
import { UserProvider } from "./contexts/context";
import { ReviewerProvider } from "./contexts/ReviewerContext";
import { MessageProvider as MessageProvider2 } from "./contexts/NewMessageContext";
import { AdminProvider } from "./contexts/AdminContext";

//*************************** Components ******************************************//
import PrevDec from "./views/PrevDec";
import CurrentDec from "./views/CurrentDec";
import Reviewer from "./Pages/Reviewer/Reviewer";
import socketHandler from "./utils/socket";
import Contributors from "./Pages/Expectations/Components/ContributorBar/Contributors";

//**************************** Styling *******************************************//
import "./CSS/style.css";
import { Spinner } from "@plexxis/react-ui-kit/lib/Misc";
import { FaBars } from "react-icons/fa";
import styled from "styled-components";
import MyDay from "./media/MyDay.svg";
import { themes } from "./CSS/themes";

//************************** Lazy Components ************************************//
const AdminPanel = lazy(() => import("./Pages/Admin/Index"));
const Login = lazy(() => import("./components/Auth/Login"));
const ForgetPassword = lazy(() => import("./components/Auth/ForgetPassword"));
const SubmissionError = lazy(() => import("./Error/SubmissionError"));
const Activities = lazy(() => import("./components/ActivityFeed/Activities"));
const UpdateModal = lazy(() => import("./components/UpdateModal/UpdateModal"));
const NewForm = lazy(() => import("./views/form/NewForm"));
const Announcements = lazy(() => import("./components/Announcements/Announcements"));
const CalendarDec = lazy(() => import("./views/CalendarDec"));
const RSS = lazy(() => import("./components/Expectations/RSS"));
const SettingsModal = lazy(() => import("./components/Auth/SettingsModal"));
const ClientWrapper = lazy(() => import("./Pages/SideNav/Components/ClientWrapper"));
const Notification = lazy(() => import("./components/Misc/Notification"));
const Messages = lazy(() => import("./Pages/Messages/Index"));
const WhosAway = lazy(() => import("./Pages/Contributor/Components/whosaway/WhosAway"));

const Container = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  background-color: ${(props) => themes[props.theme].background};
  color: ${(props) => themes[props.theme].backgroundFontColor};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 320px) {
    margin-bottom: 2em;
    margin-top: 0.5em;
    justify-content: center;
    h1 {
      font-size: 1.3em;
      margin: 0;
    }
  }
`;

const HamburgerMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0.5em;
  width: fit-content;
`;

const CalendarContainer = styled.div`
  position: relative;
  @media (max-width: 1023px) {
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;
  }
  @media (min-width: 1024px) {
    grid-area: 1 / 14 / 21 / 20;
    background-color: ${(props) => themes[props.theme].surface};
    padding-top: 0.5em;
    z-index: 3;
  }
  @media (min-width: 1200px) {
    grid-area: 1 / 1 / 21 / 5;
    padding-top: unset;
    z-index: unset;
  }
`;
const PrevContainer = styled.div`
  background-color: ${(props) => themes[props.theme].surface};
  position: relative;
  @media (max-width: 1023px) {
    max-width: 100vw;
  }
  @media (min-width: 1024px) {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    grid-area: ${(props) => (!props.isAble ? "3 / 1 / 21 / 14" : "1 / 1 / 21 / 14")};
  }

  @media (min-width: 1200px) {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: unset;
    flex-direction: unset;
    height: 50%;
  }
`;

const ContributorView = styled.div`
  @media (max-width: 1023px) {
    overflow: hidden;
    height: 100%;
  }
  @media (min-width: 1024px) {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(19, minmax(0, 1fr));
    grid-template-rows: repeat(20, minmax(0, 1fr));
    grid-gap: 0.5em;
    width: 100%;
    height: 90vh;
    padding: 0.3em;
  }
`;

const ExpectationWrapper = styled.div`
  position: relative;

  @media (max-width: 1023px) {
    max-width: 100vw;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1024px) {
    grid-area: 1 / 14 / 12 / 20;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 0.8em;
      font-weight: 600;
    }
    margin-bottom: -1.5em;
  }
  @media (min-width: 1200px) {
    grid-area: 1 / 15 / 21 / 20;
    margin-bottom: unset;
  }
  background-color: ${(props) => themes[props.theme].surface};
  color: ${(props) => themes[props.theme].surfaceFontColor};
`;

const TabContainer = styled.div`
  display: flex;
  align-items: flex-end;

  @media (min-width: 1024px) {
    grid-area: 12 / 14 / 13 / 20;
    margin-bottom: -0.5em;
  }
  @media (min-width: 1200px) {
    margin-top: 1em;
    margin-bottom: unset;
  }
`;

const Tab = styled.div`
  cursor: pointer;
  height: 3em;
  @media (min-width: 320px) {
    font-family: "Roboto", sans-serif;
    padding: 0.35em 1em;
    font-size: 0.75em;
    border: 1px solid ${(props) => themes[props.theme].surfaceHover};
    border-bottom: ${(props) => (props.isActive ? "none" : "")};
    border-top: ${(props) => (props.isActive ? `4px solid ${themes[props.theme].secondary}` : "")};
    margin-right: 0.02em;
    color: ${(props) => themes[props.theme].surfaceFontColor};
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    font-weight: ${(props) => (props.isActive ? 600 : 400)};
    background-color: ${(props) => themes[props.theme].surface};
    box-shadow: ${(props) => (props.isActive ? "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset" : "unset")};
  }

  &:hover {
    background-color: ${(props) => themes[props.theme].surfaceHover};
  }
`;

const MessageWrapper = styled.div`
  @media (max-width: 768px) {
    width: 100vw;
    height: 80vh;
  }
  @media (min-width: 1024px) {
    grid-area: 13 / 14 / 21 / 20;
    overflow: hidden;
  }
  @media (min-width: 1200px) {
    height: 100%;
  }
`;

const TempContainer = styled.div`
  display: contents;
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    // height: 100%;
  }
  @media (min-width: 1200px) {
    grid-area: 1 / 5 / 21 / 15;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
`;

const CurrentWrapper = styled.div`
  @media (min-width: 1200px) {
    resize: vertical;
    overflow: hidden;
    min-height: 50%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
`;

function App() {
  const [session, setSession] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [employees, setEmployees] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [checkedChange, setCheckedChange] = useState(false);
  const [questions, setQuestions] = useState([]);
  // FLAGS for different modes
  const [mode, setMode] = useState("comboMode");
  const [isRatingOpen, setIsRatingOpen] = useState(false);
  const [isAble, setIsAble] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [changeCounter, setChangeCounter] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  const [isShowing, setIsShowing] = useState(false);
  const [dateChanged, setDateChanged] = useState(false);
  // Hiding current dec
  const [isEditMode, setIsEditMode] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [urlId, setUrlId] = useState(getIdFromUrl(window.location.href));
  // eslint-disable-next-line no-unused-vars
  const [url, setUrl] = useState(window.location.href);
  const [isPassSet, setIsPassSet] = useState(true);
  const [isReviewerMode, setIsReviewerMode] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [openFeed, setOpenFeed] = useState(true);
  const [openEvents, setOpenEvents] = useState(true);
  const [currentView, setCurrentView] = useState({ previous: "", current: "Current" });
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [selected, setSelected] = useState({
    type: "current",
    date: moment().format("YYYY-MM-DD"),
  });
  const [lastReflection, setLastReflection] = useState({
    lastCounted: null,
    count: 0,
    answerSet: null,
    isLocked: true,
  });
  const [timeoutSet, setTimeoutSet] = useState(false);
  const [userList, setUserList] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [theme, setTheme] = useState("light");
  const [notification, setNotification] = useState({
    message: "",
    open: false,
    severity: "",
  });
  const [redirect, setRedirect] = useState(null);
  const [usersAway, setUsersAway] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Timeout used to unlock reflection update
  let timeout = null;
  const calendarRef = useRef();

  const medBreakpoint = 1024;
  const largeBreakpoint = 1200;
  const minTimeTillNextReflection = 500000;

  const getWhosAway = useCallback(async () => {
    if (currentView.current !== "Who's Away") return;
    try {
      const response = await API.whosAway();
      if (response.status >= 200 && response.status < 300) {
        setUsersAway(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      // Handle me
      setIsLoading(false);
    }
  }, [currentView]);

  useEffect(() => {
    getWhosAway();
  }, [getWhosAway, currentView]);

  // sends the frontend version on load
  useEffect(() => {
    socketHandler.initializeSocket();
    socketHandler.checkVersion();
    socketHandler.reloadClient(() => {
      window.location.reload();
    });
    socketHandler.subscribeToPing();
    return () => socketHandler.unmountAll();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Send the users info and their entry status
  useEffect(() => {
    socketHandler.checkEntry({ data: currentUser, isEntry: isEditMode });
    return () => socketHandler.unsubscribeFromEntry();
  }, [isEditMode]);

  useEffect(() => {
    if (session && currentUser.id) {
      getQuestions(currentUser.id);
    }
  }, [session]);

  useEffect(() => {
    // Check for users theme
    const settings = localStorage.getItem("theme");
    const navSettings = localStorage.getItem("navSettings");

    if (settings) {
      setTheme(settings);
    }
    try {
      setIsNavOpen(JSON.parse(navSettings).isOpen);
    } catch (error) {
      return;
    }
  }, []);

  useEffect(() => {
    if (session && currentUser.id) {
      localStorage.setItem("navSettings", JSON.stringify({ isOpen: isNavOpen }));
    }
  }, [isNavOpen]);

  useEffect(() => {
    if (currentUser.id != null) {
      const redirectData = localStorage.getItem("redirect");
      if (redirectData != null) {
        setRedirect(JSON.parse(redirectData));
        localStorage.removeItem("redirect");
      }
    }
  }, [currentUser]);

  // Get daily questions
  const getQuestions = async (id) => {
    let newQuestions = await API.getQuestions(id);
    setQuestions(newQuestions.data.response[moment().tz(currentUser.timezone).day()]);
  };
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };
  const checkedUpdate = () => {
    setCheckedChange(!checkedChange);
  };

  const countChange = () => {
    setChangeCounter(true);
  };

  const updateReviewerMode = () => {
    setIsReviewerMode(!isReviewerMode);
  };
  const handleView = (view) => {
    setCurrentView({ previous: currentView.current, current: view });
  };
  const changeToggle = async (type) => {
    const tempUser = { ...currentUser };
    if (type === "achievements") {
      tempUser.achievements = !tempUser.achievements;
    } else if (type === "selfRating") {
      tempUser.selfRating = !tempUser.selfRating;
    } else if (type === "autoScoring") {
      tempUser.autoScoring = !tempUser.autoScoring;
    } else if (type === "theme") {
      const newTheme = theme === "light" ? "dark" : "light";
      localStorage.setItem("theme", newTheme);
      setTheme(newTheme);
    }
    setCurrentUser(tempUser);
    await API.updateSettings({ id: currentUser.id, type });
  };

  const handleDebounce = debounce(() => handleReflection(), 1500);

  const handleReflection = async () => {
    if (!lastReflection.isLocked && lastReflection.answerSet) {
      const copy = { ...lastReflection };
      copy.count += 1;
      copy.lastCounted = moment();

      try {
        const response = await API.updateReflection(currentUser.id, copy);
        if (response.status === 200) {
          setLastReflection(() => ({
            ...response.data,
            isLocked: true,
          }));
        }

        setTimeout(() => {
          setLastReflection((previousState) => ({
            ...previousState,
            isLocked: false,
          }));
        }, minTimeTillNextReflection);
      } catch (error) {
        return;
      }
    } else if (!timeoutSet && lastReflection.answerSet) {
      // Reflections are locked, set a new timeout to unlock
      if (timeout) {
        clearTimeout(timeout);
      }
      const timeTillReflection = minTimeTillNextReflection - moment().diff(lastReflection.lastCounted, "millisecond");
      timeout = setTimeout(() => {
        setLastReflection((previousState) => ({
          ...previousState,
          isLocked: false,
        }));
      }, timeTillReflection);
      setTimeoutSet(true);
    }
  };
  /**
   * Fetches all the users to be displayed within the new expectation modal when the description includes "@"
   */
  const fetchUserList = async (user) => {
    try {
      const results = await API.getAllUsers(user.id, keywords);

      if (results.status >= 200 && results.status < 300) {
        let currentList = results.data;
        if (user.isGlobal || user.isAdmin) {
          // User has global permissions
          currentList = [...currentList, ...keywords.admin];
        }
        if (user.isPrimary) {
          currentList = [...currentList, ...keywords.primary];
        }
        if (user.isReviewer) {
          currentList = [...currentList, ...keywords.reviewers];
        }
        // Check for primary reviewer
        setUserList(currentList);
      }
    } catch (error) {
      setUserList([]);
      setNotification({
        message: "Failed to fetch user list",
        open: true,
        severity: "error",
      });
    }
  };
  return (
    <ToggleContext.Provider
      value={{
        mode,
        setMode,
        isRatingOpen,
        setIsRatingOpen,
      }}
    >
      <Container id="main" theme={theme}>
        <Header>
          <img src={MyDay} alt="Lighthouse" style={{ width: "1.5em", height: "1.5em", marginTop: ".2em" }} />
          <h1>MyDay</h1>
        </Header>
        {session && (!isReviewerMode || (isReviewerMode && !isNavOpen)) && (
          <HamburgerMenu style={{ position: "absolute", top: 0, right: 5 }} onClick={() => setIsNavOpen(true)}>
            <FaBars />
          </HamburgerMenu>
        )}

        <Router>
          <Switch>
            <Route exact path="/admin">
              <Suspense
                fallback={
                  <div>
                    <Spinner top={55} height="50%" left={55} opacity={0} />
                  </div>
                }
              >
                {session ? (
                  <AdminProvider
                    theme={theme}
                    user={currentUser}
                    setNotification={setNotification}
                    isNavOpen={isNavOpen}
                    setIsNavOpen={setIsNavOpen}
                    currentView={currentView}
                    setCurrentView={setCurrentView}
                    width={width}
                  >
                    <AdminPanel />
                  </AdminProvider>
                ) : (
                  <Redirect to={"/login/admin"} />
                )}
              </Suspense>
            </Route>
            <Route path="/login">
              {session ? (
                url.includes("admin") && (currentUser.isAdmin || currentUser.isPrimary) ? (
                  <Redirect to={"/admin"} />
                ) : url.includes("review") ? (
                  <Redirect to={"/" + url.substr(url.indexOf("review"))} />
                ) : (
                  <Redirect to={`/`} />
                )
              ) : (
                <Suspense
                  fallback={
                    <div>
                      <Spinner top={55} height="50%" left={55} opacity={0} />
                    </div>
                  }
                >
                  <Login
                    isOpen={session}
                    setIsOpen={setSession}
                    setCurrentUser={setCurrentUser}
                    setEmployees={setEmployees}
                    user={currentUser}
                    isSubmitted={isSubmitted}
                    isPassSet={isPassSet}
                    setIsPassSet={setIsPassSet}
                    setSelectedDate={setSelectedDate}
                    theme={theme}
                  />
                </Suspense>
              )}
            </Route>

            <Route exact path="/forgetPassword">
              <Suspense
                fallback={
                  <div>
                    <Spinner top={55} height="50%" left={55} opacity={0} />
                  </div>
                }
              >
                {localStorage.getItem("resetToken") !== null &&
                moment(JSON.parse(localStorage.getItem("resetToken")).expires).format("YYYY-MM-DD HH:mm:ss") >= moment().format("YYYY-MM-DD HH:mm:ss") ? (
                  <ForgetPassword />
                ) : (
                  <Redirect to={"/login"} />
                )}
              </Suspense>
            </Route>

            {!session ? (
              urlId !== undefined ? (
                <Redirect to={"/login/userid=" + urlId} />
              ) : (
                <Redirect to="/login" />
              )
            ) : (
              <>
                <MessageProvider2
                  theme={theme}
                  user={currentUser}
                  currentView={currentView}
                  setCurrentView={setCurrentView}
                  notification={notification}
                  setNotification={setNotification}
                  redirect={redirect}
                >
                  <Route path="/review">
                    <ReviewerProvider
                      user={currentUser}
                      employees={employees}
                      setEmployees={setEmployees}
                      fetchUserList={fetchUserList}
                      userList={userList}
                      assignees={assignees}
                      setAssignees={setAssignees}
                      theme={theme}
                      notification={notification}
                      setNotification={setNotification}
                    >
                      <Suspense
                        fallback={
                          <div>
                            <Spinner top={55} height="50%" left={55} opacity={0} />
                          </div>
                        }
                      >
                        <Reviewer
                          user={currentUser}
                          employees={employees}
                          updateReviewerMode={updateReviewerMode}
                          setCurrentView={setCurrentView}
                          currentView={currentView}
                          isNavOpen={isNavOpen}
                          setIsNavOpen={setIsNavOpen}
                          width={width}
                          redirect={redirect}
                        />
                      </Suspense>
                    </ReviewerProvider>
                  </Route>
                  <Route exact path="/">
                    <UserProvider
                      user={{ ...currentUser, isPrimary: employees.length > 0 }}
                      selectedDate={selectedDate}
                      isSubmitted={isSubmitted}
                      setIsEditMode={setIsEditMode}
                      dateChanged={dateChanged}
                      declaration={selected}
                      lastReflection={lastReflection}
                      setLastReflection={setLastReflection}
                      fetchUserList={fetchUserList}
                      userList={userList}
                      assignees={assignees}
                      setAssignees={setAssignees}
                      theme={theme}
                      notification={notification}
                      setNotification={setNotification}
                      setCurrentView={setCurrentView}
                      currentView={currentView}
                    >
                      <ContributorView onMouseMove={() => handleDebounce()}>
                        <Suspense fallback={<></>}>
                          <ClientWrapper
                            setCurrentView={setCurrentView}
                            currentView={currentView}
                            isEditMode={isEditMode}
                            setIsEditMode={setIsEditMode}
                            setSession={setSession}
                            setSelectedDate={setSelectedDate}
                            isNavOpen={isNavOpen}
                            setIsNavOpen={setIsNavOpen}
                            changeToggle={changeToggle}
                            width={width}
                            updateReviewerMode={updateReviewerMode}
                            isReviewerMode={isReviewerMode}
                          />
                        </Suspense>
                        {!isAble ? (
                          <Suspense
                            fallback={
                              <div>
                                <Spinner top={55} height="50%" left={55} opacity={0} />
                              </div>
                            }
                          >
                            <SubmissionError setIsAble={setIsAble} isAble={isAble} errorMessage={errorMessage} theme={theme} />
                          </Suspense>
                        ) : null}
                        <Suspense fallback={<></>}>
                          <SettingsModal
                            id={currentUser.id}
                            currentView={currentView}
                            setCurrentView={setCurrentView}
                            setSession={setSession}
                            isPassSet={isPassSet}
                            setIsPassSet={setIsPassSet}
                          />
                        </Suspense>

                        {(currentView.current === "Calendar/Events" || width >= largeBreakpoint) && (
                          <CalendarContainer theme={theme}>
                            <Suspense
                              fallback={
                                <>
                                  <Spinner top={0} left={0} opacity={0} style={{ position: "absolute" }} />
                                </>
                              }
                            >
                              <CalendarDec
                                calendarRef={calendarRef}
                                user={currentUser}
                                isReviewer={false}
                                isSubmitted={isSubmitted}
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                                isEditMode={isEditMode}
                                setIsEditMode={setIsEditMode}
                                openFeed={openFeed}
                                setOpenFeed={setOpenFeed}
                                openEvents={openEvents}
                                setOpenEvents={setOpenEvents}
                                setDateChanged={setDateChanged}
                                dateChanged={dateChanged}
                                currentView={currentView}
                                setCurrentView={setCurrentView}
                                isAble={isAble}
                                setSelected={setSelected}
                              />
                            </Suspense>
                          </CalendarContainer>
                        )}
                        {currentView.current === "Announcements" && (
                          <Suspense fallback={<></>}>
                            <Announcements currentView={currentView} setCurrentView={setCurrentView} />
                          </Suspense>
                        )}
                        {(currentView.current === "Expectations" || width >= medBreakpoint) && (
                          <ExpectationWrapper theme={theme}>
                            <Suspense
                              fallback={
                                <>
                                  <Spinner top={0} left={0} opacity={0} style={{ position: "absolute" }} />
                                </>
                              }
                            >
                              {employees?.length > 0 && currentView.current !== "Change Password" && <Contributors />}

                              <RSS
                                user={currentUser}
                                setOpenFeed={setOpenFeed}
                                openFeed={openFeed}
                                isShowing={isShowing}
                                setIsShowing={setIsShowing}
                                isEditMode={isEditMode}
                                setIsEditMode={setIsEditMode}
                              />
                            </Suspense>
                          </ExpectationWrapper>
                        )}
                        <TempContainer>
                          {!isEditMode && (currentView.current === "Home" || currentView.current === "Current" || width >= medBreakpoint) && (
                            <>
                              {width >= 1200 ? (
                                <CurrentWrapper>
                                  <CurrentDec
                                    setIsSubmitted={setIsSubmitted}
                                    isSubmitted={isSubmitted}
                                    questions={questions}
                                    user={currentUser}
                                    selectedDate={selectedDate}
                                    updateCounter={changeCounter}
                                    checkedUpdate={checkedUpdate}
                                    setIsEditMode={setIsEditMode}
                                    isEditMode={isEditMode}
                                    setInitialLoad={setInitialLoad}
                                    initialLoad={initialLoad}
                                    isShowing={isShowing}
                                    setIsShowing={setIsShowing}
                                    setIndex={setIsShowing}
                                    setSelected={setSelected}
                                  />
                                </CurrentWrapper>
                              ) : (
                                <CurrentDec
                                  setIsSubmitted={setIsSubmitted}
                                  isSubmitted={isSubmitted}
                                  questions={questions}
                                  user={currentUser}
                                  selectedDate={selectedDate}
                                  updateCounter={changeCounter}
                                  checkedUpdate={checkedUpdate}
                                  setIsEditMode={setIsEditMode}
                                  isEditMode={isEditMode}
                                  setInitialLoad={setInitialLoad}
                                  initialLoad={initialLoad}
                                  isShowing={isShowing}
                                  setIsShowing={setIsShowing}
                                  setIndex={setIsShowing}
                                  setSelected={setSelected}
                                />
                              )}
                            </>
                          )}
                          {(width > 768 && (currentView.current === "Edit/Enter" || isEditMode)) || (width <= 768 && currentView.current === "Edit/Enter") ? (
                            <PrevContainer isAble={isAble} theme={theme}>
                              <Suspense
                                fallback={
                                  <>
                                    <Spinner top={0} left={0} opacity={0} style={{ position: "absolute" }} />
                                  </>
                                }
                              >
                                <NewForm
                                  isEditMode={isEditMode}
                                  setIsEditMode={setIsEditMode}
                                  setIsSubmitted={setIsSubmitted}
                                  isSubmitted={isSubmitted}
                                  questions={questions}
                                  user={currentUser}
                                  isAble={isAble}
                                  setIsAble={setIsAble}
                                  setErrorMessage={setErrorMessage}
                                  setOpenSnackBar={setOpenSnackBar}
                                  selectedDate={selectedDate}
                                  session={session}
                                  isShowing={isShowing}
                                  setIsShowing={setIsShowing}
                                  checkedChange={checkedChange}
                                  getQuestions={getQuestions}
                                  setSelected={setSelected}
                                  selected={selected}
                                  setCurrentView={setCurrentView}
                                  currentView={currentView}
                                  setNotification={setNotification}
                                />
                              </Suspense>
                            </PrevContainer>
                          ) : null}
                          {(["Previous", "Activity Feed", "Messages", "Who's Away"].includes(currentView.current) || width >= medBreakpoint) && (
                            <TabContainer isEditMode={isEditMode}>
                              <Tab
                                isActive={currentView.current === "Previous"}
                                theme={theme}
                                onClick={() => {
                                  handleView("Previous");
                                }}
                              >
                                Previous
                              </Tab>
                              <Tab isActive={currentView.current === "Activity Feed"} theme={theme} onClick={() => handleView("Activity Feed")}>
                                Activity
                              </Tab>
                              <Tab isActive={currentView.current === "Messages"} theme={theme} onClick={() => handleView("Messages")}>
                                Messages
                              </Tab>
                              <Tab isActive={currentView.current === "Who's Away"} theme={theme} onClick={() => handleView("Who's Away")}>
                                Who's Away
                              </Tab>
                            </TabContainer>
                          )}
                          {((width > 768 &&
                            currentView.current !== "Activity Feed" &&
                            currentView.current !== "Messages" &&
                            currentView.current !== "Who's Away") ||
                            (width <= 768 && currentView.current === "Previous")) && (
                            <PrevDec
                              questions={questions}
                              openSnackBar={openSnackBar}
                              setOpenSnackBar={setOpenSnackBar}
                              setNotification={setNotification}
                              selectedDate={selectedDate}
                              updateAnswers={countChange}
                              isEditMode={isEditMode}
                              setIsEditMode={setIsEditMode}
                              isShowing={isShowing}
                              setIsShowing={setIsShowing}
                              checkedUpdate={checkedUpdate}
                              user={currentUser}
                              setSelected={setSelected}
                              currentView={currentView}
                              setCurrentView={setCurrentView}
                            />
                          )}
                          {currentView.current === "Activity Feed" && (
                            <Suspense fallback={<></>}>
                              <Activities
                                user={currentUser}
                                isEditMode={isEditMode}
                                setCurrentView={setCurrentView}
                                currentView={currentView}
                                theme={theme}
                                id="contributorActivity"
                              />
                            </Suspense>
                          )}
                          {currentView.current === "Messages" && (
                            <Suspense fallback={<></>}>
                              <MessageWrapper isEditMode={isEditMode}>
                                <Messages theme={theme} user={currentUser} isEditMode={isEditMode} setNotification={setNotification} />
                              </MessageWrapper>
                            </Suspense>
                          )}
                          {currentView.current === "Who's Away" && (
                            <Suspense fallback={<></>}>
                              <WhosAway theme={theme} usersAway={usersAway} isLoading={isLoading} />
                            </Suspense>
                          )}
                        </TempContainer>
                        {currentView.current === "What's New?" && (
                          <Suspense fallback={<></>}>
                            <UpdateModal currentView={currentView} setCurrentView={setCurrentView} theme={theme} />
                          </Suspense>
                        )}
                      </ContributorView>
                    </UserProvider>
                  </Route>
                </MessageProvider2>
              </>
            )}
          </Switch>
        </Router>
        <Suspense fallback={<></>}>
          <Notification isOpen={notification.open} setIsOpen={setNotification} message={notification.message} severity={notification.severity} />
        </Suspense>
      </Container>
    </ToggleContext.Provider>
  );
}

export default App;
