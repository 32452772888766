import { parseJson } from "../../../components/CustomEditor/utils";

export const groupAnswers = (arr) => {
  const newStore = {};
  arr.forEach((ans) => {
    let questionStored = false;
    // Check object
    for (let key in newStore) {
      if (ans.question === key) {
        newStore[key].push({ ...ans, answer: parseJson(ans.answer) });
        questionStored = true;
      }
    }
    // Question Not stored
    if (!questionStored) {
      newStore[ans.question] = [{ ...ans, answer: parseJson(ans.answer) }];
    }
  });
  return newStore;
};
