import React, { useState, Suspense, lazy } from "react";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";
import { ImageWrapper } from "../../../../CSS/styled";
import { emailHash } from "../../../../utils/utils";
import { useGlobalContext } from "../../../../contexts/context";
import { themes } from "../../../../CSS/themes";

import ContributorGroup from "./Components/ContributorGroup";
import Tooltip from "@plexxis/react-ui-kit/lib/Misc/Tooltip";
const SearchModal = lazy(() => import("./Components/SearchModal"));

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: -1.6em;
  width: 100%;

  svg,
  img {
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    display: ${(props) => (["Announcements", "What's New?", "Calendar"].includes(props.currentView.current) ? "none" : "")};
  }

  @media (min-width: 1200px) {
    display: ${(props) => (["Announcements", "What's New?"].includes(props.currentView.current) ? "none" : "")};
  }
`;

const ContributorWrapper = styled.div`
  display: flex;
`;

const ContentWrapper = styled.div`
  display: flex;
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 4px !important;
  }
`;

const GroupWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

const Contributors = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { contributors, theme, user, getExpectations, groupSelected, handleSelection, currentView } = useGlobalContext();

  return (
    <Container currentView={currentView}>
      <Suspense fallback={<></>}>
        <>
          {isOpen && (
            <SearchModal
              theme={theme}
              isOpen={isOpen}
              contributors={contributors}
              setIsOpen={setIsOpen}
              handleSelection={handleSelection}
              component={groupSelected.expanded}
            />
          )}
        </>
      </Suspense>
      <ContentWrapper>
        <GroupWrapper>
          <ContributorGroup
            contributors={contributors?.filter((contributor) => contributor.isSecondary)}
            theme={theme}
            selected={groupSelected}
            handleSelection={handleSelection}
            type={"Secondary"}
          />
          <ContributorGroup
            contributors={contributors?.filter((contributor) => contributor.isPrimary)}
            theme={theme}
            selected={groupSelected}
            handleSelection={handleSelection}
            type={"Primary"}
          />
        </GroupWrapper>
        <ContributorWrapper>
          <Tooltip title={`You`} modalProps={{ style: { padding: "4px", fontSize: ".8em", background: themes[theme].surfaceHover } }}>
            <ImageWrapper
              theme={theme}
              src={`https://gravatar.com/avatar/${emailHash(user.email)}?d=robohash`}
              alt={"Gravitar Icon"}
              style={{ width: "30px", height: "30px" }}
              onClick={() => {
                if (groupSelected.expanded != null || groupSelected.contributors.length !== 0) {
                  getExpectations();
                }
              }}
            />
          </Tooltip>
        </ContributorWrapper>
      </ContentWrapper>
      <FaSearch onClick={() => setIsOpen(true)} />
    </Container>
  );
};

export default Contributors;
