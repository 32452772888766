import escapeHtml from "escape-html";
import { Text, Node } from "slate";
import { themes } from "../../CSS/themes";

/**
 * Attempts to convert a SlateJS formatted object into readable HTML. In cases that it fails (Old - Plain text), return the passed value
 * @param {Object} node SlateJS formatted object || Plain text
 * @returns HTML formatted text || Plain text
 */
export const serialize = (node, theme) => {
  try {
    if (Text.isText(node)) {
      let string = escapeHtml(node.text);
      if (node.bold) {
        string = `<strong>${string}</strong>`;
      } else if (node.underline) {
        string = `<u>${string}</u>`;
      } else if (node.italic) {
        string = `<i>${string}</i>`;
      }
      return string;
    }
    const children = node.children.map((n) => serialize(n, theme)).join("");

    switch (node.type) {
      case "paragraph":
        if (children !== "") {
          return `<p style="display: inline; margin: 0;">${children}</p>`;
        }
        return;
      case "mention":
        return `<span style="
          padding: 3px 3px 2px;
          margin: 0 1px;
          vertical-align: baseline;
          display: inline-block;
          border-radius: 4px;
          background-color: ${themes[theme].mentionedBackground ?? "var(--light-mode-mentioned-background)"};
          color: ${themes[theme].mentionedFontColor ?? "var(--light-mode-mentioned-font-color)"};
          font-size: 0.9em;"
        >@${node.user}</span>`;
      case "expectation":
        if (children !== "") {
          return `<p style="display: inline; margin: 0; color: pink;">${children}</p>`;
        }
        return;
      case "link":
        if (children !== "") {
          return `<a href=${node.href} target="_blank" class="${theme === "dark" ? "darkThemeLink" : "lightThemeLink"}" style="color: ${
            themes[theme].secondary
          }; text-decoration: none;">${children} </a>`;
        }
        return;
      default:
        return children;
    }
  } catch (error) {
    const text = node.children ? node.children : node;
    return text;
  }
};

/**
 * Attempts to convert emails into a SlateJS formatted object
 * @param {string} subject Email's subject
 * @param {string} desc Email's body
 * @param {string} link Link to the full email
 * @returns JSON Object formatted to work with SlateJS
 */
export const deserialize = (data) => {
  if (data == null) return;
  try {
    const result = {
      children: [],
    };
    const value = data.split("\n");
    if (value?.length > 0) {
      const urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
      value.forEach((line) => {
        const isURL = line.match(urlRegex);
        let isMatch;
        let startingIndex = 0;

        if (isURL) {
          // Loop through each url found within the given line
          while ((isMatch = urlRegex.exec(line)) != null) {
            const plainText = line.substring(startingIndex, isMatch.index);

            // String found before the link
            if (plainText?.length > 0) {
              const newLine = {
                type: "paragraph",
                children: [{ text: plainText }],
              };
              result.children.push(newLine);
            }
            startingIndex = isMatch[0].length + startingIndex;
            const hyperlink = {
              type: "link",
              href: `${isMatch[0]}`,
              children: [{ text: `${isMatch[0]}` }],
            };
            result.children.push(hyperlink);
          }
        } else {
          const newLine = {
            type: "paragraph",
            children: [{ text: line }],
          };
          result.children.push(newLine);
        }
      });
    }
    return result;
  } catch (error) {
    return data;
  }
};

/**
 * Attempts to parse the passed value into a JSON object. In the case that it fails (Old format - Plain text) then return the passed value
 * @param {Object} value Stringify JSON object || Plain Text
 * @param {string} loc Location on where it's being called from
 * @returns
 */
export const parseJson = (value, loc) => {
  let data;
  try {
    data = JSON.parse(value);
  } catch (error) {
    if (loc === "slate") {
      const initValue = [
        {
          type: "paragraph",
          children: [{ text: `${value}` }],
        },
      ];
      return initValue;
    }
    return value;
  }
  return data;
};

/**
 * Attempts to convert the given value into a string
 * @param {Object} value JSON value that should contain rich text
 * @returns
 */
export const stringifyJson = (value) => {
  let data;
  try {
    data = JSON.stringify(value);
  } catch (error) {
    return data;
  }
  return data;
};

/**
 * Converts SlateJS JSON format to plain text
 * @param {Object} value JSON text object
 * @returns Plain text
 */
export const formatText = (value) => {
  try {
    const arr = parseJson(value)
      .map((n) => Node.string(n))
      .join("\n ");
    return arr;
  } catch (error) {
    const newValue = deserialize(value);

    return newValue;
  }
};
